import {useRef, useState} from 'react';
import {Badge, Box, ButtonBase} from '@mui/material';
import useCart from '@panel/hooks/cart/useCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {useHistory} from 'react-router-dom';
import {CartPopover} from '@panel/components/CartPopover';

export const CartButton = () => {
  const anchorRef = useRef(null);
  const {total} = useCart();
  const [openCartMenu, setOpenCartMenu] = useState(false);
  const history = useHistory();

  const handleCartClicked = () => {
    openCartMenu && history.push(`/my-cart`);
    setOpenCartMenu(!openCartMenu);
  };

  const handleOpenPopover = () => {
    setOpenCartMenu(true);
  };

  const handleClosePopover = () => {
    setOpenCartMenu(false);
  };

  return (
    <Box onMouseEnter={handleOpenPopover} onMouseLeave={handleClosePopover}>
      <Box
        aria-owns={openCartMenu ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        component={ButtonBase}
        onClick={handleCartClicked}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          ml: 2,
          mr: 2,
        }}
      >
        <Badge badgeContent={total.propertyQuantity} color="primary">
          <ShoppingCartOutlinedIcon sx={{color: 'gray'}} fontSize="small" />
        </Badge>
        <CartPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openCartMenu} />
      </Box>
    </Box>
  );
};
