import {TableCell} from '@mui/material';
import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import api from '@panel/utils/api';

const PorcentDividends = (props: any) => {
  const [netDividends, setNetDividends] = useState<any>(0);
  const id = props.propertyId;

  const {
    isLoading,
    isError,
    data: dataFetched,
  } = useQuery(['property', id], () =>
    api['properties'].get(`/${id}`, {include: ['financial']})
  );

  useEffect(() => {
    if (dataFetched) {
      const TotalValue = Number(dataFetched.data.closingValue);
      const rentalNet = Number(dataFetched.data.__financial__.rentalNetAmount);
      const total = (((rentalNet * 12) / TotalValue) * 100).toFixed(3);
      setNetDividends(total);
    }
  }, [dataFetched]);

  return <TableCell align="center">{netDividends} %</TableCell>;
};

export default PorcentDividends;
