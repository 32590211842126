import {Box, IconButton} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import useAuth from '@panel/hooks/useAuth';
import Page from '@panel/components/Page';
import {useEffect, useState} from 'react';
import FiltersSideMenu from './FiltersSideMenu';
import {Filter as FilterIcon} from '@panel/icons/Filter';
import PropertyGridView from './PropertyGridView';
import {IFilters} from '@panel/interfaces/filters-marketplace';
import {FilterContext} from '@panel/components/PropertyFilter';
import CompleteProfileBanner from '@panel/components/CompleteProfileBanner';
import {OrderContext} from '@panel/components/SelectOrderContext';
import {SaleTypeContext} from '@panel/components/SaleTypeContext';
import {useQuery} from 'react-query';
import api from '@panel/utils/api';

const MarketplaceView: React.FunctionComponent<IPage> = (props): JSX.Element => {
  const {user} = useAuth();
  const {name} = props

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [filtersSelected, setFiltersSelected] = useState<IFilters>({range: [], types: []});
  const [orderSelected, setOrderSelected] = useState('recent')
  const [saleTypeSelected, setSaleTypeSelected] = useState('0')
  const value = {filtersSelected, setFiltersSelected};
  const order = {orderSelected, setOrderSelected};
  const saleTypeOrden = {saleTypeSelected, setSaleTypeSelected};

  const [completeProfileBannerIsVisible, setCompleteProfileBannerIsVisible] = useState(false);

  useEffect(() => {
    user?.isProfileComplete === false && setCompleteProfileBannerIsVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={name ?? ''}>
      <FilterContext.Provider value={{filtersSelected, setFiltersSelected}}>
        <OrderContext.Provider
          value={{
            orderSelected,
            setOrderSelected,
          }}
        >
          <SaleTypeContext.Provider
            value={{
              saleTypeSelected,
              setSaleTypeSelected
            }}
          >
            <FiltersSideMenu onClose={() => setIsSidebarOpen(false)} open={isSidebarOpen}/>
            <Box
              sx={{
                marginLeft: {
                  lg: '285px',
                  xs: '5px',
                },
                marginRight: {
                  lg: '5px',
                  xs: '5px',
                },
                marginTop: '25px',
              }}
            >
              <CompleteProfileBanner/>
              <PropertyGridView/>
            </Box>
          </SaleTypeContext.Provider>
        </OrderContext.Provider>
      </FilterContext.Provider>
      <IconButton
        onClick={() => setIsSidebarOpen(true)}
        sx={{
          display: {
            xs: 'inline-flex',
            lg: 'none',
          },
          position: 'fixed',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          right: '20px',
          bottom: '30px',
          border: 'solid 2px rgba(0, 0, 0, 0.2)',
          borderRadius: '25px',
          color: 'black',
        }}
      >
        <FilterIcon/>
      </IconButton>
    </Page>
  );
};

export default MarketplaceView;
