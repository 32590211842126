import {SetupIntent, SetupIntentResult} from '@stripe/stripe-js';
import {useStripe} from '@stripe/react-stripe-js';
import useAuth from '../useAuth';
import {useQuery} from 'react-query';
import useApi from '@panel/utils/apis';
import {toast} from 'react-toastify';
import {IVerifyAchPayment} from '@panel/interfaces';
import {IPaymentMethod} from '@brix/shared-frontend';

const useStripeAch = () => {
  const stripe = useStripe();
  const {user} = useAuth();
  const {paymentMethodApi} = useApi();
  const queryKey = ['payment-method', 'user', user?.id, 'ach'];

  const {data: response, refetch} = useQuery(queryKey, () => paymentMethodApi.get(`/user/${user?.id}/ach`));

  const addNewAchAccount = async () => {
    paymentMethodApi
      .post(`/setup-intents`, {}, {userId: user?.id})
      .then((response: any) => {
        const intent = response.data as SetupIntent;
        stripe
          ?.collectBankAccountForSetup({
            clientSecret: intent.client_secret,
            expand: ['payment_method'],
            params: {
              payment_method_type: 'us_bank_account',
              payment_method_data: {
                billing_details: {
                  name: `${user?.firstName?.trim()} ${user?.lastName?.trim()}`,
                  email: user?.email,
                },
              },
            },
          } as any)
          .then((response: SetupIntentResult) => {
            const setupIntent = response.setupIntent;
            if (response.error) {
              toast.error(response.error.message);
            } else if (setupIntent?.status === 'requires_payment_method') {
              toast.warning('Transaction cancelled by the user');
            } else if (setupIntent?.status === 'requires_confirmation') {
              confirmAcknowledgement(intent.client_secret);
            }
          });
      })
      .catch((error: any) => toast.error(error?.message));
  };

  const confirmAcknowledgement = (clientSecret: string | null) => {
    stripe?.confirmUsBankAccountSetup(clientSecret ?? '').then(({setupIntent, error}) => {
      if (error) {
        toast.error(error.message);
      } else if (setupIntent?.status === 'requires_payment_method') {
        toast.error('Confirmation failed. Attempt again with a different payment method.');
      } else if (setupIntent?.status === 'succeeded') {
        confirmIntent(setupIntent);
      } else if (setupIntent?.next_action?.type === 'verify_with_microdeposits') {
        confirmIntent(setupIntent);
      }
    });
  };

  const confirmIntent = (intent: SetupIntent) => {
    paymentMethodApi.post('/setup-intents/confirm', {}, {intentId: intent.id, userId: user?.id}).then(
      async (res: any) => {
        if (res.data.status !== 'succeeded') {
          toast.info('ACH account added but need to verify via microdeposits');
        } else {
          toast.success('ACH account added successfully');
        }
        await refetch();
      },
      () => {
        toast.error('There was an error trying to add ACH account');
      }
    );
  };

  const onVerifyACH = async (data: IVerifyAchPayment, intentId: string, userId: string, paymentMethodId: string) => {
    paymentMethodApi.post('/setup-intents/verify-microdeposits', {
      intentId,
      userId,
      paymentMethodId,
      amounts: [data.deposit1 * 100, data.deposit2 * 100],
    }).then(() => {
      toast.success('ACH account verified successfully');
    }).catch(() => {
        toast.error('There was an error trying to verify ACH account');
      });
    await refetch();
  };

  return {
    addNewAchAccount,
    onVerifyACH,
    paymentMethods: (response?.data ?? []) as Array<IPaymentMethod>,
  };
};

export default useStripeAch;
