import {Box, Grid, Typography} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import Page from '@panel/components/Page';
import Portfolio from '@panel/views/dashboard/Portfolio';
import { PropsWithChildren, FunctionComponent } from 'react';

const PortfolioView: FunctionComponent<IPage> = (props: PropsWithChildren<IPage>) => {

  const { name } = props

  return (
    <Page title={name ?? ''}>
      <Box sx={{
          flexGrow: 1,
          pt: 8,
          px: 4,
          backgroundColor: 'background.default',
          height: '100%',
        }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h4">Portfolio</Typography>
          </Grid>
          <Grid item xs={12}>
            <Portfolio/>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default PortfolioView;
