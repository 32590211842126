import {Box, Grid} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import {useState, useEffect} from 'react';
import Page from '@panel/components/Page';
import CompleteProfileBanner from '@panel/components/CompleteProfileBanner';
import useAuth from '@panel/hooks/useAuth';
import Insights from './Insights';
import Welcome from './Welcome';
import Portfolio from './Portfolio';

const DashboardView: React.FunctionComponent<IPage> = (props) => {
  const {user} = useAuth();
  const [completeProfileBannerIsVisible, setCompleteProfileBannerIsVisible] = useState(false);
  const { name } = props

  useEffect(() => {
    user?.isProfileComplete === false && setCompleteProfileBannerIsVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={name ?? ''}>
      <Box
        sx={{
          flexGrow: 1,
          pt: 4,
          px: {xs: 2, sm: 4},
          backgroundColor: 'background.default',
          height: '100%',
        }}
      >
        <Welcome />
        <CompleteProfileBanner />
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={12}>
            <Insights />
          </Grid>
          <Grid item xs={12}>
            <Portfolio />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default DashboardView;
