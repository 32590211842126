import {Box, Card, Dialog, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export const BrixMortgageUnableProperty = ({onClose}: { onClose: () => void }) => {

  return (
    <Dialog disableScrollLock onClose={onClose} open={true}>
      <Card sx={{
        px: 6,
        pt: 2,
        pb: 2,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'background.default',
        overflowY: 'auto',
      }}>
        <Box sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}>
          <IconButton onClick={onClose}>
            <CloseIcon/>{' '}
          </IconButton>
        </Box>
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <Typography variant="h3" align="center">
            Unable to sell
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
            Unable to sell property while it has mortgage pending balance.
          </Typography>
        </Box>
      </Card>
    </Dialog>
  )
}
