/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  Container,
  Divider,
  Drawer,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {Scrollbar} from '@panel/components/Scrollbar';
import {Filter} from '@panel/icons/Filter';
import {useEffect, useState, useContext, useRef} from 'react';
import { debounce } from 'lodash';
import {FilterContext} from '@panel/components/PropertyFilter';
import SelectOrderMenu from '@panel/views/marketplace/SelectOrderMenu';
import SaleTypeMenu from '@panel/views/marketplace/SaleTypeMenu';
import { formatPrice } from '@brix/shared-frontend'

const types = [
  {id: 1, name: 'apartment', selected: false, label: 'Apartment'},
  {id: 2, name: 'condo', selected: false, label: 'Condo'},
  {id: 3, name: 'townhouse', selected: false, label: 'Townhouse'},
  {id: 4, name: 'singlefamily', selected: false, label: 'Single-family'},
];

const FilterSideMenu: React.FunctionComponent<{
  onClose: () => void;
  open: boolean;
}> = (props) => {
  const {filtersSelected, setFiltersSelected} = useContext(FilterContext);

  const [value, setValue] = useState<number[]>([50000, 10000000]);
  const [typeSelected, setTypeSelected] = useState<any[]>([]);
  const {onClose, open} = props;
  const lgUp = useMediaQuery(
    (theme: {breakpoints: {up: (breakpoint: string) => string}}) => theme.breakpoints.up('lg'),
    {
      noSsr: true,
    }
  );

  const handlePriceRangeChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  useEffect(() => {
    setFiltersSelected({...filtersSelected, range: value});
  }, [value]);

  const handleSelectFilter = async (type: {id: number; name: string; selected: boolean}) => {
    type.selected = !type.selected;
    type.selected
      ? setTypeSelected([...typeSelected, type.id])
      : setTypeSelected(typeSelected.filter((filter) => filter !== type.id));
  };

  useEffect(() => {
    setFiltersSelected({...filtersSelected, types: typeSelected});
  }, [typeSelected]);

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: 12,
          backgroundColor: 'background.paper',
          height: '100%',
        }}
      >
        <Box sx={{px: 2}}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
              display: 'flex',
              px: 3,
              py: 4,
              borderRadius: 1,
            }}
          >
            <Filter style={{color: 'black'}} />
            <Typography color="black" variant="h6" sx={{fontWeight: 700, ml: '4px', mb: '4px'}}>
              Filters
            </Typography>
          </Box>
          <Container>
            <Typography color="gray" variant="subtitle2" sx={{fontWeight: 600, fontSize: '11px', mb: 2}}>
              Property Price Range
            </Typography>
            <Slider value={value} valueLabelFormat={(value) => formatPrice(value)} step={10000} min={50000} max={10000000} onChange={debounce(handlePriceRangeChange, 300)} valueLabelDisplay="auto" />
            <Divider
              sx={{
                my: 2,
              }}
            />
            <Typography color="gray" variant="subtitle2" sx={{fontWeight: 600, fontSize: '11px'}}>
              PROPERTY TYPE
            </Typography>
            <FormGroup>
              {types.map((type: {id: number; name: string; selected: boolean; label: string}) => (
                <FormControlLabel
                  key={type.id}
                  label={type.label}
                  sx={{color: 'black'}}
                  control={<Checkbox onChange={() => handleSelectFilter(type)} value={type.selected} />}
                />
              ))}
            </FormGroup>
            <Divider
              sx={{
                my: 2,
              }}
            />
            <SaleTypeMenu />
            <SelectOrderMenu />
            <Divider
              sx={{
                my: 2,
              }}
            />
          </Container>
        </Box>
        <Box sx={{flexGrow: 1}}></Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
            boxShadow: (theme) => theme.shadows[3],
            color: '#FFFFFF',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{zIndex: (theme) => theme.zIndex.appBar + 100}}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default FilterSideMenu;
