import React, {useState, useEffect, useContext} from 'react';
import {Button, Container, Grid, SvgIcon, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import api from '@panel/utils/api';
import {useQuery} from 'react-query';
import MarketplaceItem from '@panel/views/marketplace/PropertyGridView/MarketplaceItem';
import {IMarketplaceItem} from '@brix/shared-frontend';
import Slider from 'react-slick';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FilterContext } from '@panel/components/PropertyFilter';

const NextArrow = (props: any) => {
  const {style, onClick} = props;
  return (
    <ArrowCircleRightIcon
      color="primary"
      style={{
        ...style,
        position: 'absolute',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        right: '-25px',
        top: '50%',
        display: 'block',
        fontSize: '60px',
        zIndex: '9',
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props: any) => {
  const {style, onClick} = props;
  return (
    <ArrowCircleLeftIcon
      color="primary"
      style={{
        ...style,
        position: 'absolute',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        left: '-25px',
        top: '50%',
        display: 'block',
        fontSize: '60px',
        zIndex: '9',
      }}
      onClick={onClick}
    />
  );
};

const RecommendedProperties: React.FC = () => {
  const [properties, setProperties] = useState<IMarketplaceItem[]>([]);
  const history = useHistory();
  const { filtersSelected } = useContext(FilterContext);

  const {
    isLoading,
    isError,
    data: recommendedProperties,
  } = useQuery(['property', 'recommendedProperties', filtersSelected], () => api['properties'].get(`/`, {
    limit: '8',
    page: 1,
    filter: [
      `grossValue||$between||${filtersSelected.range.length === 0 ? '50000,1000000' : filtersSelected.range.join(',')}`,
      `publishedAt||$notnull`,
    ],
    include: ['address', 'poster', 'financial'],
  }));

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: 'ease-in-out',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (recommendedProperties) {
      setProperties(recommendedProperties?.data?.data);
    }
  }, [recommendedProperties]);

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="space-between" sx={{my: 1}}>
        <Typography color="black" sx={{fontWeight: 600, my: 2}} variant="h6">
          Other investors look at
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            borderRadius: '25px',
            color: 'black',
            borderColor: 'rgba(0,0,0,0.1)',
            height: '40px',
            my: {xs: 2, sm: 0},
          }}
          onClick={() => history.push(`/marketplace`)}
          endIcon={
            <SvgIcon fontSize="inherit">
              <ArrowForwardIosRoundedIcon />
            </SvgIcon>
          }
        >
          {'Go to Marketplace'}
        </Button>
      </Grid>
      <Slider {...settings}>
        {properties &&
          properties.length > 0 &&
          properties.map((data) => {
            return (
              <Grid key={data.id} item xs={12} sx={{p: 2}}>
                <MarketplaceItem
                {...data}
                __poster__={data?.__poster__?.key}
                publicationDate={data?.publishedAt}
                financial={data?.__financial__}
                saleType={data.saleType}
              />
              </Grid>
            );
          })}
      </Slider>
    </Container>
  );
};

export default RecommendedProperties;
