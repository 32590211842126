import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {QontoStepIcon, QontoConnector} from '@panel/components/QontoStepIcon';
import {Typography} from '@mui/material';

const steps = [
  {
    label: 'Payment Method',
    number: 0,
  },
  {
    label: 'Review',
    number: 1,
  },
  {
    label: 'Verification',
    number: 2,
  },
];

export const ProgressHeader = ({currentStep}: {currentStep: number}) => {
  return (
    <Stack sx={{width: '100%', py: 2, backgroundColor: 'background.default', color: 'text.secondary'}} spacing={4}>
      <Stepper sx={{mt: 2}} alternativeLabel activeStep={currentStep} connector={<QontoConnector />}>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Typography sx={{color: step.number <= currentStep ? 'primary.main' : 'text.secondary', mt: '-12px'}}>
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default ProgressHeader;
