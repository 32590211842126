import * as Yup from 'yup';

export const ProfileValidation = Yup.object().shape({
  firstName: Yup.string()
    .required('Your first name is required')
    .trim('Your first name cannot include leading and trailing spaces')
    .min(3, 'Your first name must have at least 3 chars'),
  lastName: Yup.string()
    .required('Your last name is required')
    .trim('Your last name cannot include leading and trailing spaces')
    .min(3, 'Your last name must have at least 3 chars'),
  username: Yup.string()
    .trim('Your username cannot include leading and trailing spaces')
    .min(3, 'Your username must have at least 3 chars'),
  citizenship: Yup.string()
    .typeError('Invalid Citizenship')
    .min(2, 'Citizenship is required')
    .max(50, 'Citizenship must have maximum 50 chars')
    .required('Citizenship is required'),
  citizenshipStatus: Yup.string()
    .min(2, 'Citizenship Status is required')
    .typeError('Invalid Citizenship Status')
    .required('Citizenship Status is required'),
  idDocument: Yup.array(),
  address: Yup.string()
    .typeError('Invalid Address')
    .min(3, 'Address must have at least 3 chars')
    .required('Address is required'),
  address2: Yup.string().typeError('Invalid Address 2').optional().nullable(),
  countryId: Yup.string().typeError('Invalid Country')
  .required('Country is required'),
  city: Yup.string().typeError('Invalid City').required('City is required'),
  stateCode: Yup.string().required('State is a required field'),
  zipCode: Yup.string().typeError('Invalid Zip Code').required('Zip Code is required'),
  ssn: Yup.string().typeError('Invalid SSN').required('SSN is required'),
  email: Yup.string().email('Invalid email'),
  birthday: Yup.date()
    .typeError('Invalid date')
    .required('Date of birth is required')
    .max(new Date(), 'Date of birth must be in the past'),
  phone: Yup.string(),
});
