import {AppBar, Box, Container, IconButton, Toolbar, Typography} from '@mui/material';
import {Link as RouterLink, NavLink} from 'react-router-dom';
import {AccountButton} from './AccountButton';
import {CartButton} from './CartButton';
import {styled} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '@panel/components/Logo';

const sections = [
  {
    title: 'Dashboard',
    path: '/dashboard',
  },
  {
    title: 'Marketplace',
    path: '/marketplace',
  },
  {
    title: 'Portfolio',
    path: '/portfolio',
  },
  {
    title: 'Financials',
    path: '/financials',
  },
  {
    title: 'Reporting',
    path: '/reporting',
  },
];

const DashboardNavbarRoot = styled(AppBar)(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none',
      }),
}));

export const DashboardNavbar: React.FC<{
  onOpenSidebar: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  const {onOpenSidebar, ...other} = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            ml: {
              lg: 6,
            },
            px: {sm: 0, lg: 6},
            py: 1,
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none',
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: {xs: '100%', lg: '0'},
              display: 'inline-flex',
            }}
          >
            <Box
              sx={{
                ml: {xs: 'auto', lg: '0'},
                mr: {xs: 'auto', lg: '0'},
              }}
            >
              <RouterLink to="/">
                <Logo height={60} width={60} />
              </RouterLink>
            </Box>
          </Box>
          <Box sx={{flexGrow: 0.4}} />
          <Box
            sx={{
              alignItems: 'center',
              display: {
                lg: 'flex',
                xs: 'none',
              },
            }}
          >
            {sections.map((section) => (
              <NavLink
                key={section.title}
                style={{
                  textDecoration: 'none',
                  color: 'gray',
                }}
                activeStyle={{color: '#CC4645'}}
                exact
                to={section.path}
              >
                <Typography
                  sx={{
                    '&:hover': {opacity: 0.7},
                    transition: '0.2s ease-in-out',
                    textTransform: 'uppercase',
                    mr: 6,
                  }}
                  variant="body2"
                >
                  {section.title}
                </Typography>
              </NavLink>
            ))}
          </Box>
          <Box sx={{flexGrow: 1}} />
          <CartButton />
          <AccountButton />
        </Toolbar>
      </Container>
    </DashboardNavbarRoot>
  );
};
