import {useCartContext} from '@panel/contexts/CartContext';
import {ICartProduct} from '@panel/interfaces';

const useCartTotal = () => {
  const {total, setTotal} = useCartContext();

  const updateCartTotal = (products: Partial<ICartProduct>[]) => {
    const productQuantity = products.reduce((sum: number, product: Partial<ICartProduct>) => {
      sum += Number(product.quantity ?? 0);
      return sum;
    }, 0);

    const propertyQuantity = products.length;

    const totalPrice = products.reduce((sum: number, product: Partial<ICartProduct>) => {
      sum += (product.currentValue ?? 0) * (product.quantity ?? 0);
      return sum;
    }, 0);

    const total = {
      productQuantity,
      propertyQuantity,
      totalPrice,
      currencyId: 'USD',
      currencyFormat: '$',
    };

    setTotal(total);
  };

  return {
    total,
    updateCartTotal,
  };
};

export default useCartTotal;
