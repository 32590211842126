import {Page, Header} from '@panel/components';
import {IPage, IPropertyAddress, IPropertySpecs, getCountryName, IPropertyDescription} from '@brix/shared-frontend';
import {api, loadCountries} from '@panel/utils';
import {IBreadCrumb} from '@panel/interfaces/breadcrumb';
import {useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {styled} from '@mui/material/styles';
import {Alert, Box, LinearProgress, Typography} from '@mui/material';
import RecommendedProperties from './Details/RecommendedProperties';
import Details from './Details';
import Media from './Details/Media';
import SubHeader from './SubHeader';
import BrixSelectionModal from '@panel/components/BrixSelectionModal';


const PropertyDetailsLayoutRoot = styled('div')(({theme}) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
}));

const PropertyDetailsView: React.FunctionComponent<IPage> = (props): JSX.Element => {
  const {id} = useParams<Record<string, undefined>>();
  const {name} = props;
  const [breadCrumbItems, setBreadCrumbItems] = useState<IBreadCrumb[]>([
    {
      text: 'Dashboard',
      url: '/dashboard',
    },
    {
      text: 'Marketplace',
      url: '/marketplace',
    },
  ]);
  const [isBrixModalOpen, setIsBrixModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [title, setTitle] = useState('');
  const [lotSize, setLotSize] = useState<number>(0);
  const [featuredImageUrl, setFeaturedImageUrl] = useState('');
  const [propertyData, setPropertyData] = useState<IPropertySpecs>({
    size: 0,
    numberOfBathrooms: 0,
    numberOfBedrooms: 0,
    repairQuality: 1,
    status: 1,
    description: '',
    closingValue: 0,
  });

  const [addressItem, setAddressItem] = useState<IPropertyAddress>();
  const {
    isLoading,
    isError,
    data: dataFetched,
    refetch: refetchProperty
  } = useQuery(['property', id, 'panel'], () =>
    api['properties'].get(`/${id}`, {include: ['address', 'poster', 'media', 'financial']})
  );

  const {
    isLoading: isAddressLoading,
    isError: isErrorAddress,
    data: AddressData,
    refetch: refetchAddress
  } = useQuery(['address', id, 'panel'], () =>
    api['properties'].get(`/${id}/address`)
  );

  const {
    isLoading: isDescriptionLoading,
    isError: isErrorDescription,
    data: DescriptionData,
    refetch: refetchDescription
  } = useQuery(['description', id, 'panel'], () =>
    api['properties'].get(`/${id}/descriptions`)
  );

  useEffect(() => {
    refetchProperty();
    refetchAddress();
    refetchDescription();

  }, [isLoading, isAddressLoading, isDescriptionLoading]);

  const handleClose = (value: string) => {
    setIsBrixModalOpen(false);
    setSelectedValue(value);
  };
  
  useEffect(() => {
    if (dataFetched && AddressData && DescriptionData) {
      const description = DescriptionData?.data?.find( (description: IPropertyDescription) => description.locale === "en" );
      const name = dataFetched?.data?.name;
      const financialData = dataFetched?.data?.__financial__;
      const addressData = AddressData?.data;
      const specs = {
        name: dataFetched?.data?.name || 'Unspecified',
        yearBuilt: dataFetched?.data?.constructionYear || 'Unspecified',
        numberOfBathrooms: dataFetched?.data?.numberOfBathrooms || 0,
        numberOfBedrooms: dataFetched?.data?.numberOfBedrooms || 0,
        arv: dataFetched?.data?.arv || 0,
        repairQuality: dataFetched?.data?.repairQuality || 'Unspecified',
        status: dataFetched?.data?.status || 0,
        city: addressData?.city || 'Unspecified',
        states: addressData?.stateCode || 'Unspecified',
        //Monthly dividend on hold of the backend side
        description: description?.value || 'Unspecified',
        listedOn: Math.round(
          Number(new Date().getTime() - new Date(dataFetched.data.createdAt).getTime()) / (1000 * 3600 * 24)
        ),
        lat: Number(addressData?.latitude),
        lng: Number(addressData?.longitude),
        saleType: dataFetched?.data?.saleType,
        type: dataFetched?.data?.type || 0,
        lotSize: dataFetched?.data?.lotSize || 0,
        leaseEndDate: dataFetched?.data?.leaseEndDate || 'Unspecified',
        size: dataFetched?.data?.size || 'No Data',
        rentalStatus: dataFetched?.data?.rentalStatus || 'Unspecified',
        rentalGrossAmount: financialData?.rentalGrossAmount || 0,
        rentalNetAmount: financialData?.rentalNetAmount || 0,
        maintenanceAmount: financialData?.maintenanceAmount || 0,
        taxesAmount: financialData?.taxesAmount || 0,
        insuranceAmount: financialData?.insuranceAmount || 0,
        managementFeeAmount: financialData?.managementFeeAmount || 0,
        expensesAmount: financialData?.expensesAmount || 0,
        mortgageMonthlyAmount: financialData?.mortgageMonthlyAmount || 0,
        mortgageInterestAmount: financialData?.mortgageInterestAmount || 0,
        reservesPct: financialData?.reservesPct || 0,
        closingValue: dataFetched?.data?.closingValue || 0,
      };
      title !== name && setTitle(name);
      setAddressItem(AddressData.data);
      setLotSize(dataFetched.data.lotSize);
      setFeaturedImageUrl(dataFetched?.data?.__poster__?.key);
      setPropertyData(specs);
      title !== name && setBreadCrumbItems([...breadCrumbItems, {text: name}]);
    } else {
      refetchProperty();
      refetchAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched, AddressData]);

  return (
    <Page title={title}>
      <PropertyDetailsLayoutRoot>
        <Box
          sx={{
            flexDirection: 'column',
            width: '100%',
            flexGrow: 1,
            py: {
              xs: 4,
              sm: 8,
            },
            px: {
              xs: 2,
              sm: 8,
            },
            backgroundColor: 'background.default',
            height: '100%',
          }}
        >
          {isLoading && (
            <Box>
              <LinearProgress />
            </Box>
          )}
          {isError ? (
            <Box mt={3}>
              <Alert severity="error">Unexpected error occurred. Unable to collect property data.</Alert>
            </Box>
          ) : (
            <>
              <Header breadCrumbTitle={name ?? ''} breadCrumbItems={breadCrumbItems} />
              <Typography variant="h4" sx={{py: 2}}>
                {title}
              </Typography>
              <SubHeader
                openBrixModal={() => setIsBrixModalOpen(true)}
                addressItem={addressItem}
                lotSize={lotSize}
                type={propertyData?.type}
                size={propertyData?.size}
                status={propertyData?.status}
                {...propertyData}
              />
              <Media featuredImageUrl={featuredImageUrl} propertyId={id} />
              <Details
                openBrixModal={() => setIsBrixModalOpen(true)}
                propertyData={propertyData}
                addressItem={addressItem}
                featuredImageUrl={featuredImageUrl}
              />
            </>
          )}
        </Box>
      </PropertyDetailsLayoutRoot>
      <Box sx={{maxWidth: '100vw', my: 8, mx: {xs: 2, md: 4}}}>
        <RecommendedProperties />
      </Box>
      <BrixSelectionModal
        selectedPropertyId={id}
        selectedProperty={{
          propertyId: id,
          propertyName: title,
          propertyAddress: addressItem?.city + ', ' + addressItem?.stateCode,
          propertyImage: featuredImageUrl,
        }}
        selectedValue={selectedValue}
        open={isBrixModalOpen}
        onClose={handleClose}
      />
    </Page>
  );
};

export default PropertyDetailsView;
