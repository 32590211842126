import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {QontoConnector, QontoStepIcon} from '@panel/components/QontoStepIcon';
import {Typography} from '@mui/material';

interface LabeledIndex {
  label: string;
  index: number;
}

const steps: Array<LabeledIndex> = [
  {
    label: 'Transfer Details',
    index: 1,
  },
  {
    label: 'Bank Information',
    index: 2,
  },
  {
    label: 'Review',
    index: 3,
  },
];

const stepper_styles = {mt: 2, width: '60%', ml: '20%'};
const stack_styles = {width: '100%', py: 4, backgroundColor: 'background.default', color: 'text.secondary'};

export const BalanceTransferProgressBar = ({currentStep}: { currentStep: number }) => {
  return (
    <Stack sx={stack_styles} spacing={4}>
      <Stepper sx={stepper_styles} alternativeLabel activeStep={currentStep - 1} connector={<QontoConnector/>}>
        {steps.map((step: LabeledIndex) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Typography sx={{color: step.index <= currentStep ? 'primary.main' : 'text.secondary', mt: '-12px'}}>
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default BalanceTransferProgressBar;
