/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Button, Card, Dialog, Grid, IconButton, MenuItem, TextField, Typography} from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import useStripeBankAccount, {BankAccountInfo} from '@panel/hooks/stripe/useStripeBankAccount';
import {toast} from 'react-toastify';

type ResaleOptions = {
  open: boolean;
  onClose: () => void;
};

const ResaleSchema = Yup.object().shape({
  country: Yup.string().required('This field is required'),
  currency: Yup.string().required('This field is required'),
  routing_number: Yup.string().required('This field is required'),
  account_number: Yup.string().required('This field is required'),
  account_holder_name: Yup.string().required('This field is required'),
  account_holder_type: Yup.string().required('This field is required'),
});

const BrixBankAccountModal = ({open, onClose}: ResaleOptions) => {
  const {addNewBankAccount} = useStripeBankAccount();

  const {register, handleSubmit, formState} = useForm<BankAccountInfo>({
    resolver: yupResolver(ResaleSchema),
    mode: "all",
  });

  const handleClose = () => {
    onClose();
  };

  const handleAddAccount = (info: BankAccountInfo) => {
    addNewBankAccount(info).then(() => {
      toast.success('Bank Account Registered');
      onClose && onClose();
    }, ({message}: { message: string }) => {
      toast.error(message);
    });
    // onClose();
  }

  return (
    <Dialog disableScrollLock onClose={handleClose} open={open}>
      <Card sx={{px: 6, pt: 2, pb: 2, border: 'none', boxShadow: 'none', backgroundColor: 'background.default'}}>
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <Typography variant="h4" align="center">
            Add Payout Bank Account
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
            Please complete the following form to continue:
          </Typography>
        </Box>
        <Box sx={{position: 'absolute', right: 8, top: 8}}>
          <IconButton onClick={handleClose}>
            <CloseIcon/>{' '}
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(handleAddAccount)}>
          <Grid item xs={12}>
            <Box sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              px: {xs: 2, md: 16},
            }}>
              <Grid spacing={2} sx={{width: 640, px: 10, mt: 1}} container>
                <Grid item xs={12}>
                  <TextField {...register('country')} select={true}
                             label={'Country'} fullWidth sx={{my: 1}}
                             defaultValue={'US'}>
                    <MenuItem sx={{color: 'black'}} value={'US'}>
                      United States
                    </MenuItem>
                    <MenuItem sx={{color: 'black'}} value={'CO'}>
                      Colombia
                    </MenuItem>
                    <MenuItem sx={{color: 'black'}} value={'AU'}>
                      Australia
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid spacing={2} sx={{width: 640, px: 10, mt: 1}} container>
                <Grid item xs={6}>
                  <TextField {...register('currency')} select={true}
                             label={'Currency'} fullWidth sx={{my: 1}}
                             defaultValue={'USD'}>
                    <MenuItem sx={{color: 'black'}} value={'USD'}>
                      US Dollar
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField {...register('account_holder_type')} select={true}
                             label={'Account Holder Type'} fullWidth sx={{my: 1}}
                             defaultValue={'individual'}>
                    <MenuItem sx={{color: 'black'}} value={'individual'}>
                      Individual
                    </MenuItem>
                    <MenuItem sx={{color: 'black'}} value={'company'}>
                      Company
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid spacing={2} sx={{width: 640, px: 10, mt: 1}} container>
                <Grid item xs={12}>
                  <TextField label={'Routing Number'} {...register('routing_number')} fullWidth type="text"/>
                </Grid>
              </Grid>
              <Grid spacing={2} sx={{width: 640, px: 10, mt: 1}} container>
                <Grid item xs={12}>
                  <TextField label={'Account Number'} {...register('account_number')} fullWidth type="text"/>
                </Grid>
              </Grid>
              <Grid spacing={2} sx={{width: 640, px: 10, mt: 1}} container>
                <Grid item xs={12}>
                  <TextField label={'Account Holder Name'} {...register('account_holder_name')} fullWidth type="text"/>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Button type={'submit'}
                          disabled={!formState.isValid}
                          fullWidth
                          variant="contained"
                          color="primary"
                          sx={{mt: 2, height: '40px'}}>
                    <Typography variant="body2">Add Account</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </form>
      </Card>
    </Dialog>
  );
};

export default BrixBankAccountModal;
