import {Avatar, Badge, Box, Divider, Grid, IconButton, Tab, Tabs, Typography} from '@mui/material';
import {IPage, UserRole} from '@brix/shared-frontend';
import Page from '@panel/components/Page';
import {useHistory} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import GeneralInformation from './GeneralInformation';
import CompleteProfileBanner from '@panel/components/CompleteProfileBanner';
import Security from './Security';
import PaymentMethods from './PaymentMethods';
import Notifications from './Notifications';
import React, {useEffect, useState} from 'react';
import useAuth from '@panel/hooks/useAuth';
import {getInitials} from '@panel/utils/get-initials';
import {TabPanelProps} from '@panel/interfaces/tab-panel';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {EditProfilePictureModal} from '@panel/components/EditProfilePictureModal';

const tabs = [
  {
    id: 1,
    label: 'General Information',
    subLabel: 'Personal information & account settings',
    icon: <AccountCircleIcon/>,
    component: <GeneralInformation/>,
  },
  {
    id: 2,
    label: 'Security',
    subLabel: 'Password, 2FA & security',
    icon: <SecurityIcon/>,
    component: <Security/>,
  },
  {
    id: 3,
    label: 'Payment & Payout',
    subLabel: 'Payment and payout information',
    icon: <CreditCardIcon/>,
    component: <PaymentMethods/>,
  },
  {
    id: 4,
    label: 'Notifications',
    subLabel: 'Set your notifications',
    icon: <NotificationsNoneIcon/>,
    component: <Notifications/>,
  },
];

const TabPanel: React.JSXElementConstructor<TabPanelProps> = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3, width: {md: '68vw', lg: '75vw'}}}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

export const ProfileView: React.FC<IPage> = (props) => {
  const [value, setValue] = useState(Number(localStorage.getItem('tab')) ?? 1);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const {logout, user} = useAuth();
  const history = useHistory();
  const {name} = props;

  const handleLogout = async () => {
    try {
      await logout(user?.email || '');
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    localStorage.setItem('tab', '1')
  }, []);

  const handleEditPicture = () => {
    setEditProfile(true);
  }

  const handleCloseModal = () => {
    setEditProfile(false);
  }

  return (
    <>
      <Page title={name ?? ''}>
        <Box sx={{flexGrow: 1, pt: 2, backgroundColor: 'background.default', display: 'flex'}}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            sx={{
              backgroundColor: 'background.paper',
              borderRight: 1,
              borderColor: 'divider',
              minWidth: {xs: '50px', sm: '250px'},
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: {xs: 'none', sm: 'flex'},
                justifyContent: 'center',
                flexDirection: 'column',
                pt: 4,
                pb: 2,
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                badgeContent={
                  <IconButton size="small"
                              onClick={handleEditPicture}
                              sx={{
                                borderRadius: '50%',
                                backgroundColor: 'black',
                                color: 'white',
                                border: '2px solid white',
                                '&:hover': {backgroundColor: 'gray'},
                              }}>{' '}
                    <EditOutlinedIcon sx={{fontSize: '14px'}}/>
                  </IconButton>
                }
              >
                <Avatar sx={{height: 70, width: 70, backgroundColor: '#508cb8',}} src={user?.avatarURL}>
                  {getInitials(user?.firstName + ' ' + user?.lastName)}
                </Avatar>
              </Badge>
              <Typography sx={{pt: 2}} variant="subtitle2">
                {user?.firstName + ' ' + user?.lastName}
              </Typography>
              {!user?.roles.includes(UserRole.Investor) && (
                <Typography sx={{opacity: 0.7}} variant="body1">
                  Individual Investor
                </Typography>
              )}
            </Box>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                sx={{ml: 3, py: 1, right: 12}}
                {...a11yProps(tab.id)}
                label={
                  <>
                    <Grid
                      container
                      alignItems="center"
                      sx={{py: {xs: 3, sm: 0}, '&:hover': {backgroundColor: 'background.default'}}}
                    >
                      <Grid item xs={12} sm={2}>
                        <Box>{tab.icon}</Box>
                      </Grid>
                      <Grid item xs={0} sm={9} sx={{display: {xs: 'none', sm: 'block'}}}>
                        <Typography align="left" variant="body2" sx={{ml: 1}}>
                          {tab.label}
                        </Typography>
                        <Typography
                          align="left"
                          variant="body1"
                          sx={{ml: 1, mt: '4px', opacity: 0.7, fontSize: '12px', lineHeight: 1}}
                        >
                          {tab.subLabel}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <Divider sx={{py: 1}}/>
                      </Grid>
                    </Grid>
                  </>
                }
              />
            ))}
            <Tab
              sx={{ml: 3, py: 1, right: 12}}
              onClick={handleLogout}
              label={
                <Grid
                  container
                  alignItems="center"
                  sx={{py: {xs: 3, sm: 0}, '&:hover': {backgroundColor: 'background.default'}}}
                >
                  <Grid item xs={12} sm={2}>
                    <LogoutOutlinedIcon/>
                  </Grid>
                  <Grid item xs={0} sm={9} sx={{display: {xs: 'none', sm: 'block'}}}>
                    <Typography align="left" variant="body2" sx={{ml: 1, color: 'gray'}}>
                      Log out
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      sx={{ml: 1, mt: '4px', opacity: 0.7, fontSize: '12px', lineHeight: 1, color: 'gray'}}
                    >
                      End current session
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Tabs>
          {tabs.map((tab) => (
            <TabPanel value={value} key={tab.id} index={tab.id}>
              <CompleteProfileBanner/>
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Page>
      {editProfile && (
        <EditProfilePictureModal onClose={handleCloseModal}/>
      )}
    </>
  );
};

export default ProfileView;
