import {IPropertySpecs, PropertySaleTypes, NumberOfRooms} from '@brix/shared-frontend';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import MapIcon from '@mui/icons-material/Map';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaidIcon from '@mui/icons-material/Paid';
import {Button, Card, Grid, Typography} from '@mui/material';

const Hightlights: React.FC<{propertyData: IPropertySpecs}> = (propertyData) => {
  const Area = `${propertyData.propertyData?.city}, ${propertyData.propertyData?.states}`

  const dividendsAnnual = (((Number(propertyData.propertyData?.rentalNetAmount) * 12) / propertyData.propertyData.closingValue) * 100).toFixed(3);

  const highlights = [
    {
      name: 'Bedrooms',
      value: NumberOfRooms(Number(propertyData.propertyData.numberOfBedrooms)),
      icon: <BedIcon sx={{color: 'rgba(0,0,0,0.6)'}} />,
    },
    {
      name: 'Bathrooms',
      value: NumberOfRooms(Number(propertyData.propertyData.numberOfBathrooms)),
      icon: <BathtubIcon sx={{color: 'rgba(0,0,0,0.6)'}} />,
    },
    {
      name: 'Net Annual Dividends % (EST.)',
      value: dividendsAnnual + '%',
      icon: <PaidIcon sx={{color: 'rgba(0,0,0,0.6)'}} />,
    },
    {
      name: 'Area (City, State)',
      value: Area,
      icon: <MapIcon sx={{color: 'rgba(0,0,0,0.6)'}} />,
    },
    {
      name: 'Status',
      value: PropertySaleTypes[Number(propertyData.propertyData.saleType)],
      icon: <CheckCircleOutlineIcon sx={{color: 'rgba(0,0,0,0.6)'}} />,
    },
  ];


  return (
    <Card color="white" sx={{px: 6, py: 2}}>
      <Grid container justifyContent={'space-between'} spacing={0}>
        {highlights.map((highlight) => {
          return (
            <Grid key={highlight.name} item>
              <Typography sx={{color: 'gray', opacity: 0.7, textTransform: 'uppercase'}} variant="body2">
                {highlight.name}
              </Typography>
              <Button sx={{mb: 1, px: 0, mx: 0}} disabled startIcon={highlight.icon}>
                <Typography
                  color="gray"
                  sx={{textTransform: 'none', fontSize: '14px', fontWeight: 600}}
                  variant="body2"
                >
                  {highlight.value}
                </Typography>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default Hightlights;
