import {Box, Button, Card, Grid, Typography} from '@mui/material';
import {Image} from 'mui-image';
import React, {useState} from 'react';
import useStripeBankAccount, {RegisteredBankAccountInfo} from '@panel/hooks/stripe/useStripeBankAccount';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';
import BrixBankAccountModal from '@panel/components/BrixBankAccountModal';

const card_styles: SxProps<Theme> = {
  borderStyle: 'solid',
  borderWidth: 2,
  px: 3,
  py: 2,
  boxShadow: 'none',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {borderColor: 'rgba(0,0,0,1)'},
}

const bankStyles: SxProps<Theme> = {
  textTransform: 'capitalize',
  fontWeight: 400,
  pt: 1,
  fontSize: {
    xs: '11px',
    sm: '0.85rem',
  }
}

const addCardStyles: SxProps<Theme> = {
  borderStyle: 'solid',
  borderColor: 'rgba(0,0,0,.15)',
  borderWidth: 2,
  px: 3,
  py: 1,
  boxShadow: 'none',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {borderColor: 'rgba(0,0,0,1)'},
}

const SelectBankAccount = ({onSelected}: { onSelected: (payment: RegisteredBankAccountInfo) => void }) => {
  const {bankAccounts} = useStripeBankAccount();
  const [selected, setSelected] = useState<string>();

  const handleClickOnPaymentMethod = (account: RegisteredBankAccountInfo) => {
    setSelected(account.id);
    onSelected(account);
  };

  const [visible, setVisible] = useState(false);

  const handleStatusClicked = () => {

  }

  const handleCloseBankAccount = () => {
    setVisible(false);
  }

  const addNewBankAccount = () => {
    setVisible(true);
  }

  return (
    <>
      {bankAccounts?.map((account: RegisteredBankAccountInfo) => {
        return (
          <Grid key={account.id} item xs={12}>
            <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
              <Card sx={{...card_styles, borderColor: selected === account.id ? 'black' : 'rgba(0,0,0,.15)'}}
                    onClick={() => handleClickOnPaymentMethod(account)}>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={2}>
                    <Image src={'../assets/images/bank.png'}
                           alt={'BRiX'}
                           duration={50}
                           easing="ease-in-out"
                           width={'90%'}
                           height={'90%'}
                           fit="contain"/>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Typography variant="body1" sx={bankStyles}>
                      {account.bank_name} {' ****'}
                      <span style={{fontWeight: 600}}>{account.last4}</span>
                    </Typography>
                    <Typography variant="body1" sx={{fontWeight: 400, fontSize: {xs: '11px', sm: '0.85rem'}}}>
                      {'Routing Number: ' + (account.routing_number)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Box sx={{display: 'flex', pt: 1}}>
                      <Button variant="contained" size="small" onClick={handleStatusClicked}>
                        {account.status?.toUpperCase()}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
          <Card sx={addCardStyles} onClick={addNewBankAccount}>
            <Typography sx={{fontWeight: 600, textAlign: 'center'}}>+ Add New Bank Account</Typography>
          </Card>
        </Box>
      </Grid>

      {visible && (
        <BrixBankAccountModal open={true} onClose={handleCloseBankAccount}/>
      )}
    </>
  );
};

export default SelectBankAccount;
