import {Button, Card, Container, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import useAuth from '@panel/hooks/useAuth';
import api from '@panel/utils/api';
import {IPaymentMethod} from '@brix/shared-frontend';
import {useQuery} from 'react-query';
import useApi from '@panel/utils/apis';
import SelectMethodHeader, {SelectedPaymentMethodCategory} from './SelectMethodHeader';
import PaymentMethodItem from './PaymentMethodItem';
import SelectWalletMethod from '@panel/views/marketplace/CheckoutView/CheckoutWizard/PaymentMethod/SelectWalletMethod';

const PaymentMethod: React.FunctionComponent<{
  prevStep: () => void;
  nextStep: () => void;
  setPayment: any;
}> = ({prevStep, nextStep, setPayment}) => {
  const {user} = useAuth();
  const {paymentMethodApi} = useApi();
  const [selectedPaymentMethodCategory, setSelectedPaymentMethodCategory] = useState<SelectedPaymentMethodCategory>('creditCard');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod | null>();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const {data: paymentMethodsData, refetch, isLoading: isPaymentLoading} = useQuery(['payment-method', user?.id], () =>
  paymentMethodApi.get('/user/' + user?.id)
);
  
  const handleChangeSelectedPaymentMethodCategory = (e: SelectedPaymentMethodCategory) => {
    setSelectedPaymentMethodCategory(e);
    if (selectedPaymentMethodCategory === 'wallet') {
      onWalletSelected();
    } else {
      handleDefaultPaymentMethodCC();
    }
  };

  useEffect(() => {
    selectedPaymentMethodCategory === 'wallet' && selectedPaymentMethod?.type === 'card' && onWalletSelected();
    selectedPaymentMethodCategory === 'creditCard' && selectedPaymentMethod?.type === 'wallet' && handleDefaultPaymentMethodCC();
  }, [selectedPaymentMethodCategory])

  const handleClickOnPaymentMethod = (paymentMethod: IPaymentMethod) => {
    if (selectedPaymentMethod === paymentMethod) {
      setPayment(null);
      setSelectedPaymentMethod(null);
      return;
    }
    setPayment(paymentMethod);
    setSelectedPaymentMethod(paymentMethod);
  };

  const onWalletSelected = () => {
    const method: IPaymentMethod = {
      type: 'wallet',
      brand: 'custom',
      lastFourDigits: 1001,
      provider: 'brix',
      id: 'wallet',
      expirationDate: 'NA'
    }
    setPayment(method);
    setSelectedPaymentMethod(method);
  };

  const popDefaultPaymentMethods = () => {
    if (paymentMethodsData && paymentMethodsData?.data?.length > 0) {
      setPaymentMethods(paymentMethodsData?.data);
    } else {
      setPaymentMethods([]);
    }
  }

  const handleDefaultPaymentMethodCC = () => {
    const defaultPaymentMethod = paymentMethods.find((paymentMethod: IPaymentMethod) => paymentMethod.isDefault);
    if (defaultPaymentMethod && defaultPaymentMethod.type === 'card') {
      setPayment(defaultPaymentMethod);
      setSelectedPaymentMethod(defaultPaymentMethod);
    }
  }

  useEffect(popDefaultPaymentMethods, [paymentMethodsData, isPaymentLoading]);

  useEffect(handleDefaultPaymentMethodCC, [paymentMethods, paymentMethodsData, isPaymentLoading]);


  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: {
          xs: 2,
          md: 0,
          lg: 0,
        },
      }}
    >
      <Card sx={{p: 4, border: 'none', boxShadow: 'none'}}>
        <SelectMethodHeader category={selectedPaymentMethodCategory}
                            setCategory={handleChangeSelectedPaymentMethodCategory}/>
        {selectedPaymentMethodCategory === 'creditCard' &&
        paymentMethods?.map((paymentMethod: IPaymentMethod) => {
          return (
            paymentMethod.type === 'card' && (
              <Grid key={paymentMethod.id} item xs={12}>
                <PaymentMethodItem
                  selected={selectedPaymentMethod?.id === paymentMethod.id}
                  handleClickOnPaymentMethod={handleClickOnPaymentMethod}
                  paymentMethod={paymentMethod}
                />
              </Grid>
            )
          );
        })}
        {selectedPaymentMethodCategory === 'wallet' &&
        <SelectWalletMethod selected={true} onSelected={onWalletSelected}/>}
        <Grid container mt={2} spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              onClick={prevStep}
              sx={{color: '#000000', borderColor: '#000000'}}
              fullWidth
              size="large"
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button disabled={!selectedPaymentMethod} onClick={nextStep}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default PaymentMethod;
