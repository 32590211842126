import { Link as RouterLink } from 'react-router-dom';
import { Button, Card, CardMedia, Chip, Container, Divider, Grid, Typography, Box } from '@mui/material';
import { formatWithDigits, IMarketplaceItem, PropertyTypes, PropertySaleTypes, Infographic, formatPriceWithoutSymbol } from '@brix/shared-frontend';
import { useState, useEffect } from 'react';
import BrixSelectionModal from '@panel/components/BrixSelectionModal';
import { useQuery } from 'react-query';
import api from '@panel/utils/api';

const MarketplaceItem = ({
  id,
  name,
  __poster__,
  type,
  grossValue,
  closingValue,
  saleType,
  financial,
  publicationDate,
}: IMarketplaceItem) => {
  const [isBrixModalOpen, setIsBrixModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [address, setAddress] = useState('')
  const [infographics, setInfographics] = useState<Infographic>({});

  const handleClose = (value: string) => {
    setIsBrixModalOpen(false);
    setSelectedValue(value);
  };

  const {
    isLoading: isAddressLoading,
    isError: isErrorAddress,
    data: AddressData,
  } = useQuery(['address', 'investorPanel', id], () =>
    api['properties'].get(`/${id}/address`)
  );

  const {
    isLoading: isInfographicsLoading,
    isError: isErrorInfographics,
    data: InfographicsData,
  } = useQuery(['infographics', 'investorPanel', id], () =>
    api['properties'].get(`/${id}/infographics`)
  );

  useEffect(() => {
    if (AddressData) {
      setAddress(AddressData.data.city + ', ' + AddressData.data.stateCode);
    }
  }, [AddressData]);

  useEffect(() => {
    if (InfographicsData) {
      setInfographics(InfographicsData.data);
    }
  }, [InfographicsData]);


  const dividendsAnnual = (((Number(financial?.rentalNetAmount) * 12) / closingValue) * 100).toFixed(3);

  const published = new Date(publicationDate).getTime()

  const today = new Date().getTime()

  const days = Math.floor((today - published) / (1000 * 60 * 60 * 24));

  return (
    <Card>
      <Box sx={{
        ml: '12px',
      }}>
        <CardMedia component="img" height="250" image={__poster__ ? __poster__ : '../assets/svg/logo-demo.svg'} />
      </Box>
      {
        infographics.available === 0 && (
          <Box sx={{
            zIndex: 3,
            position: 'absolute',
          }}>
            <img src='../../../assets/images/SoldOutTag.png' alt="logo" style={{ width: '120px', position: 'relative', top: '-240px', right: '10px' }} />
          </Box>
        )
      }
      <Container sx={{ mt: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1} >
          <Grid item >
            <Chip
              sx={{
                color:
                  Number(type) === 1
                    ? '#508cb8'
                    : Number(type) === 2
                      ? '#9a63a3'
                      : Number(type) === 3
                        ? '#cc4645'
                        : '#39b064',
                backgroundColor:
                  Number(type) === 1
                    ? '#eef4f8'
                    : Number(type) === 2
                      ? '#f5eff6'
                      : Number(type) === 3
                        ? '#ffeeee'
                        : '#e2f4f0',
                borderWidth: 0,
                py: 0,
              }}
              size="small"
              label={
                PropertyTypes[Number(type)].charAt(0).toUpperCase() + PropertyTypes[Number(type)].slice(1)
              }
              variant="outlined"
            />
          </Grid>
          <Grid item >
            <Chip
              label={PropertySaleTypes[Number(saleType)]}
              size="small"
              variant="outlined"
              sx={{
                color: Number(saleType) === 0 ? 'green' : Number(saleType) === 1 ? '#bda300' : '#508cb8',
                backgroundColor: Number(saleType) === 0 ? '#e2f4f0' : Number(saleType) === 1 ? '#fff262' : '#eef4f8',
                borderWidth: 0,
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography color="black" variant="subtitle1" sx={{ mt: '6px', fontWeight: 700 }}>
            {name}
          </Typography>
          <Typography color="gray" variant="body1">
            {address}
          </Typography>
          <Typography color="gray" variant="body1" sx={{ alignSelf: 'end' }} >
            Published {days} days ago
          </Typography>
        </Box>
        <Divider sx={{ mb: 1, mt: 1 }} />
      </Container>
      <Grid container display='flex' flexDirection='column' spacing={2} sx={{ mb: 1 }} >
        <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' sx={{ mr: 3, ml: 3 }} >
          <Typography
            color="gray"
            sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600, opacity: 0.8 }}
            variant="body2"
          >
            Property Value
          </Typography>
          <Typography
            color="gray"
            sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600, opacity: 0.8 }}
            variant="body2"
          >
            {grossValue !== undefined && grossValue !== null ? formatWithDigits(grossValue) : 0}
          </Typography>
        </Grid>
        <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' sx={{ mr: 3, ml: 3 }} >
          <Typography
            color="gray"
            sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600, opacity: 0.8 }}
            variant="body2"
          >
            Net Annual Dividends % (EST.)
          </Typography>
          <Typography
            color="gray"
            sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600, opacity: 0.8 }}
            variant="body2"
          >
            {dividendsAnnual}%
          </Typography>
        </Grid>
        <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' sx={{ mr: 3, ml: 3 }} >
          <Typography
            color="gray"
            sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600, opacity: 0.8 }}
            variant="body2"
          >
            Available BRiX
          </Typography>
          <Typography
            color="gray"
            sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600, opacity: 0.8 }}
            variant="body2"
          >
            {formatPriceWithoutSymbol(Number(infographics.available) /10)}
          </Typography>
        </Grid>
      </Grid>
      <Container sx={{ mb: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <RouterLink to={'/marketplace/' + id} style={{ textDecoration: 'initial' }}>
              <Button fullWidth sx={{ mt: 1 }} color="primary" size="small" variant="contained">
                View
              </Button>
            </RouterLink>
          </Grid>{' '}
          <Grid item xs={6}>
            <Button
              onClick={() => setIsBrixModalOpen(true)}
              fullWidth
              sx={{ mt: 1 }}
              color="primary"
              size="small"
              variant="contained"
            >
              Buy
            </Button>
          </Grid>
        </Grid>
      </Container>
      {isBrixModalOpen && (
        <BrixSelectionModal
          selectedPropertyId={id}
          selectedProperty={{ propertyId: id, propertyName: name, propertyAddress: address, propertyImage: __poster__ }}
          selectedValue={selectedValue}
          open={isBrixModalOpen}
          onClose={handleClose}
        />
      )}
    </Card>
  );
};

export default MarketplaceItem;
