import {Box, Button, Card, Container, Grid, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';

const TransferMessage = () => {
  const history = useHistory();

  return (
    <Container maxWidth="sm"
               sx={{
                 pt: {
                   xs: 2,
                   md: 0,
                   lg: 0,
                 },
               }}>
      <Card sx={{pt: 4, border: 'none', boxShadow: 'none'}}>
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <Typography variant="h3" align="center">
            Your offer is under review
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Box sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
                <img width="75" height="100" alt="brick" src={'../assets/images/brick-congratulation1.png'}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
                Your balance transfer has been submitted and will be available in 2-3 business days. <br/>
                You will be notified when the balance is accepted or rejected via email and in-app.
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
                <img width="60" height="60" alt="brick" src={'../assets/images/brick-congratulation2.png'}/>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          flexGrow: 1,
          mt: 4,
          ml: {
            md: 8,
            xs: 1,
          },
          mr: {
            md: 8,
            xs: 1,
          },
        }}/>
        <Box mt={2}>
          <Grid container mt={2} spacing={2}>
            <Grid item xs={12} md={6}>
              <Button sx={{color: '#000000', borderColor: '#000000'}}
                      onClick={() => history.push(`/financials`)}
                      fullWidth
                      size="large"
                      variant="outlined">
                Go to Finances
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button color="primary" fullWidth
                      size="large"
                      variant="contained"
                      onClick={() => history.push(`/marketplace`)}>
                See properties
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Container>
  );
};

export default TransferMessage;
