import {useRef, useState} from 'react';
import {Avatar, Box, ButtonBase} from '@mui/material';
import {AccountPopover} from '@panel/components/AccountPopover';
import useAuth from '@panel/hooks/useAuth';
import {getInitials} from '@panel/utils/get-initials';

export const AccountButton = () => {
  const {user} = useAuth();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleAccountClicked = () => {
    setOpenPopover(!openPopover);
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <Box onMouseEnter={handleOpenPopover} onMouseLeave={handleClosePopover}>
      <Box aria-owns={openPopover ? 'mouse-over-popover' : undefined}
           aria-haspopup="true"
           component={ButtonBase}
           onClick={handleAccountClicked}
           ref={anchorRef}
           sx={{
             alignItems: 'center',
             display: 'flex',
             ml: 2,
           }}>
        <Avatar sx={{height: 40, width: 40, backgroundColor: '#508cb8'}} src={user?.avatarURL}>
          {getInitials(user?.firstName + ' ' + user?.lastName)}
        </Avatar>
        <AccountPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover}/>
      </Box>
    </Box>
  );
};
