import {Box, Button, Card, Container, Dialog, Divider, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';
import InputCode from '@panel/components/InputCode';
import useAuth from '@panel/hooks/useAuth';
import {api} from '@panel/utils';
import {toast} from 'react-toastify';
import {AxiosResponse} from 'axios';
import axiosInstance from '@panel/utils/axios';

const card_styles: SxProps<Theme> = {
  px: 6,
  pt: 2,
  pb: 2,
  border: 'none',
  boxShadow: 'none',
  backgroundColor: 'background.default',
  overflowY: 'auto',
};

export type MFAConfirmationParams = {
  onValidated: VoidFunction,
}

const MFAConfirmationDialog = ({onValidated}: MFAConfirmationParams) => {

  const [open, setOpen] = useState<boolean>(true);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const {user} = useAuth();


  useEffect(() => {
    setVerifying(true);
    api['/mfa'].post(`/${user?.id}/send`).then(() => {
      toast.success('Code sent to your email.');
    }, () => {
      toast.error('Unable to send code. Please try again later.')
    }).finally(() => setVerifying(false));
  }, []);

  const validate = () => {
    setVerifying(true);
    api['/mfa'].post(`/${user?.id}/revalidate?code=` + code).then((response: AxiosResponse) => {
      const data = response.data;
      localStorage.setItem('mfaTicket', data.ticket);
      axiosInstance.defaults.headers.common['x-auth-ticket'] = data.ticket;
      setOpen(false);
      onValidated();
    }, () => {
      toast.error('Invalid code. Please try again with another one.')
    }).finally(() => setVerifying(false));
  }

  return (
    <Dialog disableEscapeKeyDown disableScrollLock onClose={() => setOpen(true)} open={open}>
      <Card sx={card_styles}>
        <Container maxWidth="sm" sx={{pt: {xs: 4, md: 4, lg: 4}}}>
          <Typography variant="h3" align="center">
            MFA Validation
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
            We've sent you a validation code to your registered email.
          </Typography>
          <Box sx={{mt: 4, textAlign: 'center'}}>
            <Typography variant="body2">Input code here</Typography>
            <Divider sx={{my: 1}}/>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <InputCode
                length={6}
                loading={verifying}
                onComplete={(code: string) => {
                  setCode(code);
                }}
              />
            </Box>
          </Box>

          <Box mt={2} mb={4}>
            <Button color="primary"
                    fullWidth
                    disabled={code === undefined}
                    onClick={validate}
                    size="large"
                    type="submit"
                    variant="contained">
              Validate
            </Button>
          </Box>
        </Container>
      </Card>
    </Dialog>


  )
}

export default MFAConfirmationDialog;
