import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {AuthLayoutTopbar} from './Topbar';
import {AuthLayoutFooter} from './Footer';
import React from 'react';
import {useLocation} from 'react-router-dom';

const AuthLayoutRoot = styled('div')(({theme}) => ({
  height: '100%',
  paddingTop: 64,
}));

export const AuthLayout: React.FC<{children?: React.ReactNode}> = ({children}): JSX.Element => {
  const location = useLocation();

  return (
    <AuthLayoutRoot>
      <AuthLayoutTopbar
        blackText={
          location.pathname === '/login' || location.pathname === '/forgot-password'
            ? 'Need a BRiX account?'
            : 'Already have a BRiX account?'
        }
        redLink={
          location.pathname === '/login' || location.pathname === '/forgot-password' ? 'Create an account' : 'Login'
        }
        uri={location.pathname === '/login' || location.pathname === '/forgot-password' ? '/register' : '/login'}
      />
      {children}
      <AuthLayoutFooter />
    </AuthLayoutRoot>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
