import { IPropertySpecs, PropertyTypes, NumberOfRooms, firstLetterUppercase } from '@brix/shared-frontend';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {api} from '@panel/utils';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import moment from 'moment';
import { useEffect, useState } from 'react';

const Specs: React.FC<{ propertyData: IPropertySpecs }> = (propertyData) => {
  const { listedOn, type, size, numberOfBedrooms, numberOfBathrooms, city, yearBuilt } = propertyData.propertyData;
  const [leaseEndData, setLeaseEndData] = useState<Date>();

  const {id} = useParams<Record<string, undefined>>();

  const propertyTypeLabel = PropertyTypes[Number(type) || 0];

  const {
    isLoading: isRentalLoading,
    isError: isErrorRental,
    data: rentalData,
  } = useQuery(['property', 'panel', id], () =>
    api['properties'].get(`/${id}/rentals/current`)
  );

  useEffect(() => {
    if (rentalData) {
      setLeaseEndData(rentalData.data.rentalEndDate)
    }
  }, [rentalData])
  

  const specs = [
    { key: 'listedOn', name: 'Listed on', value: listedOn + ' days ago' },
    { key: 'propertyType', name: 'Type', value: firstLetterUppercase(propertyTypeLabel)},
    { key: 'size', name: 'Size', value: size + ' sqft' },
    { key: 'yearBuilt', name: 'Year Built', value: yearBuilt },
    { key: 'numberOfBedrooms', name: 'Bed', value: NumberOfRooms(Number(numberOfBedrooms)) },
    { key: 'numberOfBathrooms', name: 'Bath', value: NumberOfRooms(Number(numberOfBathrooms)) },
    { key: 'city', name: 'City', value: city },
    { key: 'leaseEndDate', name: 'Lease End', value: leaseEndData ? moment(leaseEndData).format('yyyy-MM-DD') : 'N/A' },
  ];

  return (
    <>
      <Typography sx={{ color: 'black', my: 4 }} variant="subtitle2">
        Property Specs
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: 3 } }}>
          {specs.slice(0, 4).map((spec) => (
            <Box key={spec.key} sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ color: 'gray', fontWeight: 500, opacity: 0.7, textTransform: 'uppercase' }}
              >
                {spec.name}
              </Typography>
              <Divider sx={{ flexGrow: 1, mx: 1, mb: 1 }} />
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {spec.value}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ pl: { xs: 0, sm: 3 } }}>
          {specs.slice(4).map((spec) => (
            <Box key={spec.key} sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ color: 'gray', fontWeight: 500, opacity: 0.7, textTransform: 'uppercase' }}
              >
                {spec.name}
              </Typography>
              <Divider sx={{ flexGrow: 1, mx: 1, mb: 1 }} />
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {spec.value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default Specs;
