import {Button, Card, Chip, Container, CardMedia, Typography, Divider, Grid, Box} from '@mui/material';
import {IMarketplaceItem, PropertyTypes} from '@brix/shared-frontend';
import {useState} from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LayersIcon from '@mui/icons-material/Layers';
import {formatPrice} from '@brix/shared-frontend';


const MarketplaceItemPlaceholder = ({type, name, address, grossValue, lotSize, __poster__, publicationDate}:any) => {


  const published = new Date(publicationDate).getTime()

  const today = new Date().getTime()

  const days = Math.floor((today - published) / (1000 * 60 * 60 * 24));

    return (

    <Card>
            <Box sx={{
                position: 'relative',
                height: '260px',
            }}>
            <Box sx={{
                filter: 'blur(4px)',
                zIndex: '1',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
            }}>
                <CardMedia component="img" height="250" width='100%' image={__poster__} />
            </Box>
            <Box sx={{
                 position: 'absolute',
                 top: '0',
                 left: '0',
                 width: '100%',
                 height: '100%',
                 display: 'flex',
                 textAlign: 'center',
                 alignItems: 'center',
                 justifyContent: 'center',
                 fontFamily: 'DMSans_Regular',
                 color: '#fff',
                 fontSize: '25px',
                 textShadow: '2px 2px #545454',
                 zIndex: '2',
            }}>Coming Soon</Box>
        </Box>
      <Container sx={{mt: 2}}>
      <Chip
          sx={{
            color:
             Number(type) === 1
                ? '#508cb8'
                : Number(type) === 2
                ? '#9a63a3'
                : Number(type) === 3
                ? '#cc4645'
                : '#39b064',
            backgroundColor:
            Number(type) === 1
                ? '#eef4f8'
                : Number(type) === 2
                ? '#f5eff6'
                : Number(type) === 3
                ? '#ffeeee'
                : '#e2f4f0',
            borderWidth: 0,
            py: 0,
          }}
          size="small"
          label={
            PropertyTypes[Number(type)].charAt(0).toUpperCase() + PropertyTypes[Number(type)].slice(1)
          }
          variant="outlined"
        ></Chip>
        <Typography color="black" variant="subtitle1" sx={{mt: '6px', fontWeight: 700}}>
          {name}
        </Typography>
        <Typography color="gray" variant="body1">
          {address}
        </Typography>
        <Typography color="gray" variant="body1">
        Published {days} days ago
        </Typography>
        <Divider sx={{mb: 1, mt: 1}} />
      </Container>
      <Container sx={{mb: 2}}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
              <Button fullWidth sx={{mt: 1}} color="primary" size="small" variant="contained">
                View
              </Button>
          </Grid>{' '}
          <Grid item xs={6}>
            <Button
              fullWidth
              sx={{mt: 1}}
              color="primary"
              size="small"
              variant="contained"
            >
              Buy
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>

    )
}

export default MarketplaceItemPlaceholder;
