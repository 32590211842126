import {Box, Container, Divider, Grid, Link, Typography} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {useContext, useState} from 'react';
import RegisterContext from '@panel/contexts/RegisterContext';
import InputCode from '@panel/components/InputCode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  sendPhoneVerification,
  sendEmailVerification,
  checkPhoneVerification,
  checkEmailVerification,
} from '../../utils';

const PersonalInformationVerification = () => {
  const [loading, setLoading] = useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [phoneVerificationSent, setPhoneVerificationSent] = useState(false);
  const registerContext = useContext(RegisterContext);
  const userToken = registerContext?.state.userToken;

  const sendSMS = async () => {
    const response = await sendPhoneVerification(registerContext?.state.id, userToken);
    setPhoneVerificationSent(response);
  };

  const sendEmail = async () => {
    const response = await sendEmailVerification(registerContext?.state.email, registerContext?.state.userToken);
    setEmailVerificationSent(response);
  };

  const verifyPhone = async (code: string) => {
    const response = await checkPhoneVerification(code, registerContext?.state.id, userToken);
    if (response) {
      registerContext?.dispatch({type: 'SET_DATA', payload: {isPhoneVerified: true}});
    }
    setLoading(false);
  };

  const verifyEmail = async (code: string) => {
    const response = await checkEmailVerification(code, registerContext?.state.email, userToken);
    if (response) {
      registerContext?.dispatch({type: 'SET_DATA', payload: {isEmailVerified: true}});
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm" sx={{pt: {xs: 2, md: 0, lg: 0}}}>
      <Typography variant="h3" align="center">
        Personal Information
      </Typography>
      <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
        Please verify your phone number and email address (2/2)
      </Typography>
      <Box sx={{mt: 4}}>
        <Typography variant="body2">EMAIL ADDRESS</Typography>
        <Grid container spacing={2} sx={{pt: 3}}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{registerContext?.state?.email}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {!registerContext?.state.isEmailVerified && (
              <Link onClick={() => sendEmail()} underline="none" sx={{cursor: 'pointer'}}>
                <Typography
                  color="primary"
                  variant="body2"
                  sx={{fontWeight: 500, display: 'flex', justifyContent: 'right'}}
                >
                  {emailVerificationSent ? 'Resend' : 'Send'} a code &nbsp; <ArrowCircleRightIcon color="primary" />
                </Typography>
              </Link>
            )}
            {registerContext?.state.isEmailVerified && (
              <Typography
                color="success.main"
                variant="body2"
                sx={{fontWeight: 500, display: 'flex', justifyContent: 'right'}}
              >
                Email confirmed &nbsp; <CheckCircleIcon color="success" />
              </Typography>
            )}
          </Grid>
        </Grid>
        <Divider sx={{my: 1}} />
        {!registerContext?.state.isEmailVerified && emailVerificationSent && (
          <>
            <Typography sx={{mb: 1, fontWeight: '600'}} color="primary" variant="body2">
              Code has been sent!
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <InputCode
                length={6}
                loading={loading}
                onComplete={(code) => {
                  setLoading(false);
                  verifyEmail(code);
                }}
              />
            </Box>
          </>
        )}
      </Box>
      <Typography variant="body2">PHONE NUMBER</Typography>
      <Grid container sx={{pt: 3}}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">{registerContext?.state?.phone}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {!registerContext?.state.isPhoneVerified && (
            <Link onClick={() => sendSMS()} underline="none" sx={{cursor: 'pointer'}}>
              <Typography
                color="primary"
                variant="body2"
                sx={{fontWeight: 500, display: 'flex', justifyContent: 'right'}}
              >
                {phoneVerificationSent ? 'Resend' : 'Send'} a code &nbsp; <ArrowCircleRightIcon color="primary" />
              </Typography>
            </Link>
          )}
          {registerContext?.state.isPhoneVerified && (
            <Typography
              color="success.main"
              variant="body2"
              sx={{fontWeight: 500, display: 'flex', justifyContent: 'right'}}
            >
              Phone number confirmed &nbsp; <CheckCircleIcon color="success" />
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider sx={{my: 1}} />
      {!registerContext?.state.isPhoneVerified && phoneVerificationSent && (
        <>
          <Typography sx={{mb: 1, fontWeight: '600'}} color="primary" variant="body2">
            Code has been sent!
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <InputCode
              length={6}
              loading={loading}
              onComplete={(code) => {
                setLoading(false);
                verifyPhone(code);
              }}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default PersonalInformationVerification;
