import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {IPaymentMethod} from '@brix/shared-frontend';
import PaymentItem from './PaymentItem';
import AddNewCardForm from '@panel/components/AddNewCardForm';
import CloseIcon from '@mui/icons-material/Close';
import {toast} from 'react-toastify';
import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import useAuth from '@panel/hooks/useAuth';
import useApi from '@panel/utils/apis';
import {api} from '@panel/utils';
import {useQuery} from 'react-query';
import {environment} from '@panel/environments/environment';
import useStripeAch from '@panel/hooks/stripe/useStripeAch';
import RegisteredPayoutAccounts from '@panel/views/profile/PaymentMethods/RegisteredPayoutAccounts';

const stripePromise = loadStripe(environment.stripeKey);

const PaymentMethods: React.FC = () => {
  const {user} = useAuth();
  const {addNewAchAccount} = useStripeAch();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<IPaymentMethod | null>();
  const {paymentMethodApi} = useApi();

  const {data: paymentMethodsData, refetch} = useQuery(['payment-method', user?.id], () =>
    paymentMethodApi.get('/user/' + user?.id)
  );

  const setDefaultPaymentMethod = async (paymentMethod: IPaymentMethod) => {
    await paymentMethodApi.post(
      `/set-default`,
      {},
      {
        paymentMethodId: paymentMethod.id,
      }
    );
    await refetch();
    setIsStripeLoading(false);
  };

  const deletePaymentMethod = (paymentMethod: IPaymentMethod) => {
    setPaymentMethodToDelete(paymentMethod);
    setIsStripeLoading(false);
    setOpenConfirmationModal(true);
  };

  const handleAddNewCardClicked = () => {
    if (addNewCard) return;
    setIsStripeLoading(true);
    setAddNewCard(true);
  };

  const handleCloseAddNewCardModal = () => {
    setAddNewCard(false);
  };

  const handleNewCardFormReady = () => {
    setIsStripeLoading(false);
  };

  const handleRemovePaymentMethodConfirmation = async () => {
    try {
      await api['payment-method'].delete(`/${paymentMethodToDelete?.id}`);
      setPaymentMethods(
        paymentMethods?.filter((paymentMethod: IPaymentMethod) => paymentMethod.id !== paymentMethodToDelete?.id)
      );
      toast.success('Payment method deleted', {
        position: 'top-left',
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: 3000,
      });
      setOpenConfirmationModal(false);
      await refetch()
    } catch (e: any) {
      toast.error('Could not delete payment method', {
        position: 'top-left',
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: 3000,
      });
      setOpenConfirmationModal(false);
      await refetch()
    }
    return;
  };

  const handleCreditCardAdded = async () => {
    handleCloseAddNewCardModal();
    toast.success('Credit card added successfully', {
      position: 'top-left',
      pauseOnHover: false,
      closeOnClick: true,
      autoClose: 3000,
    });
    await refetch();
  };

  useEffect(() => {
    setPaymentMethods(paymentMethodsData?.data ?? []);
  }, [paymentMethodsData]);

  return (
    <Grid container sx={{p: 0, m: 0}}>
      <Grid item xs={12}>
        <Card sx={{px: 3, py: 4, height: '100%'}}>
          <Typography variant="h6">Payment Methods</Typography>
          <Typography color="gray" variant="body1" sx={{mt: 1}}>
            Please add a default payment method.
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Typography color="gray" mt={3} variant="subtitle2">
                    Credit / Debit Cards
                  </Typography>
                  <Grid container spacing={1} sx={{pt: 3}}>
                    <Grid item xs={12}>
                      <Divider sx={{mt: 1}} />
                    </Grid>
                    {paymentMethods?.map((paymentMethod: IPaymentMethod) => {
                      return (
                        paymentMethod.type === 'card' && (
                          <Grid key={paymentMethod.id} item xs={12}>
                            <PaymentItem
                              paymentMethod={paymentMethod}
                              setDefaultPaymentMethod={setDefaultPaymentMethod}
                              deletePaymentMethod={deletePaymentMethod}
                            />
                            <Divider sx={{mt: 1}} />
                          </Grid>
                        )
                      );
                    })}
                    <Grid item xs={12}>
                      <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
                        <Card
                          sx={{
                            borderStyle: 'solid',
                            borderColor: 'rgba(0,0,0,.15)',
                            borderWidth: 2,
                            px: 3,
                            py: 1,
                            boxShadow: 'none',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            transition: 'all .2s ease-in-out',
                            cursor: 'pointer',
                            '&:hover': {borderColor: 'rgba(0,0,0,1)'},
                          }}
                          onClick={handleAddNewCardClicked}
                        >
                          <Typography sx={{fontWeight: 600, textAlign: 'center'}}>
                            {isStripeLoading ? (
                              <CircularProgress sx={{mt: '4px'}} color="primary" size="12px" />
                            ) : (
                              '+ Add New Card'
                            )}
                          </Typography>
                        </Card>
                      </Box>
                    </Grid>
                    {addNewCard && (
                      <Dialog disableScrollLock onClose={handleCloseAddNewCardModal} open={addNewCard}>
                        <Box
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                          }}
                        >
                          <IconButton onClick={handleCloseAddNewCardModal}>
                            <CloseIcon />{' '}
                          </IconButton>
                        </Box>
                        {isStripeLoading && (
                          <Box sx={{display: 'flex', justifyContent: 'center', pt: 10, pb: 2}}>
                            <CircularProgress />{' '}
                          </Box>
                        )}
                        <Elements stripe={stripePromise}>
                          <AddNewCardForm onSucceed={handleCreditCardAdded}
                                          onReady={handleNewCardFormReady} />
                        </Elements>
                      </Dialog>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="gray" variant="subtitle2">
                    ACH
                  </Typography>
                  <Grid container spacing={1} sx={{pt: 2}}>
                    <Grid item xs={12}>
                      <Divider sx={{mt: 1}} />
                    </Grid>
                    {paymentMethods?.filter(p => p.status !== 'canceled')?.map((paymentMethod: IPaymentMethod) => {
                      return (
                        paymentMethod.type === 'us_bank_account' && (
                          <Grid key={paymentMethod.id} item xs={12}>
                            <PaymentItem
                              paymentMethod={paymentMethod}
                              setDefaultPaymentMethod={setDefaultPaymentMethod}
                              deletePaymentMethod={deletePaymentMethod}
                            />
                            <Divider sx={{mt: 1}} />
                          </Grid>
                        )
                      );
                    })}
                    <Grid item xs={12}>
                      <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
                        {' '}
                        <Card
                          sx={{
                            borderStyle: 'solid',
                            borderColor: 'rgba(0,0,0,.15)',
                            borderWidth: 2,
                            px: 3,
                            py: 1,
                            boxShadow: 'none',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            transition: 'all .2s ease-in-out',
                            cursor: 'pointer',
                            '&:hover': {borderColor: 'rgba(0,0,0,1)'},
                          }}
                          onClick={addNewAchAccount}
                        >
                          <Typography sx={{fontWeight: 600, textAlign: 'center'}}>+ Add New ACH Account</Typography>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography color="gray" mt={3} variant="subtitle2">
                Payout Bank Accounts
              </Typography>
              <RegisteredPayoutAccounts/>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Dialog disableScrollLock open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 1,
          }}
        >
          <DialogTitle sx={{textAlign: 'center'}} color="black">
            {'Are you sure you want to remove this payment method from your account?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleRemovePaymentMethodConfirmation}>Yes</Button>
            <Button onClick={() => setOpenConfirmationModal(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default PaymentMethods;
