/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Button, Card, Chip, Grid, IconButton, MenuItem, Select, Tooltip, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import {useQuery} from 'react-query';
import {DocumentTypes} from '@brix/shared-frontend';
import useAuth from '@panel/hooks/useAuth';
import api from '@panel/utils/api';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import {FormErrorMessage} from '@panel/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';

const DocumentUpload: React.FunctionComponent<{
  isDocumentVerified: boolean;
  setIsDocumentVerified: Dispatch<SetStateAction<boolean>>;
}> = ({isDocumentVerified, setIsDocumentVerified}) => {
  const {user} = useAuth();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [idTypeSelected, setIdTypeSelected] = useState('sel');
  const [uploadError, setUploadError] = useState(false);
  const [documentVerificationText, setDocumentVerificationText] = useState('');
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);

  const {data: documentsData, refetch} = useQuery(['users', user?.id, 'document'], () =>
    api['users'].get(`/${user?.id}/documents?filter=type||$eq||${DocumentTypes.identity}&limit=3`)
  );

  const uploadFiles = async (files: File[]) => {
    setIsSaveButtonLoading(true);
    try {
      files.forEach(async (file: File) => {
        const formData = new FormData();
        const fileToUpload = file;
        formData.append('file', fileToUpload, fileToUpload.name);
        await api['upload'].post(`/`, formData).then(async (res: any) => {
          await api['users'].post(`/${user?.id}/documents`, {
            key: res.data.Key,
            metadata: fileToUpload.name,
            type: DocumentTypes.identity,
            location: res.data.Location,
          });
        });
        setDocumentVerificationText('Document under review');
        await refetch();
        setNewFiles([]);
        setIsSaveButtonLoading(false);
      });
      toast.success('Documents saved', {
        position: 'top-left',
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.error('Could not upload documents', {
        position: 'top-left',
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: 3000,
      });
      setUploadError(true);
      setIsSaveButtonLoading(false);
      return;
    }
  };

  const handleOnClickSave = async () => {
    await uploadFiles(newFiles);
    setIsSaved(true);
  };

  const handleDeleteUploadedDocument = async (file: any) => {
    setIsSaveButtonLoading(true);
    if (newFiles.find((f: any) => f.key === file.key)) {
      setSelectedFiles(selectedFiles.filter((f: any) => f.key !== file.key));
      setIsSaveButtonLoading(false);
      return;
    } else {
      try {
        await api['users'].delete(`/${user?.id}/documents/${file.id}`);
        toast.success('Document deleted', {
          position: 'top-left',
          pauseOnHover: false,
          closeOnClick: true,
          autoClose: 3000,
        });
        await refetch();
        setIsSaveButtonLoading(false);
        setSelectedFiles(selectedFiles.filter((f: any) => f.key !== file.key));
        setNewFiles(newFiles.filter((f: any) => f.key !== file.key));
      } catch (e: any) {
        toast.error('Could not delete document', {
          position: 'top-left',
          pauseOnHover: false,
          closeOnClick: true,
          autoClose: 3000,
        });
        setIsSaveButtonLoading(false);
        return;
      }
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedFiles && selectedFiles.length >= 3) {
      event.preventDefault();
      toast.error('Cannot upload more than 3 files', {
        position: 'top-left',
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: 3000,
      });
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      if (event?.target?.files?.length > 3) {
        event.preventDefault();
        toast.error('Cannot upload more than 3 files', {
          position: 'top-left',
          pauseOnHover: false,
          closeOnClick: true,
          autoClose: 3000,
        });
        return;
      }
      // eslint-disable-next-line no-unsafe-optional-chaining
      const newFile = event.target.files[0];
      setSelectedFiles((existing: any) => [...existing, newFile]);
      setNewFiles((existing: any) => [...existing, newFile]);
      setIsFileSelected(true);
    }
  };

  const handleChangeIdType = (event: {target: {value: SetStateAction<string>}}) => {
    setIdTypeSelected(event.target.value);
  };

  useEffect(() => {
    if (selectedFiles.length === 0) !isDocumentVerified && setDocumentVerificationText('');
  }, [selectedFiles]);

  useEffect(() => {
    if (newFiles.length >= 0) setIsSaved(false);
  }, [newFiles]);

  useEffect(() => {
    if (documentsData && documentsData.data?.data?.length > 0) {
      setSelectedFiles(documentsData.data?.data);
      setIsFileSelected(true);
      !isDocumentVerified && setDocumentVerificationText('Document under review');
    }
  }, [documentsData]);

  return (
    <Card sx={{px: 3, pt: 4, pb: 2}}>
      <Typography variant="h6">Document Upload</Typography>
      <Grid container spacing={2} sx={{py: 2}}>
        <Grid item xs={12} md={2}>
          <Typography color="black" variant="caption">
            ID TYPE
          </Typography>
          <Tooltip
            arrow
            title={`If you select Driver's License or Identity Card, please upload an image of both the front AND the back of the document.`}
          >
            <InfoIcon color={'secondary'} fontSize="small" sx={{cursor: 'pointer', ml: 1}} />
          </Tooltip>
          <Select
            MenuProps={{disableScrollLock: true}}
            id="idType"
            defaultValue={'sel'}
            fullWidth
            name="idType"
            value={idTypeSelected}
            onChange={handleChangeIdType}
          >
            <MenuItem value="sel">Select an option</MenuItem>
            <MenuItem value="identificationCard">Identifiation Card</MenuItem>
            <MenuItem value="passport">Passport</MenuItem>
            <MenuItem value="driverLicense">Driver's License</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <br />
          <Button
            startIcon={<InsertPhotoOutlinedIcon />}
            disabled={(selectedFiles && selectedFiles.length >= 3) || isDocumentVerified || idTypeSelected === 'sel'}
            variant="contained"
            size="small"
            component="label"
            sx={{mt: 1}}
          >
            Select Files
            <input accept="image/*" type="file" hidden id="idDocument" onChange={changeHandler} />
          </Button>
          {uploadError && <FormErrorMessage message={'Error uploading document'} />}
        </Grid>
        <Grid item xs={12} md={6}>
          <Chip
            variant="outlined"
            size="small"
            sx={{borderWidth: '0px', fontSize: '11px'}}
            icon={
              isDocumentVerified ? <CheckCircleIcon /> : documentsData?.data?.data?.length > 0 ? <InfoIcon /> : <></>
            }
            color={isDocumentVerified ? 'success' : 'info'}
            label={
              isDocumentVerified
                ? 'Document verified'
                : documentsData?.data?.data?.length > 0
                ? 'Document under review'
                : ' '
            }
          ></Chip>
          {isFileSelected ? (
            selectedFiles.map((file: any, index: number) => (
              <Grid key={index} container>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontSize: '11px',
                      mt: '3px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {file.name || file.metadata}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {!isDocumentVerified && (
                    <IconButton onClick={() => handleDeleteUploadedDocument(file)} size="small">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography sx={{fontSize: '11px', mt: 1}}>Please upload an image of your ID.</Typography>
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 4,
            }}
          >
            <LoadingButton
              loading={isSaveButtonLoading}
              onClick={handleOnClickSave}
              disabled={
                idTypeSelected === 'sel' ||
                !isFileSelected ||
                isDocumentVerified ||
                selectedFiles.length === 0 ||
                isSaved
              }
              size="medium"
              variant="contained"
            >
              Save Documents
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DocumentUpload;
