import {Grid, Tooltip, Typography} from '@mui/material';
import {brixFeeLabel, calcBrixFeeAmount, formatWithDigits} from '@brix/shared-frontend';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import {FeesType} from '@panel/contexts/FeesContext';

type BrixUnit = 'BRiXie' | 'BRiX' | 'Stax';

export interface ReviewBrixParams {
  fee?: Partial<FeesType>;
  unit: BrixUnit;
  amount: number;
  price: number;

}

const pctLabel = (amount: string, unit: BrixUnit) => `${amount} fee of total transaction to buy ${unit}`;
const totalLabel = (amount: string, unit: BrixUnit) => `${amount} fee amount to buy ${unit} transaction`;

export const ReviewBrixItem = ({fee, unit, amount, price}: ReviewBrixParams) => {
  const feeLabel = brixFeeLabel(fee);
  const label = fee?.isPercentage ? pctLabel(feeLabel, unit) : totalLabel(feeLabel, unit)
  return (
    <>
      <Grid item xs={10}>
        <Typography sx={{display: 'inline'}} color="textSecondary" variant="body1">
          {unit} Buying Fee
        </Typography>
        <Tooltip arrow title={label}>
          <InfoIcon color={'secondary'} fontSize="small"
                    sx={{cursor: 'pointer', ml: 1, mb: '-6px'}}/>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Typography sx={{float: 'right'}} color="textSecondary" variant="body1">
          {formatWithDigits(calcBrixFeeAmount(amount * price, fee))}
        </Typography>
      </Grid>
    </>
  );
}
