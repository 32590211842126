import api from '@panel/utils/api';

const useApi = () => {
  const paymentMethodApi = api['payment-method'];
  return {
    paymentMethodApi
  }
};

export default useApi
