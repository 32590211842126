import {Box, Grid, Typography} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import Page from '@panel/components/Page';
import ReportingTable from '@panel/views/reporting/ReportingTable'

const ReportingView: React.FunctionComponent<IPage> = (props) => {
  const { name } = props
  
  return (
    <Page title={name ?? ''}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '20px 20px',
          pt: 8,
          px: 4,
          margin: 4,
          backgroundColor: 'background.paper',
          height: '100%',
        }}
      >
            <Typography variant="h4">Reporting</Typography>
            <ReportingTable />
      </Box>
    </Page>
  );
};

export default ReportingView;
