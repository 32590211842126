import {Box, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {IPage} from '@brix/shared-frontend';
import Page from '@panel/components/Page';
import BankAccountCard from '@panel/components/BankAccountCard';
import useAuth from '@panel/hooks/useAuth';
import CurrentWalletBalance from './CurrentWalletBalance';
import api from '@panel/utils/api';
import {useQuery} from 'react-query';
import TransactionHistory from '@panel/views/wallet/TransactionHistory'

const loadWallet = async (userId: string | undefined) => {
  const res = await api['users'].get(`/${userId}/wallet/balance`);
  return res.data;
};

const loadInsights = async (userId: string | undefined) => {
  const res = await api['users'].get(`/${userId}/wallet/insights`);
  return res.data;
};

const WalletView: React.FunctionComponent<IPage> = (props) => {

  const {user} = useAuth();
  const [balance, setBalance] = useState(9999)
  const [insights, setInsights] = useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {name} = props


  const {data: walletData} = useQuery(['users', user?.id, 'wallet'], () =>
    loadWallet(user?.id));

  const {data: insightsData} = useQuery(['users', user?.id, 'wallet', 'insights'], () =>
    loadInsights(user?.id));

  const handlePageChange = (event: React.MouseEvent | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRowsPerPage(parseInt(event?.target?.value as string, 10));
  };

  useEffect(() => {
    setBalance(walletData?.available)
  }, [walletData])

  useEffect(() => {
    setInsights(insightsData)
  }, [insightsData])

  return (
    <Page title={name ?? ''}>
      <Box
        sx={{
          flexGrow: 1,
          pt: 4,
          px: {xs: 2, sm: 4},
          backgroundColor: 'background.default',
          height: '100%',
        }}
      >
        <Grid container spacing={6} justifyContent='space-between' alignItems="center">
          <Grid item md={12} lg={8.5}>
            <CurrentWalletBalance
              balance={balance}
              insights={insights}
            />
          </Grid>
          <Grid item md={6} lg={3.5}>
            <BankAccountCard/>
          </Grid>
          <Grid item xs={12}>
            <TransactionHistory
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default WalletView;
