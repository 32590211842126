import { createContext, useContext } from 'react';
import {IFilters} from '@panel/interfaces/filters-marketplace';

export type FilterContextType = {
    filtersSelected: IFilters;
    setFiltersSelected: (Filters: IFilters) => void;
}

export const FilterContext = createContext<FilterContextType>({ filtersSelected: {range: [], types: []}, setFiltersSelected: FilterContextType => {} });
export const useFilter = () => useContext(FilterContext);
