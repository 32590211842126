import {BrixTypes} from "@brix/shared-frontend";

export const brixieToUnit = (amount: number, type: BrixTypes) => {
  switch (type) {
    case BrixTypes.Brix:
      return amount / 10;
    case BrixTypes.HalfBrix:
      return amount / 5;
    case BrixTypes.Stax:
      return amount / 100;
    default:
      return amount;
  }
}
