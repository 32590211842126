import 'simplebar/dist/simplebar.min.css';
import React, {useEffect, createRef, forwardRef} from 'react';
import SimpleBar from 'simplebar-react';
import {styled} from '@mui/material/styles';

const ScrollbarRoot = styled(SimpleBar)``;

export const Scrollbar = forwardRef(
  (props: {children: React.ReactNode; sx?: Record<string, unknown>}, ref: React.Ref<SimpleBar>) => {
    const scrollableNodeRef: {current: Window | null} = createRef();
    useEffect(() => {
      scrollableNodeRef?.current?.scrollTo({
        top: 0,
        behavior: 'auto',
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.children]);
    return <ScrollbarRoot scrollableNodeProps={{ref: scrollableNodeRef}} ref={ref} {...props} />;
  }
);

export default Scrollbar;
