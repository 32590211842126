import React, {useEffect, useState, useContext} from 'react';

export const UsersnapContext = React.createContext(null);

export const UsersnapProvider = ({
  initParams = {},
  children,
}: {
  initParams?: Record<string, unknown>;
  children: React.ReactElement;
}) => {
  const [usersnapApi, setUsersnapApi] = useState(null);

  useEffect(() => {
    let usersnapApi: any = null;
    (window as any).onUsersnapCXLoad = function (api: any) {
      api.init(initParams);
      usersnapApi = api;
      setUsersnapApi(api);
    };
    const script: any = document.createElement('script');
    script.defer = 1;
    script.src = `https://widget.usersnap.com/global/load/${
      process.env['NX_USERSNAP_GLOBAL_API_KEY'] as string
    }?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy();
      }
      script.remove();
    };
  }, []);

  return <UsersnapContext.Provider value={usersnapApi}>{children}</UsersnapContext.Provider>;
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
