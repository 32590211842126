import {useContext} from 'react';
import {MenuItem} from '@mui/material';
import {OrderContext} from '@panel/components/SelectOrderContext';
import Select, {SelectChangeEvent} from '@mui/material/Select';

const SelectOrderMenu = () => {
  const {orderSelected, setOrderSelected} = useContext(OrderContext);

  const handleChange = (event: SelectChangeEvent) => {
    setOrderSelected(event.target.value as string);
  };

  return (
    <Select
      value={orderSelected}
      onChange={handleChange}
      sx={{minWidth: '175px', backgroundColor: 'white', mb: 4, bottom: 5, mt: 2}}
      defaultValue="recent"
    >
      <MenuItem sx={{color: 'black'}} value="recent">
        Recently Added
      </MenuItem>
      <MenuItem sx={{color: 'black'}} value="popular">
        Most Popular
      </MenuItem>
      <MenuItem sx={{color: 'black'}} value="lowestPrice">
        Lowest Price
      </MenuItem>
      <MenuItem sx={{color: 'black'}} value="highestPrice">
        Highest Price
      </MenuItem>
      <MenuItem sx={{color: 'black'}} value="largest">
        Largest
      </MenuItem>
      <MenuItem sx={{color: 'black'}} value="smallest">
        Smallest
      </MenuItem>
    </Select>
  );
};

export default SelectOrderMenu;
