import {IPage} from '@brix/shared-frontend';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Card, Container, Divider, Link, Typography} from '@mui/material';
import ForgotPasswordForm from './ForgotPasswordForm';
import Page from '@panel/components/Page';

const ForgotPasswordView: React.FC<IPage> = () => {
  return (
    <Page title="Forgot password">
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: 2,
              md: 0,
              lg: 0,
            },
          }}
        >
          <Card elevation={16} sx={{p: 4, border: 'none', boxShadow: 'none'}}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <img width="160" height="80" alt="Logo" src={'../assets/images/brick.png'} />
              <Typography variant="h4">
                Enter your <span style={{color: '#CC4645'}}>Email address</span>
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                  width: '100%',
                }}
              >
                <ForgotPasswordForm />
              </Box>
            </Box>
            <Divider sx={{my: 3}} />
            <Typography align="center" color="textSecondary" sx={{mt: 2}} variant="body2">
              Already have a password? &nbsp;
              <Link component={RouterLink} to="/login" color="primary" sx={{mt: 1, fontWeight: 500}} variant="body2">
                Sign in
              </Link>
            </Typography>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPasswordView;
