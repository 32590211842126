import {IPaymentMethod} from '@brix/shared-frontend';
import {Image} from 'mui-image';
import {Box, Card, Grid, Typography} from '@mui/material';

const PaymentMethodItem: React.FC<{
  paymentMethod: IPaymentMethod;
  handleClickOnPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  selected?: boolean;
}> = ({paymentMethod, handleClickOnPaymentMethod, selected}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
      <Card
        sx={{
          borderStyle: 'solid',
          borderColor: selected ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.15)',
          borderWidth: 2,
          px: 3,
          py: 2,
          boxShadow: 'none',
          width: '100%',
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          transition: 'all .2s ease-in-out',
          cursor: 'pointer',
          '&:hover': {borderColor: 'primary.main'},
        }}
        onClick={() => handleClickOnPaymentMethod(paymentMethod)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} md={2}>
            <Image
              src={
                paymentMethod.type === 'us_bank_account'
                  ? '../assets/images/bank.png'
                  : paymentMethod.brand === 'visa'
                  ? '../assets/images/visa.png'
                  : '../assets/images/mastercard.png'
              }
              alt={'BRiX'}
              duration={50}
              easing="ease-in-out"
              width={'100%'}
              height={'24px'}
              fit="contain"
            />
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography variant="subtitle1" sx={{fontWeight: 400}}>
              {paymentMethod.type === 'us_bank_account'
                ? 'ACH: **** **** **** '
                : paymentMethod.brand === 'visa'
                ? 'Visa: **** **** **** '
                : 'Mastercard: **** **** **** '}
              <span style={{fontWeight: 600}}>{paymentMethod.lastFourDigits}</span>
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            {paymentMethod.isDefault && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0,0,0,.2)',
                  width: '100%',
                }}
              >
                <Typography variant={'body2'} sx={{fontSize: {xs: '11px', sm: '0.85rem'}}}>
                  Default
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default PaymentMethodItem;
