import {IRoute} from '@brix/shared-frontend';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import {DashboardLayout} from './layouts/DashboardLayout/Layout';
import AuthLayout from './layouts/AuthLayout/Layout';
import {Fragment} from 'react';
import DashboardView from './views/dashboard';
import CartView from './views/cart';
import ProfileView from './views/profile';
import MarketplaceView from './views/marketplace';
import CheckoutView from './views/marketplace/CheckoutView';
import PortfolioView from './views/portfolio';
import ReportingView from './views/reporting';
import WalletView from './views/wallet';
import PropertyDetailsView from './views/marketplace/PropertyDetailsView';
import {RouteComponentProps, Route, Switch} from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import AuthGuard from './components/AuthGuard';
import BalanceTransferView from '@panel/views/wallet/BalanceTransfer/BalanceTransferView';
import TransferToWalletView from '@panel/views/wallet/BalanceTransferFromAccount/TransferToWalletView';
import ValidateMFAView from '@panel/views/mfa';

export const renderRoutes = (routes: IRoute[] = []) => {
  return (
    <Switch>
      {routes.map((route, index) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={(props: RouteComponentProps<any>) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <route.component name={route.name} {...props} {...route.props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  );
};

const routes: IRoute[] = [
  {
    path: '/login',
    name: 'Login',
    guard: GuestGuard,
    layout: AuthLayout,
    exact: true,
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    guard: GuestGuard,
    layout: AuthLayout,
    exact: true,
    component: RegisterView,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    layout: AuthLayout,
    exact: true,
    component: ForgotPasswordView,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    layout: AuthLayout,
    exact: true,
    component: ResetPasswordView,
  },
  {
    path: '/validate',
    name: 'ValidateMFA',
    layout: AuthLayout,
    exact: true,
    component: ValidateMFAView,
  },
  {
    path: '/',
    name: 'Dashboard',
    guard: AuthGuard,
    component: DashboardView,
    layout: DashboardLayout,
    routes: [
      {
        path: '/',
        name: 'Dashboard',
        exact: true,
        component: DashboardView,
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        exact: true,
        component: DashboardView,
      },
      {
        path: '/portfolio',
        name: 'Portfolio',
        exact: true,
        component: PortfolioView,
      },
      {
        path: '/reporting',
        name: 'Reporting',
        exact: true,
        component: ReportingView,
      },
      {
        path: '/financials',
        name: 'Financials',
        exact: true,
        component: WalletView,
      },
      {
        path: '/marketplace',
        name: 'Marketplace',
        exact: true,
        component: MarketplaceView,
      },
      {
        path: '/marketplace/:id',
        name: 'Property Details',
        exact: true,
        component: PropertyDetailsView,
      },
      {
        path: '/my-cart',
        name: 'My Cart',
        exact: true,
        component: CartView,
      },
      {
        path: '/checkout',
        name: 'Checkout',
        exact: true,
        component: CheckoutView,
      },
      {
        path: '/transfer/from-wallet',
        name: 'Transfer From Wallet',
        exact: true,
        component: BalanceTransferView,
      },
      {
        path: '/transfer/from-account',
        name: 'Transfer From Wallet',
        exact: true,
        component: TransferToWalletView,
      },
      {
        path: '/profile',
        name: 'My Profile',
        exact: true,
        component: ProfileView,
      },
    ],
  },
];

export default routes;
