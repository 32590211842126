import {createContext, useReducer} from 'react';
import {RegisterWizardProps} from '@panel/interfaces';

type Action = {type: 'SET_DATA'; payload: RegisterWizardProps} | {type: 'UPDATE_DATA'; payload: RegisterWizardProps};
type Dispatch = (action: Action) => void;
type CountProviderProps = {children: React.ReactNode};

const RegisterContext = createContext<{state: RegisterWizardProps; dispatch: Dispatch} | undefined>(undefined);

function reducer(state: RegisterWizardProps, action: Action) {
  switch (action.type) {
    case 'SET_DATA': {
      return {...state, ...action.payload};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const RegisterProvider = ({children}: CountProviderProps) => {
  const [state, dispatch] = useReducer(reducer, {userName: '', isTermsAccepted: false, accreditedInvestor: false});
  const value = {state, dispatch};
  return <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>;
};

export const RegisterConsumer = RegisterContext.Consumer;
export default RegisterContext;
