/* eslint-disable react-hooks/exhaustive-deps */
import {Alert, Box, Button, Card, Dialog, Grid, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useCart from '@panel/hooks/cart/useCart';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import FormErrorMessage from './FormErrorMessage';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {formatPriceWithoutSymbol} from '@brix/shared-frontend';
import {useQuery} from 'react-query';
import api from '@panel/utils/api';
import {toast} from 'react-toastify';
import useAuth from '@panel/hooks/useAuth';

const BrixSelectionModal: React.FC<{
  open: boolean;
  selectedPropertyId: string | undefined;
  selectedProperty?: {
    propertyName: string | undefined;
    propertyId: string | undefined;
    propertyAddress: string | undefined;
    propertyImage: string | undefined;
  };
  selectedValue: string;
  onClose: (value: string) => void;
}> = ({open, selectedPropertyId, selectedValue, selectedProperty, onClose}) => {
  const {addProduct} = useCart();
  const [brixiePrice, setBrixiePrice] = useState(0);
  const [stax, setStax] = useState(0);
  const [brix, setBrix] = useState(0);
  const [brixie, setBrixie] = useState(0);
  const [brixAvailable, setBrixAvailable] = useState(0);
  const [priceError, setPriceError] = useState(false);
  const [investmentAmountError, setInvestmentAmountError] = useState(false);
  const [investmentAmountErrorMessage, setInvestmentAmountErrorMessage] = useState('');
  const [investmentAmount, setInvestmentAmount] = useState<number>(0);
  const [totalDisplay, setTotalDisplay] = useState<number>(0);
  const [brixieAvailable, setBrixieAvaiable] = useState(0)

  const {user} = useAuth();

  const {data: availableBrixiesData} = useQuery(['property', selectedPropertyId, 'brixies'], () =>
    api['properties'].get(`/${selectedPropertyId}/infographics`)
  );

  const {data: availableBrixieData} = useQuery(['brixies', selectedPropertyId, 'brixiePrice'], () =>
    api['brixies'].get(`/`, {filter: 'propertyId||$eq||' + selectedPropertyId})
  );

  const brixCategories = [
    {
      type: `stax`,
      value: `${stax}`,
      label: '1 Stax = $' + formatPriceWithoutSymbol(brixiePrice * 100),
      description: 'This would be a single person who is investing.',
      image: '../assets/images/brick-wizard3.png',
      disabled: false,
      checked: true,
    },
    {
      type: `brix`,
      value: `${brix}`,
      label: '1 BRiX = $' + formatPriceWithoutSymbol(brixiePrice * 10),
      description: 'Joint represents an investor who is more than one person.',
      image: '../assets/images/brick-wizard2.png',
      disabled: false,
      checked: false,
    },
    {
      type: `brixie`,
      value: `${brixie}`,
      label: '1 BRiXie = $' + formatPriceWithoutSymbol(brixiePrice),
      description: 'Entity represents a company or corporation that is represented.',
      image: '../assets/images/brick-wizard.png',
      disabled: false,
      checked: false,
    },
  ];

  const handleClose = () => {
    setInvestmentAmount(0);
    setTotalDisplay(0);
    setBrixie(0);
    setBrix(0);
    setStax(0);
    onClose(selectedValue);
  };

  const handleOnClickAddToCart = async () => {
    if (isNaN(brixiePrice)) {
      setPriceError(true);
      return;
    }
    await addProduct({
      ...availableBrixieData.data[0],
      propertyName: selectedProperty?.propertyName,
      propertyAddress: selectedProperty?.propertyAddress,
      propertyImage: selectedProperty?.propertyImage,
      quantity: brixie + brix * 10 + stax * 100,
    });
    setInvestmentAmount(0);
    setTotalDisplay(0);
    setBrixie(0);
    setBrix(0);
    setStax(0);
    toast.success('Items added to cart', {position: 'top-left', closeOnClick: true, autoClose: 3000});
    onClose(selectedValue);
  };

  const handleChangeInvestmentAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event?.target?.value);
    if (isNaN(value) || value <= 0) {
      setInvestmentAmount(0);
      return;
    } else if (value < 100) {
      setInvestmentAmountError(true);
      setInvestmentAmountErrorMessage('Minimum investment amount is $100');
    } else {
      setInvestmentAmountError(false);
    }
    setInvestmentAmount(value);
  };

  const handleOnBlurInvestmentAmount = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(event?.target?.value);
    if (isNaN(value) || value <= 0) return;
    value <= 99 ? setInvestmentAmount(100) : setInvestmentAmount(value);
    investmentAmount % 100 !== 0
      ? setInvestmentAmount(Math.trunc(investmentAmount / 100) * 100)
      : setInvestmentAmount(value);
  };

  const handleBrixChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, brixType: string) => {
    const value = parseInt(event?.target?.value);
    switch (brixType) {
      case 'brixie':
        isNaN(value) || value <= 0
          ? setBrixie(0)
          : stax >= Math.trunc(brixAvailable / 10) &&
          brix >= Math.trunc((brixAvailable / 10 - Math.trunc(brixAvailable / 10)) * 10)
            ? setBrixie(0)
            : value > 9
              ? setBrixie(9)
              : setBrixie(value);
        return;
      case 'brix':
        isNaN(value) || value <= 0
          ? setBrix(0)
          : stax >= Math.trunc(brixAvailable / 10)
            ? brixie > 0
              ? setBrix(Math.trunc((brixAvailable / 10 - Math.trunc(brixAvailable / 10)) * 10) - 1)
              : setBrix(Math.trunc((brixAvailable / 10 - Math.trunc(brixAvailable / 10)) * 10))
            : value > 9
              ? setBrix(9)
              : setBrix(value);
        return;
      case 'stax':
        if (isNaN(value) || value <= 0) {
          setStax(0);
        } else {
          if (value > brixAvailable / 10) {
            setStax(Math.trunc(brixAvailable / 10));
            setBrix(brixAvailable - Math.trunc(brixAvailable / 10) * 10);
            setBrixie(0);
          } else {
            setStax(value);
          }
        }
        return;
    }
  };

  useEffect(() => {
    if (investmentAmount >= brixAvailable * 10 * brixiePrice) {
      setInvestmentAmount(brixAvailable * 10 * brixiePrice);
      setBrixie(
        Math.trunc((investmentAmount / (brixiePrice * 10)) * 10 - Math.trunc(investmentAmount / (brixiePrice * 10)) * 10)
      );
      setBrix(
        Math.trunc(
          (investmentAmount / (brixiePrice * 100)) * 10 - Math.trunc(investmentAmount / (brixiePrice * 100)) * 10
        )
      );
      setStax(Math.trunc(investmentAmount / (brixiePrice * 100)));
    } else if (investmentAmount >= brixiePrice * 100) {
      setBrixie(Math.trunc((investmentAmount / (brixiePrice * 10)) * 10 - Math.trunc(investmentAmount / (brixiePrice * 10)) * 10));
      setBrix(
        Math.trunc(
          (investmentAmount / (brixiePrice * 100)) * 10 - Math.trunc(investmentAmount / (brixiePrice * 100)) * 10
        )
      );
      setStax(Math.trunc(investmentAmount / (brixiePrice * 100)));
    } else if (investmentAmount >= brixiePrice * 10) {
      setBrixie(
        Math.trunc(
          (investmentAmount / (brixiePrice * 10)) * 10 - Math.trunc(investmentAmount / (brixiePrice * 10)) * 10
        )
      );
      setBrix(Math.trunc(investmentAmount / (brixiePrice * 10)));
      setStax(0);
    } else {
      setBrixie(Math.trunc(investmentAmount / brixiePrice));
      setBrix(0);
      setStax(0);
    }
    setTotalDisplay(investmentAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentAmount]);

  useEffect(() => {
    if (isNaN(brixie)) setBrixie(0);
    if (isNaN(brix)) setBrix(0);
    if (isNaN(stax)) setStax(0);
    isNaN(brixiePrice) || brixiePrice === 0
      ? setTotalDisplay(0)
      : setTotalDisplay(brixiePrice * (brixie + brix * 10 + stax * 100));
  }, [brixie, brix, stax]);

  useEffect(() => {
    setBrixAvailable(availableBrixiesData?.data?.available / 10);
  }, [availableBrixiesData]);

  useEffect(() => {
    setBrixieAvaiable(availableBrixiesData?.data.available);
  }, [availableBrixiesData])

  useEffect(() => {
    setBrixiePrice(availableBrixieData?.data[0]?.currentValue);
  }, [availableBrixieData]);

  if (brixieAvailable === 0) {

    return (
      <Dialog disableScrollLock onClose={handleClose} open={open}>
        <Card sx={{
          px: 6,
          pt: 2,
          pb: 2,
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'background.default',
          overflowY: 'auto',
        }}>
          <Box
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon/>{' '}
            </IconButton>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" align="center">
              Sold Out
            </Typography>
            <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
              This property has no BRiXies available to purchase
            </Typography>
          </Box>
        </Card>
      </Dialog>
    )
  }

  if (user?.status !== 'Approved') {

    return (
      <Dialog disableScrollLock onClose={handleClose} open={open}>
        <Card sx={{
          px: 6,
          pt: 2,
          pb: 2,
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'background.default',
          overflowY: 'auto',
        }}>
          <Box
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon/>{' '}
            </IconButton>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" align="center">
              Your account is not approved.
            </Typography>
            <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
              You will be notified when your account is approved by email
            </Typography>
          </Box>
        </Card>
      </Dialog>
    )
  }

  if (user?.status === 'Approved' && brixieAvailable > 0) {
    return (
      <Dialog disableScrollLock onClose={handleClose} open={open}>
        <Card sx={{
          px: 6,
          pt: 2,
          pb: 2,
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'background.default',
          overflowY: 'auto',
        }}>
          <Box
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon/>{' '}
            </IconButton>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" align="center">
              Set Quantity
            </Typography>
            <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
              Enter how much you would like to invest:
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  px: {xs: 2, md: 16},
                }}
              >
                <Grid container>
                  <Grid item xs={2}>
                    <AttachMoneyOutlinedIcon sx={{mt: 2, opacity: 0.7}}/>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      value={investmentAmount}
                      onChange={handleChangeInvestmentAmount}
                      onBlur={handleOnBlurInvestmentAmount}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        boxShadow: 'rgb(0, 0, 0, 0.1) 6px 6px 10px 1px',
                        borderRadius: '4px',
                      }}
                      inputProps={{style: {textAlign: 'center'}}}
                      type="text"
                      inputMode="numeric"
                      placeholder="$"
                    />
                  </Grid>
                  {investmentAmountError && (
                    <Box sx={{ml: 2}}>
                      <FormErrorMessage message={investmentAmountErrorMessage}/>
                    </Box>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6" sx={{py: 1}}>
                  - OR -
                </Typography>
                <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
                  Select the amount of BRiXies you want:
                </Typography>
                <Typography color="gray" align="center" variant="body2" sx={{mt: 0, mb: 2}}>
                  {brixAvailable} BRiX available
                </Typography>
              </Box>
            </Grid>
            {brixCategories.map((brixType, key) => {
              return (
                <Grid key={key} item xs={12} sm={4} md={4}>
                  <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Card
                      sx={{
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,.15)',
                        borderWidth: 2,
                        boxShadow: 'none',
                        opacity: brixType.disabled ? 0.5 : 1,
                        width: '140px',
                        height: '140px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {brixType.label === '1 BRiX = $1,000' ? (
                        <img width="42" height="24" alt="brick" src={brixType.image}/>
                      ) : (
                        <img width="20" height="24" alt="brick" src={brixType.image}/>
                      )}
                      <Typography
                        justifyContent="center"
                        color="primary"
                        variant={'body2'}
                        sx={{mt: 2, fontSize: '12px', textTransform: 'uppercase'}}
                      >
                        {brixType.type}
                      </Typography>
                      <TextField
                        type="text"
                        variant="standard"
                        inputMode="numeric"
                        inputProps={{style: {textAlign: 'center'}}}
                        value={brixType.value}
                        onChange={(e) => handleBrixChange(e, brixType.type)}
                        sx={{pt: 1, px: 6}}
                      />
                      <Typography justifyContent="center" variant={'body2'} sx={{mt: 2, fontSize: '12px'}}>
                        {brixType.label}
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Button
            onClick={handleOnClickAddToCart}
            disabled={totalDisplay < 100}
            fullWidth
            variant="contained"
            color="primary"
            sx={{mt: 2, height: '40px'}}
          >
            <Typography variant="body2">Add to cart</Typography>
          </Button>
          {priceError && (
            <Box sx={{mt: 1, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Alert severity="error" sx={{fontSize: '11px'}}>
                There was an error loading the data. Please close the modal and try again.
              </Alert>
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  pt: 3,
                  pb: 4,
                }}
              >
                <Typography sx={{textTransform: 'uppercase',}} variant="body2">
                  Total ($)
                  <Tooltip title="Not including fees">
                    <IconButton>
                      <InfoIcon color="secondary"/>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{flexGrow: 1}}/>
                <Typography
                  sx={{
                    typography: {sm: 'h5', xs: 'body2'},
                    mt: 1
                  }}>{formatPriceWithoutSymbol(totalDisplay)} USD</Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default BrixSelectionModal;
