import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import useAuth from '../useAuth';
import useApi from '@panel/utils/apis';
import React, {useState} from 'react';
import {StripeCardElement} from '@stripe/stripe-js';

const useStripeCreditCard = () => {
  const stripe = useStripe();
  const {user} = useAuth();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [succeed, setSucceed] = useState<boolean>(false);
  const {paymentMethodApi} = useApi();
  const elements = useElements();

  const addCreditCard = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const {error, paymentMethod} = await stripe!.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardElement) as StripeCardElement
      });

      if (error) {
        setError(error.message as string);
        return;
      }
      const response = await paymentMethodApi.post(`/attach-payment-method`, {
        paymentMethodId: paymentMethod?.id,
        userId: user?.id
      });
      if ([200, 201].includes(response.status)) {
        setError(undefined);
        setSucceed(true);
      } else {
        setError('Could not attach payment method');
        setSucceed(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Could not attach payment method');
    }
  };

  return {
    error,
    succeed,
    loading,
    addCreditCard,
  };
};

export default useStripeCreditCard;
