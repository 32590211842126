import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, FormHelperText, TextField, Typography} from '@mui/material';
import api from '@panel/utils/api';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';
import * as Yup from 'yup';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().max(255).required('Email is required'),
});

const ForgotPasswordForm = ({...rest}) => {
  const [success, setSuccess] = useState(false);
  const {register, handleSubmit, formState} = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });
  const {errors} = formState;

  const mutation = useMutation((email) => api['auth'].post('/recover-password', {email}), {
    onSuccess: ({status}) => {
      setSuccess(status === 202);
    },
    onError: () => {
      //
    },
  });

  const onSubmit = async (data: any) => {
    setSuccess(false);
    mutation.mutate(data.email);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
      {!success && (
        <>
          <Typography sx={{mt: 2}} variant="body2">
            EMAIL
          </Typography>
          <TextField
            {...register('email')}
            fullWidth
            autoFocus
            helperText={errors?.['email']?.message}
            error={Boolean(errors?.['email']?.message)}
            margin="normal"
            name="email"
            type="email"
            variant="outlined"
            disabled={success}
          />

          <Box mt={2}>
            <Button
              color="primary"
              disabled={formState.isSubmitting || success}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Recover Password
            </Button>
          </Box>
        </>
      )}
      {success && (
        <Box mt={3} ml={4}>
          <Typography>Reset password link has been sent, please review your email</Typography>
        </Box>
      )}
      <Box mt={2}></Box>
    </form>
  );
};

export default ForgotPasswordForm;
