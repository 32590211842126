import * as Yup from 'yup';

export const AccountDetailsValidation = Yup.object().shape({
  userName: Yup.string()
    .required('Your username is required')
    .trim('Your username cannot include leading and trailing spaces')
    .min(3, 'Your username must have at least 3 chars'),
  password: Yup.string()
    .min(8, 'Password requires to be at least 8 characters long')
    .required('Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Please confirm your passsword'),
  securityQuestion1: Yup.string().required('Please provide a question'),
  securityAnswer1: Yup.string().required('Please provide an answer'),
});

export const PersonalInfoValidation = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Must be a valid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
});
