import {Button, Grid} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const StepperButtons: React.FunctionComponent<{
  prevStep: () => void;
  nextStep: () => void;
  disabled?: boolean;
  loading?: boolean;
}> = ({prevStep, nextStep, disabled, loading}) => {
  return (
    <Grid container mt={2} spacing={2}>
      <Grid item xs={12} md={6}>
        <Button onClick={prevStep}
                sx={{color: '#000000', borderColor: '#000000'}}
                fullWidth
                size="large"
                variant="outlined">
          Back
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <LoadingButton
          form="payment-form"
          color="primary"
          type="submit"
          loading={loading}
          onClick={nextStep}
          fullWidth
          disabled={disabled}
          size="large"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default StepperButtons;
