import {useContext, useEffect, useState} from 'react';
import {Box, Button, Card, Container, Grid, Typography} from '@mui/material';
import RegisterContext from '@panel/contexts/RegisterContext';
import {IRegistrationWizard} from '@panel/interfaces/registration-wizard';
import useAuth from '@panel/hooks/useAuth';
import api from '@panel/utils/api';

const Review = ({prevStep, nextStep}: IRegistrationWizard) => {
  const registerContext = useContext(RegisterContext);
  const [questionList, setQuestionList] = useState<Array<any>>([]);
  const {registerUser} = useAuth();

  const loadQuestions = async () => {
    try {
      const res = await api['security-question'].get();
      setQuestionList(res.data.data);
    } catch (error) {
      //
    }
  };

  const submitReview = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const {tokens, user}: any = registerContext?.state.response;
    registerUser(tokens, user);
    nextStep && nextStep();
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    loadQuestions();
  }, []);

  return (
    <Container maxWidth="sm" sx={{pt: {xs: 2, md: 0, lg: 0}}}>
      <Card sx={{p: 4, border: 'none', boxShadow: 'none'}}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" align="center">
            Review
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
            Please review your information
          </Typography>
        </Box>
        <Box sx={{flexGrow: 1, mt: 4, ml: {md: 8, xs: 1}, mr: {md: 8, xs: 1}}}>
          <Box>
            <Typography variant="subtitle1" sx={{fontWeight: 700}}>
              Account Details&nbsp;{' '}
              {/* <EditIcon onClick={prevStep} fontSize="small" color="primary" sx={{cursor: 'pointer'}} /> */}
            </Typography>
            <Grid container spacing={2} sx={{py: 2, px: 4}}>
              <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body2" color="gray" sx={{mb: 1}}>
                  USER NAME:
                </Typography>
                <Typography variant="body2" color="black" sx={{fontWeight: 600}}>
                  {registerContext?.state.userName}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body2" color="gray" sx={{mb: 1}}>
                  SECURITY QUESTION:{' '}
                </Typography>
                <Typography variant="body2" color="black" sx={{fontWeight: 600}}>
                  {questionList.filter((q) => q.id === registerContext?.state.securityQuestion1)[0]?.question}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography variant="subtitle1" sx={{fontWeight: 700, mb: 1}}>
              Investor Type&nbsp;{' '}
              {/* <EditIcon onClick={prevStep} fontSize="small" color="primary" sx={{cursor: 'pointer'}} /> */}
            </Typography>
            <Grid container spacing={2} sx={{py: 2, px: 4}}>
              <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body2" color="gray" sx={{mb: 1}}>
                  TYPE:
                </Typography>
                <Typography variant="body2" color="black" sx={{fontWeight: 600}}>
                  Individual
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography variant="subtitle1" sx={{fontWeight: 700}}>
              Personal Information&nbsp;{' '}
              {/* <EditIcon onClick={prevStep} fontSize="small" color="primary" sx={{cursor: 'pointer'}} /> */}
            </Typography>
            <Grid container spacing={2} sx={{py: 2, px: 4}}>
              <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body2" color="gray" sx={{mb: 1}}>
                  FIRST NAME:
                </Typography>
                <Typography variant="body2" color="black" sx={{fontWeight: 600}}>
                  {registerContext?.state.firstName}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body2" color="gray" sx={{mb: 1}}>
                  LAST NAME:
                </Typography>
                <Typography variant="body2" color="black" sx={{fontWeight: 600}}>
                  {registerContext?.state.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body2" color="gray" sx={{mb: 1}}>
                  EMAIL:
                </Typography>
                <Typography variant="body2" color="black" sx={{fontWeight: 600}}>
                  {registerContext?.state.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body2" color="gray" sx={{mb: 1}}>
                  PHONE NUMBER:
                </Typography>
                <Typography variant="body2" color="black" sx={{fontWeight: 600}}>
                  {registerContext?.state.phone}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={2}>
          <Grid container mt={2} spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                sx={{color: '#000000', borderColor: '#000000'}}
                onClick={prevStep}
                fullWidth
                size="large"
                variant="outlined"
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button color="primary" onClick={submitReview} fullWidth size="large" type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Container>
  );
};

export default Review;
