import React from 'react';
import {useEffect} from 'react';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import {visuallyHidden} from '@mui/utils';
import CircleIcon from '@mui/icons-material/Circle';
import {Link as RouterLink} from 'react-router-dom';
import {Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useAuth from '@panel/hooks/useAuth';
import api from '@panel/utils/api';
import {useQuery} from 'react-query';
import {formatWithDigits} from '@brix/shared-frontend';

const transactionsTypes = [
  'Debit',
  'Credit',
  'Purchase',
  'Withdraw',
  'Failed',
  'Wallet Recharge',
  'Money Claim'
]

const transactionsStatus = [
  'Pending', // When the transaction is started but haven't validated the payment
  'Canceled', // When the user cancels the transaction before succeed / failed
  'Declined', // When the payment is declined
  'Paid', // After validating payment deposit
  'Failed', // If fails for something else
  'Needs Action' // If fails for something else
]

interface Data {
  date: string;
  type: string;
  amount: string;
  fee: string;
  method: string;
  status: string;
  id: string;
}

let rows: any = [];
type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'DATE',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'TRANSACTION AMOUNT',
  },
  {
    id: 'fee',
    numeric: false,
    disablePadding: false,
    label: 'TRANSACTION FEE',
  },
  {
    id: 'method',
    numeric: false,
    disablePadding: false,
    label: 'METHOD',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'STATUS',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{whiteSpace: 'nowrap'}}>
      <TableRow sx={{whiteSpace: 'nowrap'}}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {headCell.label}
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

interface EnhancedTransactionTableProps {
  onPageChange: (event: React.MouseEvent | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {numSelected} = props;

  return (
    <Toolbar
      sx={{
        pl: {sm: 2},
        pr: {xs: 1, sm: 3},
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{flex: '1 1 100%'}} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{flex: '1 1 100%'}} variant="h6" id="tableTitle" component="div">
          Transfer History
        </Typography>
      )}
      {numSelected > 0 ? (
        <Button size="large" variant="outlined" sx={{width: '20%'}}>
          <Typography variant="body2" sx={{fontSize: '12px'}}>
            Download Document
          </Typography>{' '}
        </Button>
      ) : (
        <RouterLink to={'/financials'} style={{textDecoration: 'initial'}}>
          <Button size="large" variant="outlined" sx={{width: '120%'}}>
            <Typography variant="body2" sx={{fontSize: '12px'}}>
              See all
            </Typography>{' '}
          </Button>
        </RouterLink>
      )}
    </Toolbar>
  );
};
const loadTransaction = async (userId: string | undefined): Promise<Array<any>> => {
  const res = await api['users'].get(`/${userId}/transactions`, {include: ['paymentMethod', 'property']});
  const data = res.data as Array<any>;
  return data?.sort((a: any, b: any) => {
    const first = (new Date(a.createdAt)).getTime();
    const second = (new Date(b.createdAt)).getTime();
    if (first === second) {
      return 0;
    }
    return second - first;
  }) ?? [];
}

const TransactionHistory = (props: EnhancedTransactionTableProps) => {
  const {onPageChange, onRowsPerPageChange, ...other} = props;
  const {user} = useAuth();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('date');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const {isLoading: isTransactionsLoading, data: transactionsData} = useQuery(['users', user?.id, 'transactions'], () =>
    loadTransaction(user?.id))

  useEffect(() => {
    if (transactionsData) {
      rows = transactionsData?.map(t => {
        return {
          date: t.createdAt,
          type: transactionsTypes[t.transactionType],
          amount: t.amount,
          fee: t.feeAmount,
          method: t.__paymentMethod__?.brand ? t.__paymentMethod__?.brand + ' ' + t.__paymentMethod__?.lastFourDigits : 'WALLET',
          status: transactionsStatus[t.status] ?? 'Pending',
          id: t.id,
        }
      }) ?? [];
    }
    setRowsPerPage(5)
  }, [transactionsData, isTransactionsLoading]);

  const handleRequestSort = () => {
    setOrder('desc');
    setOrderBy('date');
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{width: '100%'}}>
      <Paper sx={{width: '100%', mb: 2}}>
        <EnhancedTableToolbar numSelected={selected.length}/>
        <TableContainer>
          {isTransactionsLoading ? <CircularProgress color='primary' sx={{
              marginLeft: '550px',
              marginTop: '20px'
            }}/> :
            <Table sx={{minWidth: 750, whiteSpace: 'nowrap'}} aria-labelledby="tableTitle"
                   size={'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">{row.date.toString().slice(0, 10)}</TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                          {row.type}
                        </TableCell>
                        <TableCell align="center" sx={{pr: 6}}>{formatWithDigits(Number(row.amount))}</TableCell>
                        <TableCell align="center">{formatWithDigits(Number(row.fee))}</TableCell>
                        <TableCell align="center">{row.method}</TableCell>
                        <TableCell align="right">
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <CircleIcon
                              sx={{
                                color: `${
                                  row.status === 'Paid' ? 'green' : row.status === 'Pending' ? 'yellow' : 'red'
                                }`,
                                fontSize: 18,
                                marginRight: '5px',
                              }}
                            />
                            <Typography variant="body2">{row.status}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 53 * emptyRows}}>
                    <TableCell colSpan={6}/>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default TransactionHistory;
