import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {BrixReport, formatBrixUnit, formatPriceWithoutSymbol, formatWithDigits} from '@brix/shared-frontend'
import {useEffect, useState} from 'react';
import {api} from '@panel/utils';
import useAuth from '@panel/hooks/useAuth';
import {AxiosResponse} from 'axios';

const ReportingTable = () => {
  const {user} = useAuth();
  const [items, setItems] = useState<Array<BrixReport>>([]);

  useEffect(() => {
    user && api['/brix-reports'].get(`/user/${user.id}`).then((response: AxiosResponse) => {
      setItems(response.data)
    });
  }, [user]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead sx={{fontSize: '20px'}}>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell align="right">Total BRiX</TableCell>
            <TableCell align="right">Purchase Price</TableCell>
            <TableCell align="right">Total Dividends</TableCell>
            <TableCell align="right">Dividends %</TableCell>
            <TableCell align="right">Purchase Fees</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: BrixReport, index: number) => (
            <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell component="th" scope="row">{item.month}</TableCell>
              <TableCell align="right">
                {formatBrixUnit(item.totalBrixies ?? 0, item.totalBrix ?? 0, item.totalStacks ?? 0)}
              </TableCell>
              <TableCell align="right">{formatWithDigits(item.totalBrixValue)}</TableCell>
              <TableCell align="right">{formatWithDigits(item.totalDividends)}</TableCell>
              <TableCell align="right">
                {formatPriceWithoutSymbol(item.totalDividends / item.totalBrixValue)}%
              </TableCell>
              <TableCell align="right">{formatWithDigits(item.totalFee)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReportingTable;
