import {Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import * as React from 'react';
import {FC} from 'react';

export type SelectedPaymentMethodCategory = 'creditCard' | 'wallet';
export type SelectMethodParams = {
  category: SelectedPaymentMethodCategory;
  setCategory: (category: SelectedPaymentMethodCategory) => void
};

const SelectMethodHeader: FC<SelectMethodParams> = ({category, setCategory}: SelectMethodParams) => {

  const handleCategorySelected = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    event.preventDefault();
    setCategory(value as SelectedPaymentMethodCategory);
  }

  return (
    <>
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <Typography variant="h3" align="center">
          Payment Method
        </Typography>
        <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 4}}>
          Please select your payment method.
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item
              xs={12}
              sx={{
                backgroundColor: 'background.default',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                ml: 'auto',
                mr: 'auto',
              }}>
          <FormControl>
            <RadioGroup defaultValue="creditCard" row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{fontWeight: 600}}
                        value={category}
                        onChange={handleCategorySelected}>
              <FormControlLabel value="creditCard"
                                control={<Radio/>}
                                label={<Typography style={{fontWeight: 600}}>Credit / Debit Card</Typography>}/>
              <FormControlLabel value="wallet"
                                control={<Radio/>}
                                label={<Typography style={{fontWeight: 600}}>Pay With Financials</Typography>}/>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectMethodHeader;
