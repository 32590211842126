import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '@panel/hooks/useAuth';

const GuestGuard = ({ children }: { children: React.ReactElement }) => {
  const { isAuthenticated, redirectUri } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={redirectUri ?? '/dashboard'} />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
