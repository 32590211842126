import {useEffect, useState} from 'react';
import ProgressHeader from './ProgressHeader';
import PaymentMethod from './PaymentMethod';
import Review from './Review';
import {toast} from 'react-toastify';
import useCart from '@panel/hooks/cart/useCart';
import useAuth from '@panel/hooks/useAuth';
import api from '@panel/utils/api';
import CompletionMessage from './Verification/CompletionMessage';
import {useHistory} from 'react-router-dom';
import {Box} from '@mui/material';
import {IPaymentMethod} from '@brix/shared-frontend';
import {AxiosResponse} from 'axios';
import {usePortfolioContext} from '@panel/contexts/PortfolioContext';
import MFAConfirmationDialog from '@panel/components/MFAConfirmationDialog';
import {useMFAContext} from '@panel/contexts/MFAContext';
import {useWalletContext} from '@panel/contexts/WalletContext';

const CheckoutWizard = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const {products, removeAllProducts} = useCart();
  const {user} = useAuth();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod | null>();
  const history = useHistory();
  const {isEnabled} = useMFAContext();
  const {needsToRefetch} = usePortfolioContext();
  const [needsToValidate, setNeedsToValidate] = useState<boolean>(false);
  const {needsToRefetch: needsToRefetchWallet} = useWalletContext();

  const next = () => {
    if (currentStep >= 2) {
      setCurrentStep(4);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    if (products && products.length) {
      const formattedCartItems = formatCartItemsForCheckout();
      api['payment'].post(`/pre-checkout`, {
        userId: user?.id,
        cartItems: formattedCartItems,
      }).then((response: AxiosResponse) => {
        if (![200, 201, 202].includes(response.status)) {
          toast.error("There's not available brixes to purchase.")
          history.push(`/my-cart`);
        }
      }, () => {
        toast.error("There's not available brixes to purchase.")
        history.push(`/my-cart`);
      });
    }
  }, [products]);

  const prev = () => {
    if (currentStep <= 0) {
      setCurrentStep(1);
      history.push(`/my-cart`);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const doPayment = async () => {
    setLoading(true);
    const formattedCartItems = formatCartItemsForCheckout();
    try {
      await api['payment'].post(`/checkout`, {
        userId: user?.id,
        paymentMethodId: selectedPaymentMethod?.id,
        paymentMethodType: selectedPaymentMethod?.type,
        cartItems: formattedCartItems,
      });
      needsToRefetch && needsToRefetch();
      needsToRefetchWallet && needsToRefetchWallet();
      removeAllProducts();
      setCurrentStep(currentStep + 1);
    } catch (e: any) {
      toast.error(e.data.message, {
        position: 'top-left',
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: 3000,
      });
    }
    setLoading(false);
  }

  const submit = async () => {
    if (isEnabled) {
      setNeedsToValidate(true);
    } else {
      await doPayment();
    }
  };

  const setPaymentMethod = (data: any) => {
    setSelectedPaymentMethod(data);
  };

  const formatCartItemsForCheckout = () => {
    return products.map((product) => {
      return {
        propertyId: product.id,
        type: 'initial',
        amount: product.quantity,
        brixUnit: {brixie: product.quantity, brix: 0, stack: 0, halfBrix: 0},
      };
    });
  };

  const handleOnValidated = async () => {
    setNeedsToValidate(false);
    await doPayment();
  }
  return (
    <Box sx={{mt: 2}}>
      <ProgressHeader currentStep={currentStep}/>
      {currentStep === 0 && <PaymentMethod setPayment={setPaymentMethod} nextStep={next} prevStep={prev}/>}
      {currentStep === 1 && (
        <Review
          submitPayment={submit}
          paymentMethod={selectedPaymentMethod}
          nextStep={next}
          prevStep={prev}
          loading={loading}
        />
      )}
      {currentStep === 2 && <CompletionMessage/>}
      {needsToValidate && <MFAConfirmationDialog onValidated={handleOnValidated}/>}
    </Box>
  );
};

export default CheckoutWizard;
