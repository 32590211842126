import {Box} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import RegistrationWizard from './RegistrationWizard';
import Page from '@panel/components/Page';

const RegisterView: React.FC<IPage> = () => {
  return (
    <Page title="Register">
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
        }}
      >
        <RegistrationWizard />
      </Box>
    </Page>
  );
};

export default RegisterView;
