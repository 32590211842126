import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import Logo from '@panel/components/Logo';

interface AuthLayoutTopbarProps {
  blackText: string;
  redLink: string;
  uri: string;
}

export const AuthLayoutTopbar = ({blackText, redLink, uri}: AuthLayoutTopbarProps) => {
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{minHeight: 64}}>
          <RouterLink to="/">
            <Logo width={60} height={50} />
          </RouterLink>
          <Box sx={{flexGrow: 1}} />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography sx={{fontWeight: 500}} color="black" variant="body2">
              <SquareIcon sx={{fontSize: '10px', mr: 1}} color="primary" />
              {blackText}{' '}
              <RouterLink style={{textDecoration: 'none', fontWeight: 500}} to={uri}>
                <span style={{color: '#CC4645'}}>{redLink}</span>
              </RouterLink>
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AuthLayoutTopbar;
