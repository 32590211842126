import { formatPrice, Infographic, IPropertyAddress, IPropertySpecs, sum } from '@brix/shared-frontend';
import { Box, Button, Card, Grid, Tooltip, Typography } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import React, { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import api from '@panel/utils/api';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { AxiosResponse } from 'axios';

const Financials: React.FC<{
  propertyData: IPropertySpecs;
  addressItem?: IPropertyAddress;
  featuredImageUrl?: string;
  openBrixModal: () => void;
}> = ({ openBrixModal }) => {
  const { id } = useParams<any>();
  const [infographics, setInfographics] = useState<Infographic>({});

  const propertyItems = () => {

    const {
      rentalGrossAmount,
      appreciationDate,
      expensesAmount,
      appreciationValue,
      lastReappraisalDate,
      firstAppreciationValue,
      mortgageBalanceAmount,
      rentalNetAmount,
      maintenanceAmount,
      taxesAmount,
      insuranceAmount,
      managementFeeAmount,
      mortgageMonthlyAmount,
      mortgageInterestAmount,
      reservesPct
    } = infographics;
    return [
      {
        key: 'appreciationValue',
        name: 'Appreciation Value',
        value: formatPrice(appreciationValue)
      },
      {
        key: 'firstAppreciationValue',
        name: 'First Appraisal Value',
        value: formatPrice(firstAppreciationValue)
      },
      {
        key: 'rentalGrossAmount',
        name: 'Rental Gross',
        value: formatPrice(rentalGrossAmount),
      },
      {
        key: 'expenses',
        name: 'Total Expenses',
        value: reservesPct && rentalGrossAmount ? formatPrice(sum(maintenanceAmount,taxesAmount, insuranceAmount,managementFeeAmount,mortgageMonthlyAmount,mortgageInterestAmount,expensesAmount, ((reservesPct * rentalGrossAmount) /100))) : formatPrice(sum(maintenanceAmount,taxesAmount, insuranceAmount,managementFeeAmount,mortgageMonthlyAmount,mortgageInterestAmount,expensesAmount)),
      },
      {
        key: 'rentalNetAmount',
        name: 'Rental Net',
        value: formatPrice(rentalNetAmount),
      },
      {
        key: 'lastReappraisalDate',
        name: 'Last Appraisal Date',
        tooltip: 'Appraisals are done quarterly',
        value: lastReappraisalDate ? moment(lastReappraisalDate).format('yyyy-MM-DD') : '?',
      },
      {
        key: 'nextReappraisalDate',
        name: 'Next Appraisal Date',
        tooltip: 'Appraisals are done quarterly',
        value: appreciationDate ? moment(appreciationDate).format('yyyy-MM-DD') : '?',
      },
      {
        key: 'mortgageBalanceAmount',
        name: 'Remaining Mortgage Balance',
        value: mortgageBalanceAmount ? formatPrice(mortgageBalanceAmount) : '?',
      },
    ]
  }

  React.useEffect(() => {
    api['properties'].get(`/${id}/infographics`).then((response: AxiosResponse) => {
      setInfographics(response.data)
    })
  }, []);

  const sold = () => (infographics?.sold ?? 0) / 10;

  const unavailable = () => (infographics?.unavailable ?? 0) / 10;

  const available = () => (infographics?.available ?? 0) / 10;


  return (
    <Card
      sx={{
        p: 4,
        backgroundColor: 'white',
      }}
    >
      <Grid container spacing={2} direction="column" alignItems="center" sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 6 }}>
            Fractional Ownership
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            textAlign: 'center',
          }}>
            <PieChart
              data={[
                { title: available() + ' BRiX available for purchase', value: available(), color: '#CC4645' },
                { title: sold() + ' BRiX already sold', value: sold(), color: '#508cb8' },
                { title: unavailable() + ' BRiX unavailable', value: unavailable(), color: '#b4b4b4' },
              ]}
              animate
              label={() => available() + ' BRiX'}
              labelStyle={{
                fontSize: '12px',
                fontWeight: 600,
                fontFamily: 'sans-serif',
                fill: '#CC4645',
              }}
              totalValue={available() + sold() + unavailable()}
              startAngle={270}
              labelPosition={0}
              lineWidth={15}
            />
            <Typography variant="subtitle1" sx={{
              mt: {
                xs: '-85px',
                sm: '-120px',
                md: '-90px',
                lg: '-130px',
              }, opacity: 0.7, mb: '120px',
              fontSize: {
                xs: '10px',
                sm: '14px',
                lg: '16px',
              },
              color: 'black',
            }}>
              Available for purchase
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
          },
          mb: '10%'
        }}
      >
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <SquareIcon sx={{ color: '#CC4645' }} />
          <Typography variant="body2" sx={{ fontWeight: 500, pl: '5px' }}>
            Available
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <SquareIcon sx={{ color: '#508cb8' }} />
          <Typography variant="body2" sx={{ fontWeight: 500, pl: '5px' }}>
            Sold
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <SquareIcon sx={{ color: '#b4b4b4' }} />
          <Typography variant="body2" sx={{ fontWeight: 500, pl: '5px' }}>
            Unavailable
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ height: '100%', display: 'flex' }}>
        <Grid item xs={12}>
          {propertyItems().map((item) => (
            <Tooltip key={item.key} title={item.tooltip ?? ''}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                <Typography variant="body2" sx={{ color: 'gray', fontWeight: 400, opacity: 0.8 }}>
                  {item.name}
                </Typography>
                <Box sx={{ flexGrow: 1, mx: 1, mb: 1 }} />
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {item.value}
                </Typography>
              </Box>
            </Tooltip>
          ))}
        </Grid>
      </Grid>
      <Button fullWidth onClick={openBrixModal} variant="contained" color="primary" sx={{ mt: 2, height: '40px' }}>
        <Typography variant="body2">Buy</Typography>
      </Button>
    </Card>
  );
};

export default Financials;
