import { ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import routes, { renderRoutes } from '@panel/routes';
import SplashScreen from '@panel/components/SplashScreen';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createTheme } from '@panel/theme';
import { AuthConsumer, AuthProvider } from '@panel/contexts/AuthContext';
import { createBrowserHistory } from 'history';
import { useSettings } from '@brix/shared-frontend';
import { ToastContainer } from 'react-toastify';
import { UsersnapProvider } from '@panel/contexts/UsersnapContext';
import { CartProvider } from '@panel/contexts/CartContext';
import { PortfolioProvider } from '@panel/contexts/PortfolioContext';
import { WalletProvider } from '@panel/contexts/WalletContext';
import { MFAProvider } from '@panel/contexts/MFAContext';
import { FeesProvider } from '@panel/contexts/FeesContext';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FunctionComponent<Record<string, unknown>> = () => {
  const history = createBrowserHistory();
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    mode: settings.theme,
  });

  return (
    <AuthProvider>
      <UsersnapProvider>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <QueryClientProvider client={queryClient}>
              <FeesProvider>
                <WalletProvider>
                  <PortfolioProvider>
                    <CartProvider>
                      <MFAProvider>
                        <AuthConsumer>{(auth) => (!auth.isInitialized ?
                          <SplashScreen /> : renderRoutes(routes))}</AuthConsumer>
                      </MFAProvider>
                      <ToastContainer />
                    </CartProvider>
                  </PortfolioProvider>
                </WalletProvider>
              </FeesProvider>
            </QueryClientProvider>
          </Router>
        </ThemeProvider>
      </UsersnapProvider>
    </AuthProvider>
  );
};

export default App;
