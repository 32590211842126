import {Box, Card, Grid, IconButton, Menu, MenuItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import BrixBankAccountModal from '@panel/components/BrixBankAccountModal';
import useStripeBankAccount, {RegisteredBankAccountInfo} from '@panel/hooks/stripe/useStripeBankAccount';
import {Image} from 'mui-image';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {toast} from 'react-toastify';

const cart_styles = {
  borderStyle: 'solid',
  borderColor: 'rgba(0,0,0,.15)',
  borderWidth: 2,
  px: 3,
  py: 1,
  boxShadow: 'none',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'all .2s ease-in-out',
  cursor: 'pointer'
}
const bankStyles: SxProps<Theme> = {
  textTransform: 'capitalize',
  fontWeight: 400,
  pt: 1,
  fontSize: {
    xs: '11px',
    sm: '0.85rem',
  }
};

const currencyStyles: SxProps<Theme> = {
  mt: 2,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#CC4645',
  borderRadius: '5px'
}

const RegisteredPayoutAccounts = () => {

  const {bankAccounts, deleteBankAccount} = useStripeBankAccount();
  const [visible, setVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openPaymentMethodOptionsMenu = Boolean(anchorEl);

  const handleAddNewAccount = () => {
    setVisible(true);
  }

  const handleCloseBankAccount = () => {
    setVisible(false);
  }

  const handleClickOnMoreIcon = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOnDelete = (account: RegisteredBankAccountInfo) => {
    setAnchorEl(null);
    deleteBankAccount(account.id).then(() => {
      toast.success('Account successfully deleted');
    }, () => {
      toast.error('Could not delete account. Please try again later.');
    })
  }
  return (
    <>
      <Grid container spacing={1} sx={{pt: 2}}>
        <Grid item xs={12}>
          {bankAccounts?.map(account => {
            return (
              <Box sx={{display: 'flex', justifyContent: 'center'}} key={account.routing_number}>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={2}>
                    <Image src="../assets/images/bank.png" alt={'BRiX'}
                           sx={{pt: 2}} duration={50} easing="ease-in-out"
                           width={'30px'} height={'30px'} fit="contain"/>
                  </Grid>

                  <Grid item xs={5} md={7}>
                    <Typography variant="body1" sx={bankStyles}>
                      {account.bank_name} {' ****'}
                      <span style={{fontWeight: 600}}>{account.last4}</span>
                    </Typography>
                    <Typography variant="body1" sx={{fontWeight: 400, fontSize: {xs: '11px', sm: '0.85rem'}}}>
                      {'Routing Number: ' + (account.routing_number)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Box sx={currencyStyles}>
                      <Typography variant={'body2'} sx={{color: 'white', fontSize: {xs: '11px', sm: '0.85rem'}}}>
                        {account.currency?.toUpperCase()}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      aria-label="more"
                      aria-controls={openPaymentMethodOptionsMenu ? 'long-menu' : undefined}
                      aria-expanded={openPaymentMethodOptionsMenu ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClickOnMoreIcon}
                      sx={{mt: 1, borderRadius: '50%'}}>
                      <MoreHorizIcon/>
                    </IconButton>
                  </Grid>
                  <Menu id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl} disableScrollLock
                        open={openPaymentMethodOptionsMenu}
                        onClose={() => setAnchorEl(null)}
                        PaperProps={{style: {maxHeight: 204, width: '20ch',}}}>
                    <MenuItem onClick={() => handleClickOnDelete(account)}>Delete</MenuItem>
                  </Menu>
                </Grid>
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
            {' '}
            <Card sx={cart_styles} onClick={handleAddNewAccount}>
              <Typography sx={{fontWeight: 600, textAlign: 'center'}}>+ Add Bank Account</Typography>
            </Card>
          </Box>
        </Grid>
      </Grid>
      {visible && (
        <BrixBankAccountModal open={true} onClose={handleCloseBankAccount}/>
      )}
    </>
  );
}

export default RegisteredPayoutAccounts;
