import { Box, Card, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import { formatWithDigits } from '@brix/shared-frontend';
import { Link as RouterLink } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Image } from 'mui-image';
import { useEffect, useState } from 'react';
import { usePortfolioContext } from '@panel/contexts/PortfolioContext';

const Insights = () => {
  const { insightsData } = usePortfolioContext();
  const [insights, setInsights] = useState<any>([]);

  useEffect(() => {
    if (insightsData === undefined) {
      return;
    }
    setInsights([
      {
        type: `BRiX`,
        values: [
          {
            value: `${(insightsData.stackCount) / 100}`,
            unit: ' Stax',
          },
          {
            value: `${(insightsData?.brixCount) / 10}`,
            unit: ' BRiX',
          },
          {
            value: `${insightsData?.brixieCount}`,
            unit: ' BRiXies',
          },

          ],
          description: 'Total # of BRiX owned',
          tooltip: '1 Stax = 100 BRiX, 1 BRiX = 100 BRiXies',
          icon: '../assets/images/brick-icon.png',
          actionIcon: '',
          action: '',
        },
        {
          type: `value`,
          values: [{
            value: `${formatWithDigits(insightsData?.brixieValue)}`
          }],
          description: 'Total $ value of BRiX owned',
          tooltip: 'The current total estimated value of all the fractions of property you own.',
          icon: '../assets/images/bill-icon.png',
          actionIcon: '',
          action: '',
        },
        {
          type: `dividends`,
          values: [{
            value: `${formatWithDigits(insightsData?.dividends)}`
          }],
          description: 'Current dividends earned to date',
          tooltip: 'Current dividends earned to date is the amount of net dividends on all your BRiX properties since starting the account.',
          icon: '../assets/images/coins-icon.png',
          actionIcon: '../assets/images/withdraw-money-icon.png',
          action: '',
        }
      ]
    )
  }, [insightsData])

  return (
    <Grid container spacing={2}>
      {insights?.map((stat: any, index: number) => (
        <Grid key={index} item xs={6} md={3}>
          <Card sx={{ backgroundColor: 'white', p: 3, pt: 2 }}>
            <CardHeader
              sx={{ px: 0, py: 1 }}
              avatar={
                <Image
                  src={stat.icon}
                  alt={stat.type}
                  duration={50}
                  easing="ease-in-out"
                  width={'100%'}
                  height={'60px'}
                  fit="contain"
                />
              } subheaderTypographyProps={{ p: 0 }} />

            <Typography variant="body2" sx={{ color: 'gray', py: 1 }}>
              {stat.description}
              <Tooltip arrow title={stat.tooltip}>
                <InfoIcon color={'secondary'} fontSize="small" sx={{ cursor: 'pointer', ml: 1, mb: '-6px' }} />
              </Tooltip>
            </Typography>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', md: 'row' },
            }}>
              {stat.values.map((item: any, idx: number) => (
                <Typography key={idx} variant="h5" sx={{ pb: 1 }}>
                  {item.value}
                  <span style={{ fontSize: '14px', marginRight: 2 }}>{item.unit}</span>{' '}
                  {stat.actionIcon ? (
                    <RouterLink to={'/financials'} style={{ textDecoration: 'initial' }}>
                      <Tooltip title={'Request Withdrawal'}>
                        <img alt="Request Withdrawal" height={25} width={25} src={stat.actionIcon} />
                      </Tooltip>
                    </RouterLink>
                  ) : null}
                </Typography>
              ))}
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Insights;
