/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Card, Chip, Grid, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useQuery } from 'react-query';
import api from '@panel/utils/api';
import { toast } from 'react-toastify';
import { FormErrorMessage } from '@panel/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useYupValidationResolver } from '@brix/shared-frontend';
import { ProfileValidation } from '../validations';
import 'react-phone-input-2/lib/style.css';
import useAuth from '@panel/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import DocumentUpload from './DocumentUpload';
import { loadCountries, loadStates } from '@panel/utils';
import {ChangePasswordForm} from './ChangePassword';

const GeneralInformation = () => {
  const { user } = useAuth();
  const resolver = useYupValidationResolver(ProfileValidation);
  const [initialProfileData, setInitialProfileData] = useState<any>({});
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const [isDocumentVerified, setIsDocumentVerified] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [citizenship, setCitizenship] = useState<string>(' ');
  const [citizenshipStatus, setCitizenshipStatus] = useState<string>(' ');
  const [state, setState] = useState<string>(' ');
  const [birthday, setBirthday] = useState<Date | null>(new Date());
  const [countryCode, setCountryCode] = useState<string>(' ');
  const [stateCode, setStateCode] = useState(' ');

  const { data: profileData, refetch: refetchProfileData } = useQuery(['users', user?.id, 'profile'], () =>
    api['users'].get(`/${user?.id}/profile`)
  );
  const { data: addressData, refetch: refetchAddressData } = useQuery(['users', user?.id, 'address'], () =>
    api['users'].get(`/${user?.id}/address`)
  );

  const { data: countries } = useQuery('countries', loadCountries, { keepPreviousData: true });

  const { register, handleSubmit, setValue, formState, control, watch } = useForm<any>({
    resolver,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const { errors } = formState;

  const { data: stateList } = useQuery(['states', { countryCode }], () => loadStates(countryCode), {
    keepPreviousData: true,
  });

  const onSubmitHandler = async (data: any) => {
    if (
      data.citizenship !== initialProfileData.citizenship ||
      data.citizenshipStatus !== initialProfileData.citizenshipcitizenshipStatus ||
      data.birthday !== initialProfileData.birthday ||
      data.ssn !== initialProfileData.ssn
    ) {
      setIsSaveButtonLoading(true);
      try {
        await api['users'].patch(`/${user?.id}/profile`, {
          ssn: data.ssn,
          citizenship: data.citizenship,
          citizenshipStatus: data.citizenshipStatus,
          dob: moment(data?.birthday).format('YYYY-MM-DD'),
        });
        await refetchProfileData();
        setIsSaveButtonLoading(false);
      } catch (e: any) {
        toast.error('Could not update profile', {
          position: 'top-left',
          pauseOnHover: false,
          closeOnClick: true,
          autoClose: 3000,
        });
        setIsSaveButtonLoading(false);
        return;
      }
    }

    if (
      data.address !== initialProfileData.address ||
      data.address2 !== initialProfileData.address2 ||
      data.countryId !== initialProfileData.countryId ||
      data.city !== initialProfileData.city ||
      data.stateCode !== initialProfileData.stateCode ||
      data.zipCode !== initialProfileData.zipCode
    ) {
      setIsSaveButtonLoading(true);
      try {
        await api['users'].patch(`/${user?.id}/address`, {
          street: data.address,
          street2: data.address2,
          city: data.city,
          stateCode: data.stateCode,
          countryId: data.countryId,
          zipCode: data.zipCode,
        });
        await refetchAddressData();
        setIsSaveButtonLoading(false);
      } catch (e: any) {
        toast.error('Could not update address', {
          position: 'top-left',
          pauseOnHover: false,
          closeOnClick: true,
          autoClose: 3000,
        });
        setIsSaveButtonLoading(false);
        return;
      }
    }

    setIsSaveButtonLoading(false);
    toast.success('Save successful', {
      position: 'top-left',
      pauseOnHover: false,
      closeOnClick: true,
      autoClose: 3000,
    });
    setEditMode(false);
  };

  const handleBirthdayChange = async (date: Date | null) => {
    setValue('birthday', date, { shouldValidate: true });
    setBirthday(date);
  };

  const handleCitizenshipChange = async (data: string) => {
    setValue('citizenship', data, { shouldValidate: true });
    setCitizenship(data);
  };

  const handleCitizenshipStatusChange = async (data: string) => {
    setValue('citizenshipStatus', data, { shouldValidate: true });
    setCitizenshipStatus(data);
  };

  const handleClickOnCancelButton = async () => {
    returnToInitialValues();
    setEditMode(false);
  };

  const handleCountryIdChange = async (data: string) => {
    setValue('countryId', data, { shouldValidate: true });
    setCountryCode(data);
    setValue('stateCode', '', { shouldValidate: true });
  };

  const handleStateCodeChange = async (data: string) => {
    setValue('stateCode', data, { shouldValidate: true });
    setStateCode(data);
  };

  const returnToInitialValues = () => {
    setValue('firstName', initialProfileData.firstName, { shouldValidate: true });
    setValue('lastName', initialProfileData.lastName, { shouldValidate: true });
    setValue('email', initialProfileData.email, { shouldValidate: true });
    setValue('address', initialProfileData.street, { shouldValidate: true });
    setValue('address2', initialProfileData.street2, { shouldValidate: true });
    setValue('city', initialProfileData.city, { shouldValidate: true });
    setValue('countryId', initialProfileData?.countryId, { shouldValidate: true });
    setCountryCode(initialProfileData?.countryId);
    setValue('stateCode', initialProfileData?.stateCode, { shouldValidate: true });
    setStateCode(initialProfileData?.stateCode);
    setValue('zipCode', initialProfileData.zipCode, { shouldValidate: true });
    setValue('ssn', initialProfileData.ssn, { shouldValidate: true });
    setValue('phone', initialProfileData.phone, { shouldValidate: true });
    setValue('birthday', initialProfileData.dob, { shouldValidate: true });
    setBirthday(initialProfileData.dob);
    setValue('citizenship', initialProfileData.citizenship, { shouldValidate: true });
    setCitizenship(initialProfileData.citizenship);
    setValue('citizenshipStatus', initialProfileData.citizenshipStatus, { shouldValidate: true });
    setCitizenshipStatus(initialProfileData.citizenshipStatus);
  };

  useEffect(() => {
    if (profileData) {
      setInitialProfileData({ ...initialProfileData, ...profileData?.data });
      setValue('ssn', profileData?.data?.ssn);
      setValue('phone', profileData?.data?.phone);
      setValue('birthday', profileData?.data?.dob);
      setBirthday(profileData?.data?.dob);
      setValue('citizenship', profileData?.data?.citizenship);
      setValue('citizenshipStatus', profileData?.data?.citizenshipStatus);
      setCitizenship(profileData?.data?.citizenship);
      setCitizenshipStatus(profileData?.data?.citizenshipStatus);
      if (profileData?.data?.isDocumentVerified) {
        setIsDocumentVerified(true);
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (addressData) {
      setInitialProfileData({ ...initialProfileData, ...addressData?.data });
      setValue('address', addressData?.data?.street);
      setValue('address2', addressData?.data?.street2);
      setValue('city', addressData?.data?.city);
      setValue('stateCode', addressData?.data?.stateCode);
      setValue('zipCode', addressData?.data?.zipCode);
      setValue('countryId', addressData?.data?.countryId);
      setCountryCode(addressData?.data?.countryId);
      setStateCode(addressData?.data?.stateCode);
    }
  }, [addressData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setInitialProfileData({ ...initialProfileData, ...user });
    setValue('firstName', user?.firstName);
    setValue('lastName', user?.lastName);
    setValue('email', user?.email);
  }, []);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ px: 3, pt: 4, pb: 2 }}>
          <Typography variant="h6">General Information</Typography>
          <form onSubmit={onSubmitHandler}>
            <Grid container spacing={2} sx={{ py: 4 }}>
              <Grid item xs={12} sm={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  FIRST NAME
                </Typography>
                <TextField {...register('firstName')} disabled id="firstName" fullWidth name="firstName" required />
                {Boolean(errors['firstName']?.message) && <FormErrorMessage message={errors['firstName']?.message} />}
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  LAST NAME
                </Typography>
                <TextField {...register('lastName')} id="lastName" disabled fullWidth name="lastName" required />
                {Boolean(errors['lastName']?.message) && <FormErrorMessage message={errors['lastName']?.message} />}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  EMAIL ADDRESS
                  {user?.email && (
                    <Chip
                      variant="outlined"
                      size="small"
                      sx={{ borderWidth: '0px', ml: 2, fontSize: '10px' }}
                      icon={<CheckCircleIcon />}
                      color="success"
                      label="Email Verified"
                    />
                  )}
                </Typography>
                <TextField {...register('email')} id="email" fullWidth disabled name="email" type="email" required />
                {Boolean(errors['email']?.message) && <FormErrorMessage message={errors['email']?.message} />}
              </Grid>
              <Grid item xs={12} md={5} lg={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  PHONE
                  {profileData?.data?.phoneConfirmedAt && (
                    <Chip
                      variant="outlined"
                      size="small"
                      sx={{ borderWidth: '0px', ml: 2, fontSize: '10px' }}
                      icon={<CheckCircleIcon />}
                      color="success"
                      label="Phone Verified"
                    />
                  )}
                </Typography>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => {
                    return (
                      <PhoneInput
                        {...field}
                        inputStyle={{ width: '100%', height: '50px', color: '#b9b9b9' }}
                        country={'us'}
                        disabled
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true,
                        }}
                        onChange={(phone: string) => {
                          setValue('phone', '+' + phone);
                        }}
                      />
                    );
                  }}
                />
                {Boolean(errors['phone']?.message) && <FormErrorMessage message={errors['phone']?.message} />}
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  DATE OF BIRTH
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={birthday}
                    disabled={!editMode}
                    onChange={(newValue: Date | null) => handleBirthdayChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...register('birthday')} name="birthday" id="birthday" {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
                {Boolean(errors['birthday']?.message) && <FormErrorMessage message={errors['birthday']?.message} />}
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  CITIZENSHIP/NATIONALITY
                </Typography>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  {...register('citizenship')}
                  id="citizenship"
                  disabled={!editMode}
                  value={citizenship}
                  onChange={(e: any) => handleCitizenshipChange(e?.target?.value)}
                  fullWidth
                  name="citizenship"
                >
                  <MenuItem value=" ">Select a country</MenuItem>
                  {countries ? countries.map(({ name, iso3 }, idx) => (
                    <MenuItem key={idx} value={iso3}>
                      {name}
                    </MenuItem>
                  ))
                    :
                    <MenuItem>Loading</MenuItem>}
                </Select>
                {Boolean(errors['citizenship']?.message) && (
                  <FormErrorMessage message={errors['citizenship']?.message} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  CITIZENSHIP STATUS
                </Typography>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  {...register('citizenshipStatus')}
                  id="citizenshipStatus"
                  disabled={!editMode}
                  value={citizenshipStatus}
                  onChange={(e: any) => handleCitizenshipStatusChange(e?.target?.value)}
                  fullWidth
                  name="citizenshipStatus"
                >
                  <MenuItem value=" ">Select a status </MenuItem>
                  <MenuItem value="US Citizen">US Citizen</MenuItem>
                  <MenuItem value="Non-US Citizen">Non-US Citizen</MenuItem>
                  <MenuItem value="US-Visa Holder">US-Visa Holder</MenuItem>
                  <MenuItem value="LPR">LPR</MenuItem>
                </Select>
                {Boolean(errors['citizenshipStatus']?.message) && (
                  <FormErrorMessage message={errors['citizenshipStatus']?.message} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="black" sx={{ mb: 1 }} variant="caption">
                  SSN/ID Number
                </Typography>
                <TextField {...register('ssn')} disabled={!editMode} id="ssn" fullWidth name="ssn" />
                {Boolean(errors['ssn']?.message) && <FormErrorMessage message={errors['ssn']?.message} />}
              </Grid>
              <Grid container spacing={2} sx={{ py: 4, pl: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">Address Information</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="black" sx={{ mb: 1 }} variant="caption">
                    ADDRESS
                  </Typography>
                  <TextField {...register('address')} disabled={!editMode} id="address" fullWidth name="address" />
                  {Boolean(errors['address']?.message) && <FormErrorMessage message={errors['address']?.message} />}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="black" sx={{ mb: 1 }} variant="caption">
                    ADDRESS 2 (Optional)
                  </Typography>
                  <TextField {...register('address2')} disabled={!editMode} id="address2" fullWidth name="address2" />
                  {Boolean(errors['address2']?.message) && <FormErrorMessage message={errors['address2']?.message} />}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="black" sx={{ mb: 1 }} variant="caption">
                    COUNTRY
                  </Typography>
                  <TextField
                    select
                    id="countryId"
                    disabled={!editMode}
                    fullWidth
                    error={Boolean(errors.countryId?.message)}
                    {...register('countryId')}
                    value={countryCode}
                    onChange={(e: any) => handleCountryIdChange(e?.target?.value)}
                  >
                    {countries ? countries.map(({ name, iso3 }, idx) => (
                      <MenuItem key={idx} value={iso3}>
                        {name}
                      </MenuItem>
                    ))
                      :
                      <MenuItem>Loading</MenuItem>}
                  </TextField>
                  {Boolean(errors['countryId']?.message) && (
                    <FormErrorMessage message={errors['countryId']?.message} />
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="black" sx={{ mb: 1 }} variant="caption">
                    STATE
                  </Typography>
                  <TextField
                    select
                    id="state"
                    disabled={!editMode}
                    {...register('stateCode')}
                    error={Boolean(errors.stateCode?.message)}
                    value={stateCode}
                    onChange={(e: any) => handleStateCodeChange(e?.target?.value)}
                    fullWidth
                  >
                    {stateList ? stateList.map(({ stateCode, name }, idx) => (
                      <MenuItem key={idx} value={stateCode}>
                        {name}
                      </MenuItem>
                    )) :
                      <MenuItem>Loading</MenuItem>}
                  </TextField>
                  {Boolean(errors['stateCode']?.message) && <FormErrorMessage message={errors['stateCode']?.message} />}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="black" sx={{ mb: 1 }} variant="caption">
                    CITY
                  </Typography>
                  <TextField {...register('city')} disabled={!editMode} id="city" fullWidth name="city" />
                  {Boolean(errors['city']?.message) && <FormErrorMessage message={errors['city']?.message} />}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="black" sx={{ mb: 1 }} variant="caption">
                    ZIP/POSTAL CODE
                  </Typography>
                  <TextField {...register('zipCode')} disabled={!editMode} id="zipCode" fullWidth name="zipCode" />
                  {Boolean(errors['zipCode']?.message) && <FormErrorMessage message={errors['zipCode']?.message} />}
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {editMode && (
              <Button onClick={handleClickOnCancelButton} size="medium" variant="contained" sx={{ mr: 1 }}>
                Cancel
              </Button>
            )}
            {!editMode && (
              <Button onClick={() => setEditMode(true)} size="medium" variant="contained" sx={{ mr: 1 }}>
                Edit
              </Button>
            )}
            <LoadingButton
              type="submit"
              loading={isSaveButtonLoading}
              onClick={handleSubmit(onSubmitHandler)}
              disabled={!editMode}
              size="medium"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <DocumentUpload isDocumentVerified={isDocumentVerified} setIsDocumentVerified={setIsDocumentVerified} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ px: 3, pt: 4, pb: 2 }}>
          <Typography variant="subtitle2">Public Profile</Typography>
          <Typography my={2} variant="body1">
            Making your profile public means that anyone on the network will be able to find you.
          </Typography>
          <Switch disabled defaultChecked />
          <Typography variant="h5" my={2} >
            Coming Soon
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ px: 3, pt: 4, pb: 2 }}>
          <Typography variant="subtitle2">Show e-mail addresses</Typography>
          <Typography my={2} variant="body1">
            Showing your e-mail addresses means that anyone on the network will be able to find you.
          </Typography>
          <Switch disabled defaultChecked />
          <Typography variant="h5" my={2} >
            Coming Soon
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <ChangePasswordForm />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ px: 3, pt: 4, pb: 2 }}>
          <Typography variant="subtitle2" color="primary">
            Delete account
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
            <Grid container>
              <Grid item>
                <Typography mt={2} variant="body1">
                By selecting to delete this account, your account will permanently be deactivated!
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }} />
            <Button disabled color="primary" variant="contained">
              Delete
            </Button>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GeneralInformation;
