import {useCartContext} from '@panel/contexts/CartContext';
import useCartTotal from './useCartTotal';
import {ICartProduct} from '@panel/interfaces';
import useAuth from '../useAuth';
import {useEffect} from 'react';
import _ from 'lodash';
import Gun from 'gun';
import 'gun/lib/load';
import 'gun/sea';

const gun = Gun();

const useCartProducts = () => {
  const {user} = useAuth();
  const {products, setProducts} = useCartContext();
  const {updateCartTotal} = useCartTotal();

  const updateQuantitySafely = (
    currentProduct: ICartProduct,
    targetProduct: ICartProduct,
    quantity: number
  ): ICartProduct => {
    if (currentProduct.propertyId === targetProduct.propertyId) {
      return Object.assign({
        ...currentProduct,
        quantity: currentProduct.quantity + quantity,
      });
    } else {
      return currentProduct;
    }
  };

  const updateQuantity = (
    currentProduct: ICartProduct,
    targetProduct: ICartProduct,
    quantity: number
  ): ICartProduct => {
    if (currentProduct.propertyId === targetProduct.propertyId) {
      return Object.assign({
        ...currentProduct,
        quantity: quantity,
      });
    } else {
      return currentProduct;
    }
  };

  const addProduct = async (newProduct: ICartProduct) => {
    let updatedProducts;
    newProduct.propertyImage = newProduct.propertyImage ?? '';
    const isPropertyAlreadyInCart = products.some(
      (product: ICartProduct) => newProduct.propertyId === product.propertyId
    );

    if (isPropertyAlreadyInCart) {
      updatedProducts = products.map((product: ICartProduct) => {
        return updateQuantity(product, newProduct, newProduct.quantity);
      });
    } else {
      updatedProducts = [...products, newProduct];
    }
    setProducts(updatedProducts);
    updateCartTotal(updatedProducts);
    await updateGunDB(updatedProducts);
  };

  const updateGunDB = async (updatedProducts: ICartProduct[]) => {
    const gunDB = gun.get('brixCart' + user?.id);
    updatedProducts.forEach((product: ICartProduct) => {
      if (product.quantity === 0) {
        gunDB.get(product.propertyId).put(null);
      } else {
        const productToUpdate = {
          id: product.propertyId,
          quantity: product.quantity,
          propertyId: product.propertyId,
          propertyName: product.propertyName,
          propertyAddress: product.propertyAddress,
          propertyImage: product.propertyImage,
          currentValue: product.currentValue,
        };
        gunDB.get(product.propertyId).put(productToUpdate);
      }
    });
  };

  const removeProduct = (productToRemove: ICartProduct) => {
    const gunDB = gun.get('brixCart' + user?.id);
    const updatedProducts = products.filter(
      (product: ICartProduct) => product?.propertyId !== productToRemove?.propertyId
    );

    setProducts(updatedProducts);
    updateCartTotal(updatedProducts);
    gunDB.get(productToRemove?.propertyId).put(null);
  };

  const removeSelectedProducts = (productsIdToRemove: string[]) => {
    const gunDB = gun.get('brixCart' + user?.id);
    productsIdToRemove.forEach((propertyId: string) => {
      _.remove(products, (product: ICartProduct) => product.propertyId === propertyId);
      setProducts(products);
      updateCartTotal(products);
      gunDB.get(propertyId).put(null);
    });
  };

  const removeAllProducts = () => {
    removeSelectedProducts(products.map((product: ICartProduct) => product.propertyId));
    setProducts([]);
    updateCartTotal([]);
  };

  const increaseProductQuantity = async (productToIncrease: ICartProduct) => {
    const updatedProducts = products.map((product: ICartProduct) => {
      return updateQuantitySafely(product, productToIncrease, +1);
    });

    setProducts(updatedProducts);
    updateCartTotal(updatedProducts);
    await updateGunDB(updatedProducts);
  };

  const decreaseProductQuantity = async (productToDecrease: ICartProduct) => {
    const updatedProducts = products.map((product: ICartProduct) => {
      return updateQuantitySafely(product, productToDecrease, -1);
    });

    setProducts(updatedProducts);
    updateCartTotal(updatedProducts);
    await updateGunDB(updatedProducts);
  };

  const setProductQuantity = async (productToIncrease: ICartProduct, quantity: number) => {
    const updatedProducts = products.map((product: ICartProduct) => {
      return updateQuantity(product, productToIncrease, quantity);
    });
    setProducts(updatedProducts);
    updateCartTotal(updatedProducts);
    await updateGunDB(updatedProducts);
  };

  useEffect(() => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    gun.get('brixCart' + user?.id).load((data) => {
      const fetchedProducts = Object.values(data)
        // filter out deleted values which will appear as null
        .filter((item) => !!item);

      setProducts(fetchedProducts as ICartProduct[]);
      updateCartTotal(fetchedProducts as ICartProduct[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    products,
    addProduct,
    removeProduct,
    removeSelectedProducts,
    removeAllProducts,
    increaseProductQuantity,
    decreaseProductQuantity,
    setProductQuantity,
  };
};

export default useCartProducts;
