import {Box, Pagination as PaginationMUI} from '@mui/material';
import React, {SetStateAction} from 'react';

const Pagination: React.FC<{setPage: React.Dispatch<SetStateAction<number>>; pageNumber: number}> = ({
  setPage,
  pageNumber,
}) => {
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <Box sx={{my: 4, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <PaginationMUI onChange={handlePageChange} size={'small'} count={pageNumber} showFirstButton showLastButton />
    </Box>
  );
};

export default Pagination;
