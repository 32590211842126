/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
import axios from './axios';

const target = {};
const handler = {
  get(target: { [key: string]: any }, name: string) {
    return Object.assign(
      target,
      ['get', 'delete', 'head'].reduce(
        (o, method) =>
          Object.assign({}, o, {
            async [method](url = '', params = {}) {
              if (typeof url === 'object') {
                params = url;
                url = '';
              }

              return (axios as any)[method](name + url, { params });
            },
          }),
        {}
      ),
      ['post', 'put', 'patch'].reduce(
        (o, method) =>
          Object.assign({}, o, {
            async [method](url = '', body = {}, params = {}) {
              if (typeof url === 'object') {
                params = body;
                body = url;
                url = '';
              }
              return (axios as any)[method](name + url, body, { params });
            },
          }),
        {}
      )
    );
  },
};

const api = new Proxy(target, handler);

export default api;
