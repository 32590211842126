import {createContext, FunctionComponent, ReactElement, useContext, useEffect, useState} from 'react';
import {api} from '@panel/utils';
import {AxiosResponse} from 'axios';
import useAuth from '@panel/hooks/useAuth';

export type MFAProviderParam = { children: ReactElement };

interface MFAProviderValue {
  isEnabled: boolean,
  validatingMFA?: boolean,
  toggleMFA?: () => void
  requestMFACode?: () => void
}

export const MFAContext = createContext<MFAProviderValue>({isEnabled: true});

export const MFAProvider: FunctionComponent<MFAProviderParam> = ({children}: MFAProviderParam) => {
  const {user, isAuthenticated} = useAuth();
  const [isEnabled, setEnabled] = useState<boolean>(true);
  const [validatingMFA, setValidating] = useState<boolean>(false);

  useEffect(() => {
    isAuthenticated && user && loadMFA();
  }, [user]);

  const loadMFA = (): void => {
    setValidating(true);
    api['/mfa'].get(`/status`).then((response: AxiosResponse<{ status: boolean }>) => {
      setEnabled(response.data?.status ?? true);
    }, () => {
      setEnabled(true);
    }).finally(() => {
      setValidating(false);
    });
  };

  const toggleMFA = (): void => {
    api['/mfa'].post(`/toggle`).then((response: AxiosResponse<{ status: boolean }>) => {
      setEnabled(response.data?.status ?? true);
    }, () => {
      setEnabled(true);
    });
  };

  const requestMFACode = () => {

  }

  return (
    <MFAContext.Provider value={{isEnabled, requestMFACode, toggleMFA, validatingMFA}}>
      {children}
    </MFAContext.Provider>
  );
}

export const useMFAContext = () => {
  return useContext(MFAContext);
};

export const MFAConsumer = MFAContext.Consumer;
