import React, {useEffect} from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import {Button, CircularProgress} from '@mui/material';
import useStripeCreditCard from '@panel/hooks/stripe/useStripeCreditCard';

const AddNewCardForm: React.FC<{ onReady: () => void; onSucceed: () => void; }> = ({onReady, onSucceed}) => {
  const {addCreditCard, loading, succeed} = useStripeCreditCard();

  useEffect(() => {
    if (succeed) {
      onSucceed();
    }
  }, [succeed]);

  return (
    <form
      id="payment-form"
      style={{
        width: '30vw',
        minWidth: '300px',
        alignSelf: 'center',
        borderRadius: '7px',
        padding: '40px',
      }}
      onSubmit={addCreditCard}
    >
      <CardElement options={{hidePostalCode: true}}
                   onReady={onReady}
                   id="card-element"/>
      {/* Show any error or success messages */}
      <Button disabled={loading} type="submit"
              fullWidth color="primary" variant="contained" sx={{mt: 3}}>
        Submit
        {loading ? <CircularProgress  size="14px" sx={{ml: '4px'}}/> : ''}
      </Button>
    </form>
  );
};

export default AddNewCardForm;
