import {localStorage} from '@brix/shared-frontend';
import {environment} from '../environments/environment';

/**
 * Download a blob from and create URL to download it.
 * @param path
 * @param data 
 * @styling table - https://www.npmjs.com/package/pdfkit-table
 * @returns 
 * example:
 * {
      title: 'Portfolio',
      headers: ['Country', 'Conversion rate', 'Trend'],
      rows: [
        ['Switzerland', '12%', '+1.12%'],
        ['France', '67%', '-0.98%'],
        ['England', '33%', '+4.44%'],
      ],
    };
 */
export const fileDownload = async (path: string, data: any) => {
  const tokens = JSON.parse(localStorage.get('accessTokens'));

  const myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + tokens.access.token);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  const response = await fetch(`${environment.apiUrl}${environment.apiPrefix}${path}`, requestOptions);
  if (response.status === 201) {
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  } else {
    const error = await response.json();
    throw new Error(error.message);
  }
};
