import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Box, Container, Grid, Typography} from '@mui/material';
import StepperPager from '@panel/components/StepperPager';
import {makeStyles} from '@mui/styles';
import SelectBankAccount from '@panel/views/wallet/BalanceTransfer/SelectBankAccount';
import {RegisteredBankAccountInfo} from '@panel/hooks/stripe/useStripeBankAccount';

interface StepperParams {
  step: number,
  account?: RegisteredBankAccountInfo,
  setStep: Dispatch<SetStateAction<number>>
  submit: (account: RegisteredBankAccountInfo) => void
  setAccount: Dispatch<SetStateAction<RegisteredBankAccountInfo | undefined>>
}

const useStyles = makeStyles(() => ({
  container: {
    pt: {
      xs: 2,
      md: 0,
      lg: 0,
    },
  },
}));

const SelectBankAccountInfo: FC<StepperParams> = ({step, setAccount, setStep, account, submit}: StepperParams) => {
  const {container} = useStyles();
  const [selected, setSelected] = useState<RegisteredBankAccountInfo>();

  const handleNext = () => selected && submit && submit(selected);

  const handlePrevios = () => setStep(--step);

  const onAccountSelected = (account: RegisteredBankAccountInfo) => {
    setAccount(account);
    setSelected(account);
  }

  useEffect(() => {
    if (account) {
      setSelected(account);
    }
  }, [])

  return (
    <>
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <Typography variant="h3" align="center">
          Bank Account
        </Typography>
        <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 4}}>
          Please select your payout Bank Account.
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: 'background.default',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            ml: 'auto',
            mr: 'auto',
          }}
        >
        </Grid>
        <Container maxWidth="sm" className={container}>
          <SelectBankAccount onSelected={onAccountSelected}/>
          <StepperPager label={'Submit'} step={step} canContinue={!!account} next={handleNext} prev={handlePrevios}/>
        </Container>
      </Grid>
    </>
  )
}

export default SelectBankAccountInfo;
