import {Box, Grid, IconButton, Menu, MenuItem, Typography} from '@mui/material';
import {Image} from 'mui-image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useState} from 'react';
import {IPaymentMethod} from '@brix/shared-frontend';

const PaymentItem: React.FC<{
  paymentMethod: IPaymentMethod;
  setDefaultPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  deletePaymentMethod: (paymentMethod: IPaymentMethod) => void;
}> = ({paymentMethod, setDefaultPaymentMethod, deletePaymentMethod}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openPaymentMethodOptiosMenu = Boolean(anchorEl);

  const handleClickOnMoreIcon = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOnSetDefault = (paymentMethod: any) => {
    setDefaultPaymentMethod(paymentMethod);
    setAnchorEl(null);
  };

  const handleClickOnDelete = (paymentMethod: any) => {
    deletePaymentMethod(paymentMethod);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <Grid container spacing={2}>
        <Grid item xs={2} md={2}>
          <Image
            src={
              paymentMethod.type === 'us_bank_account'
                ? '../assets/images/bank.png'
                : paymentMethod.brand === 'visa'
                ? '../assets/images/visa.png'
                : '../assets/images/mastercard.png'
            }
            alt={'BRiX'}
            sx={{pt: 2}}
            duration={50}
            easing="ease-in-out"
            width={'30px'}
            height={'30px'}
            fit="contain"
          />
        </Grid>

        <Grid item xs={5} md={7}>
          <Typography
            variant="body1"
            sx={{textTransform: 'capitalize', fontWeight: 400, pt: 1, fontSize: {xs: '11px', sm: '0.85rem'}}}
          >
            {paymentMethod.brand} {' ****'}
            <span style={{fontWeight: 600}}>{paymentMethod.lastFourDigits}</span>
          </Typography>
          <Typography variant="body1" sx={{fontWeight: 400, fontSize: {xs: '11px', sm: '0.85rem'}}}>
            {'Expiration: ' + (paymentMethod.expirationDate ? paymentMethod.expirationDate : 'N/A')}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          {paymentMethod.isDefault && (
            <Box
              sx={{display: 'flex', justifyContent: 'center', mt: 2, backgroundColor: 'rgba(0,0,0,.2)', width: '100%'}}
            >
              <Typography variant={'body2'} sx={{fontSize: {xs: '11px', sm: '0.85rem'}}}>
                Default
              </Typography>
            </Box>
          )}
        </Grid>
        {!paymentMethod.isDefault && (
          <Grid item xs={1}>
            <IconButton
              aria-label="more"
              aria-controls={openPaymentMethodOptiosMenu ? 'long-menu' : undefined}
              aria-expanded={openPaymentMethodOptiosMenu ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickOnMoreIcon}
              sx={{mt: 1, borderRadius: '50%'}}
            >
              <MoreHorizIcon />
            </IconButton>
          </Grid>
        )}
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={openPaymentMethodOptiosMenu}
          disableScrollLock
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 204,
              width: '20ch',
            },
          }}
        >
          {!paymentMethod.isDefault && (
            <MenuItem onClick={() => handleClickOnSetDefault(paymentMethod)}>Set Default</MenuItem>
          )}
          <MenuItem onClick={() => handleClickOnDelete(paymentMethod)}>Delete</MenuItem>
        </Menu>
      </Grid>
    </Box>
  );
};

export default PaymentItem;
