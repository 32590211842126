import {createContext, FunctionComponent, ReactElement, useContext, useEffect, useState} from 'react';
import {api} from '@panel/utils';
import {AxiosResponse} from 'axios';
import useAuth from '@panel/hooks/useAuth';

export interface FeesType {
  amount: number;
  createAt: string;
  type: string;
  updatedAt: string;
  isPercentage: boolean;
}

export type FeesContextType = {
  fees: FeesType[] | undefined;
}

export const FeesContext = createContext<FeesContextType>({
  fees: [{
    amount: 0,
    createAt: '',
    type: '',
    updatedAt: '',
    isPercentage: false
  }]
});

export const useFees = () => useContext(FeesContext);

export type FeesProviderParam = { children: ReactElement };

interface WalletProviderValue {
  fees: FeesType[],
  setFees?: () => void,
}

export const FeesProvider: FunctionComponent<FeesProviderParam> = ({children}: FeesProviderParam) => {
  const [fees, setFees] = useState([])
  const {user, isAuthenticated} = useAuth();

  useEffect(() => {
    isAuthenticated && user && fetchFees();
  }, [user]);

  const fetchFees = (): void => {
    api['fees'].get(`/`).then((response: AxiosResponse) => {
      setFees(response?.data?.data ?? []);
    });
  };

  const getValues = (): WalletProviderValue => {
    return {
      fees: fees,
    };
  }

  return (
    <FeesContext.Provider
      value={getValues()}>
      {children}
    </FeesContext.Provider>
  );
}

export const useFeesContext = () => {
  return useContext(FeesContext);
};
