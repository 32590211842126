import {Box, Button, Card, Grid, TextField, Typography} from '@mui/material';
import useStripeAch from '@panel/hooks/stripe/useStripeAch';
import {Image} from 'mui-image';
import {useCallback, useState} from 'react';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Stripe from 'stripe';
import {IVerifyAchPayment} from '@panel/interfaces';
import {FormErrorMessage} from '@panel/components';

const AchPaymentMethods = ({onSelected}: { onSelected: (payment: Stripe.PaymentMethod) => void }) => {
  const {addNewAchAccount, paymentMethods, onVerifyACH} = useStripeAch();
  const [selectedCard, setSelectedCard] = useState('');
  const [verify, setVerify] = useState<any>(null);

  const validationSchema = useCallback(() => {
    return Yup.object().shape({
      deposit1: Yup.number()
        .typeError('Amount must be a number')
        .min(0.001, 'Amount must be greater than 0')
        .max(1, 'Amount must be less than 1')
        .required('Required'),
      deposit2: Yup.number()
        .typeError('Amount must be a number')
        .min(0.001, 'Amount must be greater than 0')
        .max(1, 'Amount must be less than 1')
        .required('Required'),
    });
  }, []);

  const {register, handleSubmit, formState, reset} = useForm<IVerifyAchPayment>({
    resolver: yupResolver(validationSchema()),
    mode: 'onChange',
  });
  const {errors} = formState;

  const handleClickOnPaymentMethod = (method: Stripe.PaymentMethod) => {
    setSelectedCard(method.id);
    onSelected(method);
  };

  const onSubmit = async (data: IVerifyAchPayment) => {
    onVerifyACH(data, verify.intentId, verify.userId, verify.id);
  };
  return (
    <>
      {paymentMethods?.filter(p => p.status !== 'canceled')?.map((method: any) => {
        return (
          <Grid key={method.id} item xs={12}>
            <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
              <Card
                sx={{
                  borderStyle: 'solid',
                  borderColor: selectedCard === method.id ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,.15)',
                  borderWidth: 2,
                  px: 3,
                  py: 2,
                  boxShadow: 'none',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  transition: 'all .2s ease-in-out',
                  cursor: 'pointer',
                  '&:hover': {borderColor: 'rgba(0,0,0,1)'},
                }}
                onClick={() => handleClickOnPaymentMethod(method)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4} md={2}>
                    <Image
                      src={'../assets/images/bank.png'}
                      alt={'BRiX'}
                      duration={50}
                      easing="ease-in-out"
                      width={'90%'}
                      height={'90%'}
                      fit="contain"
                    />
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <Typography variant="subtitle1" sx={{fontWeight: 400}}>
                      {method.brand}: **** **** ****
                      <span style={{fontWeight: 600}}>{method.lastFourDigits}</span>
                    </Typography>
                    <Box sx={{display: 'flex', pt: 1}}>
                      {method.status === 'requires_action' && verify?.id !== method.id && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setVerify(method);
                            reset();
                          }}
                        >
                          Require Action
                        </Button>
                      )}
                      {verify && verify?.id === method.id && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Box sx={{display: 'flex'}}>
                            <Box sx={{pr: 1}}>
                              <TextField
                                {...register('deposit1')}
                                name="deposit1"
                                size="small"
                                placeholder="0.01"
                                sx={{width: 60, height: 20}}
                              />
                            </Box>
                            <Box sx={{pr: 1}}>
                              <TextField
                                {...register('deposit2')}
                                name="deposit2"
                                size="small"
                                placeholder="0.01"
                                sx={{width: 60, height: 20}}
                              />
                            </Box>
                            <Button variant="contained" size="small" type="submit">
                              Verify
                            </Button>
                          </Box>
                          {errors.deposit1 && (
                            <FormErrorMessage message={errors?.deposit1.message ? errors.deposit1.message : ''}/>
                          )}
                          {!errors.deposit1 && errors.deposit2 && (
                            <FormErrorMessage message={errors?.deposit2.message ? errors.deposit2.message : ''}/>
                          )}
                        </form>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
          <Card
            sx={{
              borderStyle: 'solid',
              borderColor: 'rgba(0,0,0,.15)',
              borderWidth: 2,
              px: 3,
              py: 1,
              boxShadow: 'none',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              transition: 'all .2s ease-in-out',
              cursor: 'pointer',
              '&:hover': {borderColor: 'rgba(0,0,0,1)'},
            }}
            onClick={addNewAchAccount}
          >
            <Typography sx={{fontWeight: 600, textAlign: 'center'}}>+ Add New ACH Account</Typography>
          </Card>
        </Box>
      </Grid>
    </>
  );
};

export default AchPaymentMethods;
