import React, {useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import {Box} from '@mui/system';
import {Typography} from '@mui/material';

interface InputCodeProps {
  length: number;
  label?: string;
  loading: boolean;
  onComplete: (newCode: string) => void;
}

const InputCode = ({length, label, loading, onComplete}: InputCodeProps) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  const processInput = (e: React.ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[Number(slot)] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs?.current[slot + 1]?.focus();
    }
    if (newCode.every((num) => num !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.key === 'Backspace' && !code[Number(slot)] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs?.current[slot - 1]?.focus();
    }
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
      <Typography sx={{ml: 2}}>{label}</Typography>
      <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
        {code.map((num, idx) => {
          return (
            <input
              style={{
                backgroundImage: 'none',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                textAlign: 'center',
                height: '34px',
                width: '34px',
                borderRadius: '5px',
                margin: '15px 1px 22px',
                border: '2px solid rgba(0,0,0,.1)',
                fontSize: '22px',
              }}
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default InputCode;
