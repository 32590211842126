import {useEffect} from 'react';
import {Box, Button, Card, Container, Grid, Radio, Tooltip, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {IRegistrationWizard} from '@panel/interfaces/registration-wizard';

const investorTypes = [
  {
    label: 'Individual',
    description: 'This would be a single person who is investing.',
    image: '../assets/images/brick-wizard.png',
    disabled: false,
    checked: true,
  },
  {
    label: 'Joint',
    description: 'Joint represents an investor who is more than one person.',
    image: '../assets/images/brick-wizard2.png',
    disabled: true,
    checked: false,
  },
  {
    label: 'Entity',
    description: 'Entity represents a company or corporation that is represented.',
    image: '../assets/images/brick-wizard3.png',
    disabled: true,
    checked: false,
  },
];

const InvestorType = ({nextStep, prevStep, updateUser}: IRegistrationWizard) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: {
          xs: 2,
          md: 0,
          lg: 0,
        },
      }}
    >
      <Card sx={{p: 4, border: 'none', boxShadow: 'none'}}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" align="center">
            Investor Type
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
            How do you want to take ownership?
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {investorTypes.map((investorType, key) => {
            return (
              <Grid key={key} item xs={12} md={4}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Radio checked={investorType.checked} disabled={investorType.disabled}></Radio>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Card
                    sx={{
                      borderStyle: 'solid',
                      borderColor: 'rgba(0,0,0,.15)',
                      borderWidth: 2,
                      boxShadow: 'none',
                      opacity: investorType.disabled ? 0.5 : 1,
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {investorType.label === 'Joint' ? (
                      <img width="90" height="47" alt="brick" src={investorType.image} />
                    ) : (
                      <img width="40" height="47" alt="brick" src={investorType.image} />
                    )}
                    <Typography justifyContent="center" variant={'subtitle2'} sx={{mt: 2}}>
                      {investorType.label}
                    </Typography>
                  </Card>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', mt: 1}}>
                  <Tooltip arrow title={investorType.description}>
                    <InfoIcon color={investorType.disabled ? 'secondary' : 'inherit'} sx={{cursor: 'pointer'}} />
                  </Tooltip>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Box
          sx={{
            flexGrow: 1,
            mt: 4,
            ml: {
              md: 8,
              xs: 1,
            },
            mr: {
              md: 8,
              xs: 1,
            },
          }}
        ></Box>
        <Grid container mt={2} spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              sx={{color: '#000000', borderColor: '#000000'}}
              onClick={prevStep}
              fullWidth
              size="large"
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="primary" onClick={nextStep} fullWidth size="large" type="submit" variant="contained">
              Continue
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default InvestorType;
