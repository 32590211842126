import {Box, Card, Grid, Typography} from '@mui/material';
import {Image} from 'mui-image';
import {useWalletContext} from '@panel/contexts/WalletContext';
import {formatWithDigits} from '@brix/shared-frontend';

const WalletAvatar = <Image src={'../assets/images/wallet.png'}
                            alt={'BRiX'}
                            duration={50}
                            easing="ease-in-out"
                            width={'100%'}
                            height={'32px'}
                            fit="contain"/>;


export type SelectWalletMethod = { selected: boolean, onSelected: VoidFunction }

const SelectWalletMethod = ({selected, onSelected}: SelectWalletMethod) => {

  const {walletBalance} = useWalletContext();

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
      <Card sx={{
        borderStyle: 'solid',
        borderColor: selected ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.15)',
        borderWidth: 2,
        px: 3,
        py: 2,
        boxShadow: 'none',
        width: '100%',
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        transition: 'all .2s ease-in-out',
        cursor: 'pointer',
        '&:hover': {borderColor: 'primary.main'},
      }} onClick={onSelected}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={2}>
            {WalletAvatar}
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography variant="subtitle1" sx={{fontWeight: 400}}>
              {'Current Financial Balance'}
              <span style={{fontWeight: 600, display: 'block', color: '#CC4645'}}>
                {formatWithDigits(walletBalance?.total ?? 0)}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default SelectWalletMethod;
