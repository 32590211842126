import {createContext, useContext} from 'react';

export type SelectSaleTypeContex = {
  saleTypeSelected: string;
  setSaleTypeSelected: (Filter: string) => void;
};

export const SaleTypeContext = createContext<SelectSaleTypeContex>({
  saleTypeSelected: '0',
  setSaleTypeSelected: (SelectSaleTypeContex) => {''},
});
export const useFilter = () => useContext(SaleTypeContext);
