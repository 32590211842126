import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link as RouterLink } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '@panel/utils/api';
import { useQuery } from 'react-query';
import useAuth from '@panel/hooks/useAuth';
import useApi from '@panel/utils/apis';

const CompleteProfileBanner: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [completeProfileBannerIsVisible, setCompleteProfileBannerIsVisible] = useState(false);
  const [requestedInfo, setRequestedInfo] = useState('')
  const [dataPiechart, setDataPiechart] = useState([
    { title: 'Completed', value: 25, color: '#CC4645' },
    { title: 'Incomplete', value: 75, color: '#bdbdbd' },
  ])

  const [verifiedDocument, setVerifiedDocument] = useState(false)
  const [completeAddress, setCompleteAddress] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(false)

  const handleOnClose = () => {
    setCompleteProfileBannerIsVisible(false);
  };

  const { data: profileData, refetch: refetchProfileData } = useQuery(['users', user?.id, 'banner'], () =>
    api['users'].get(`/${user?.id}/profile`)
  );
  const { data: addressData, refetch: refetchAddressData } = useQuery(['users', user?.id, 'banner'], () =>
    api['users'].get(`/${user?.id}/address`)
  );

  const { data: paymentMethodsData, refetch } = useQuery(['payment-method', user?.id, 'banner'], () =>
    api['payment-method'].get('/user/' + user?.id)
  );

  useEffect(() => {
    if (profileData) {

      if (profileData.data.isDocumentVerified !== false) {
        setVerifiedDocument(true)
      } else {
        setCompleteProfileBannerIsVisible(true);
        setRequestedInfo('Go to profile and complete document validation.')
      }

    }

  }, [profileData])

  useEffect(() => {

    if (addressData) {

      if (addressData.data.citizenshipStatus === undefined || addressData.data.citizenshipStatus === null) {
        setCompleteProfileBannerIsVisible(true);
        setRequestedInfo('Go to profile and complete general information.')
      } else {
        setCompleteAddress(true)
      }
    }

  }, [addressData])

  useEffect(() => {

    if (paymentMethodsData) {
      if (paymentMethodsData.data.length > 0) {
        setPaymentMethod(true)
      } else {
        setCompleteProfileBannerIsVisible(true);
        setRequestedInfo('Go to profile and complete payment method.')
      }
    }
  }, [paymentMethodsData]);


  useEffect(() => {
  
    if (verifiedDocument && completeAddress && paymentMethod) {
      setCompleteProfileBannerIsVisible(false);
    } else if (verifiedDocument && completeAddress) {
      setDataPiechart([
        { title: 'Completed', value: 75, color: '#CC4645' },
        { title: 'Incomplete', value: 25, color: '#bdbdbd' },
      ]
      )
    } else if (verifiedDocument && paymentMethod) {
      setDataPiechart([
        { title: 'Completed', value: 75, color: '#CC4645' },
        { title: 'Incomplete', value: 25, color: '#bdbdbd' },
      ]
      )
    } else if (completeAddress && paymentMethod) {
      setDataPiechart([
        { title: 'Completed', value: 75, color: '#CC4645' },
        { title: 'Incomplete', value: 25, color: '#bdbdbd' },
      ]
      )
    } else if (verifiedDocument && !completeAddress && !paymentMethod) {
      setDataPiechart([
        { title: 'Completed', value: 50, color: '#CC4645' },
        { title: 'Incomplete', value: 50, color: '#bdbdbd' },
      ])
    } else if (completeAddress && !verifiedDocument && !paymentMethod) {
      setDataPiechart([
        { title: 'Completed', value: 50, color: '#CC4645' },
        { title: 'Incomplete', value: 50, color: '#bdbdbd' },
      ])
    } else if (paymentMethod && !verifiedDocument && !completeAddress) {
      setDataPiechart([
        { title: 'Completed', value: 50, color: '#CC4645' },
        { title: 'Incomplete', value: 50, color: '#bdbdbd' },
      ])
    } 

  }, [verifiedDocument, completeAddress, paymentMethod])
  



  if (!completeProfileBannerIsVisible) return null;

  return (
    <Card
      sx={{
        background:
          'linear-gradient(135deg,rgba(51,14,14,1) 0%,rgba(20,25,37,1) 25%,rgba(20,25,37,1) 75%,rgba(34,25,3,1) 100%)',
        height: '80px',
        mb: 2,
        px: { xs: 2, sm: 2 },
        pb: 2,
        pt: 1,
        position: 'relative',
      }}
    >
      <IconButton onClick={handleOnClose} sx={{
        position: 'absolute',
        top: '7%',
        right: {
          xs: '12%',
          sm: '2%',
        }
      }}>
        <CloseIcon sx={{ color: 'white' }} />{' '}
      </IconButton>
      <Grid container spacing={6}>
        <Grid item xs={3} md={2} lg={1} sx={{ mt: { xs: '12px', sm: '12px' }, pl: { xs: '0px', sm: '14px' } }}>
          <PieChart
            data={dataPiechart}
            animate
            style={{ width: '60px' }}
            totalValue={100}
            startAngle={270}
            labelPosition={0}
            lineWidth={15}
          />
          <IconButton
            onClick={() => history.push(`/profile`)}
            sx={{ position: 'absolute', ml: '-55px', mt: '7px' }}
          >
            <AccountCircleIcon sx={{ color: 'white', fontSize: '36px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={9} md={9} lg={10}>
          <RouterLink to={'/profile'} style={{ textDecoration: 'initial' }}>
            <Typography
              variant="subtitle2"
              sx={{ color: 'white', fontSize: { xs: '10px', md: '14px' }, mt: { xs: '12px', sm: '20px' } }}
            >
              Complete your profile
            </Typography>

            <Typography variant="body1" sx={{ color: 'white', mt: 1, opacity: 0.7, fontSize: { xs: '10px', md: '12px' } }}>
              Please complete your profile to see our full portfolio as an investor.{' '}
              {requestedInfo}{' '}
              <span style={{ color: '#FC4645', textDecoration: 'underline', fontWeight: 600, cursor: 'pointer' }}>
                Learn more
              </span>
            </Typography>
          </RouterLink>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CompleteProfileBanner;

