import { Box, Container, Grid, Typography } from '@mui/material';

export const TermsOfService = () => {
    return (
        <Typography color="black" variant="subtitle2" sx={{ mb: 2 }}>
            Last updated 2022.07.07<br />

            These TERMS OF SERVICE (as updated from time to time as set forth herein, these “Terms”) set forth
            the legally binding terms and conditions for use of services provided by BRiX Financial Technologies
            Holding Corporation (“we,” “us,” “our,” “BRiX”), as well as our affiliates and subsidiaries, and our
            website (the “Site”), mobile app and/or other platform(s) (collectively, the “Services”) by you and all
            other users or visitors of the Services (each, a “user,” “you” or “your”).
            PLEASE READ THESE TERMS CAREFULLY AS THEY MAY HAVE CHANGED.<br />


            PLEASE BE AWARE THAT THE SITE IS CURRENTLY IN A TESTING PHASE AND NO SERVICES ARE BEING
            PROVIDED. THE SOLE FUNCTIONALITY OF THE SITE AT PRESENT IS TO COLLECT USER PROVIDED EMAIL
            CONTACT DETAILS IN ORDER TO CONTACT PERSONS WHO MAY HAVE INTEREST IN THE SERVICES IN
            THE FUTURE.<br />


            Please be aware that these Terms include, among other things, a binding arbitration provision that
            requires you to submit to binding and final arbitration on an individual basis to resolve disputes,
            rather than a jury trial or class action lawsuit.<br />

            1. Acceptance; Privacy Policy. You must be 18 years or older to use the Services. You acknowledge
            that, by accessing, registering for and/or using, or continuing to access or use, the Services in
            any manner, you (a) are at least 18 years old and have the right, authority and capacity to enter
            into these Terms, either on behalf of yourself or the entity that you represent; (b) agree to and
            accept these Terms, which also incorporate by reference our Privacy Policy (the “Privacy Policy
            ”), each of which may be updated from time to time without notice to you as further set forth
            herein or therein; (c) if these Terms have materially changed since you last access or used the
            Services, acknowledge and agree that your continued access or use of the Services constitutes
            your acceptance of the these Terms as changed; and (d) consent to receive communications
            from us electronically, and agree that such electronic communications, notices and/or postings
            satisfy any legal requirements that such communications be in writing.<br />

            2. DISCLAIMER REGARDING INVESTMENTS AND RELATED CONTENT FOR REAL ESTATE ASSETS.<br />

            a. Disclaimer. Nothing contained in the Services, including, without limitation, any BRiX
            Content (as defined below), constitutes investment, financial, accounting, tax or legal
            advice or should be considered an offer, solicitation of an offer or advice to buy or sell
            securities. Any decisions based on the information contained in the Services are the
            sole responsibility of the user of the Services. The securities made available through or
            discussed on the Services, representing interests in various real estate assets, may not
            be suitable for all investors. BRiX makes no representations that such securities are
            available to or appropriate for investors or users in all jurisdictions or locations, nor
            that any investment vehicle is available or suitable for any particular user or purpose.
            All users accessing the Services do so on their own initiative and are responsible for
            compliance with applicable local laws and regulations.<br />


            b. General Risks. Investing involves risk, including market risks and macro conditions
            such as inflation and political and economic risk, and investments may lose value.
            Before investing, consider your investment objectives, financial resources, experience
            and risk tolerance and fees related to investing through the Services. Past
            performance does not guarantee future results. Investment outcomes and projections
            are hypothetical in nature and cannot be guaranteed. Investing in real estate assets,
            and in securities representing interests in real estate assets, involves higher risks than
            traditional investments and may have different financial and/or tax implications.<br />

            c. By using the Services, you represent and warrant that, with respect to making, or the
            decision to make, an investment in any security made available on or through the
            Services:<br />

            i. You have read and understand the discussion of risks herein, and are aware of
            the risks of any investment in real estate assets or securities representing
            interests in real estate assets;<br />

            ii. You will use your own judgment before making any decision to invest any
            amount of money in any security made available through the Services;<br />

            iii. You are solely responsible for complying with applicable law regarding any
            transaction, including, without limitation, the determination of whether any
            investment complies with the terms of local law (whether the law of a U.S.
            state, or the law of any foreign government with jurisdiction over you);<br />

            iv. You will obtain professional advice as is appropriate to protect your interests,
            including any legal, accounting, financial or other relevant advice;<br />

            v. You understand that BRiX is not acting as an investment advisor or similar in
            relation to securities made available through the Services, providing any advice
            related to such securities and is not, and will not be, in any way responsible for
            the success or failure of any investments made through the Services; and<br />

            vi. You are not relying upon any person or entity other than the underlying issuer
            of an investment.<br />

            3. License; Accessing the Services. Subject to your compliance with these Terms, we grant you a
            limited, non-exclusive, non-sublicensable, revocable, non-transferable license to access and use
            the Services for your personal, noncommercial use. It is a condition of your use of the Services
            that the information you provide is correct, current, and complete. Your use of the Services is at
            your own risk. Any rights not expressly granted herein are reserved by the BRiX and its licensors.
            You are responsible for making all arrangements necessary for you to access the Services. The
            Services shall include, without limitation, any services, applications, extensions, passes or
            widgets offered by us that you download or receive from third-party application stores (e.g., the
            Apple App Store or Google Play), subject to the terms of such stores, or providers authorized by
            us. To use the Services, you may need and are responsible for having compatible hardware,
            software (latest version recommended and sometimes required) and internet access (fees may
            apply, e.g., your carrier&#39;s normal rates and fees, such as data charges and text messaging, will
            still apply). The Services&#39; performance may be affected by these factors. We cannot and do not
            make any representations or warranties with respect to the devices you use to access or use the
            Services, including with respect to device compatibility.<br />

            4. No Guarantee of Service. Although we hope to make the Services available at all times in the
            future, there may be times when we need to disable the Services either temporarily or
            permanently. We reserve the right to discontinue, change, suspend, remove and/or disable
            access to any Service and to impose limits on the use of or access to any Service, in each case at
            any time and without notice or liability. In no event will we be liable for the discontinuance,
            modification, suspension or removal of, or disabling of access to, any such Service at any time
            and/or for any period of time. Also, from time to time, we may restrict access to some parts of
            the Services, or all Services, to registered users.<br />

            5. Account Registration. Some or all of the Services, and certain features or functionalities, may
            require that you register for an account with us. When you do, we may ask you to provide
            certain registration details, such as your email address or other information about yourself. In
            the event BRiX provides the option to register for the Services through your account on a third-
            party platform (such as Facebook or Google), you authorize BRiX to access such account as
            permitted under the applicable terms and conditions that govern your use of such account
            (including accessing and using your account credentials and other information shared from the
            third-party platform account as authorized by you). All such information is subject to our Privacy
            Policy. You agree that the information that you provide us about yourself upon registration is
            true, accurate, current and complete. You may not impersonate someone else to create an
            account, create or use an account for anyone other than yourself; permit anyone else to use
            your account; or provide personal information for purposes of account registration other than
            your own. In order to ensure we can protect and properly administer the Services and our
            community of users, we have the right to disable or close any user account at any time and for
            any or no reason.<br />

            6. Account Security. Once you have an account, you are responsible for all activities that occur in
            connection with your account. You will treat as confidential your account access credentials and
            will not disclose such credentials to any third party. You agree to immediately notify us if you
            have any reason to believe that your account credentials have been compromised or that there
            is, was, may be or may have been any unauthorized use of your account or password, or any
            other breach of security. We ask that you use particular caution when accessing your profile
            from a public or shared computer, or when using your account in a public space, such as a cafe
            or public library, so that others are not able to view or record your access credentials or other
            personal information.<br />

            7. Prohibited Uses. You may use the Services only for lawful purposes and in accordance with
            these Terms. We are under no obligation to enforce the Terms on your behalf against another
            user. We encourage you to let us know if you believe another user has violated the Terms or
            otherwise engaged in prohibited or illegal conduct. You agree not to, and will not assist,
            encourage or enable others to:<br />

            a. Use the Services:<br />

            i. For any commercial purpose, except as expressly permitted under these Terms;<br />

            ii. In a manner that is defamatory, abusive, threatening, offensive, an invasion of a
            right of privacy of another person or could otherwise be reasonably expected to
            harm any person or entity;<br />

            iii. To violate any applicable national, regional, federal, state, local or international
            law or regulation, or to encourage any such violation or any illegal activity;<br />

            iv. To create, send, knowingly receive, display, transmit, use or reuse any material
            which:<br />

            1. Infringes any patent, trademark, trade secret, copyright or other
            intellectual property right(s) of any other person;<br />

            2. Violates, or assists in the violation of, the legal rights (including the
            rights of publicity and privacy) of others or contains any material that
            could give rise to any civil or criminal liability under applicable laws or
            regulations, or that otherwise may be in conflict with these Terms or
            our Privacy Policy; or<br />

            3. Is likely to deceive or confuse any person;<br />

            v. To violate these Terms or any other rules or policies posted by us on our
            website or otherwise provided to you;<br />

            vi. To transmit any computer viruses, worms, defects, Trojan horses or other items
            of a destructive nature; or<br />

            vii. To violate the security of any computer network or crack passwords or security
            encryption codes;<br />

            b. Reverse engineer, decompile, reverse assemble or perform any other operation on or
            with the Services that would reveal any source code, trade secrets, know-how or other
            proprietary information;<br />

            c. Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or
            adaptations of, publicly display, sell, trade or in any way exploit the Services, except as
            expressly authorized by BRiX;<br />

            d. Remove or modify any copyright, trademark or other proprietary rights notice that
            appears on any portion of the Services or on any materials printed or copied from the
            Services;<br />

            e. Record, process, harvest, collect or mine information about other users;<br />

            f. Access, retrieve or index any portion of the Services for purposes of constructing or
            populating a searchable database;<br />

            g. Use any robot, spider or other automatic device, process or means to access the
            Services for any purpose;<br />

            h. Remove, circumvent, disable, damage or otherwise interfere with any security-related
            features or other restrictions of the Services;<br />

            i. Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of
            the Services, the server on which the Services is stored or any server, computer or
            database connected to the Services;<br />

            j. Attack the Services via a denial-of-service, distributed denial-of-service or other form of
            attack; and/or<br />

            k. Otherwise attempt to interfere with the proper working of the Services.<br />

            8. The restrictions above only apply to the extent permissible under applicable law. Nevertheless,
            you agree not to act contrary to them (even if permissible under applicable law) without
            providing 30 days&#39; prior written notice to us info@realtybrix.com, together with any information
            that we may reasonably require to give us an opportunity to provide alternative remedies or
            otherwise accommodate you at our sole discretion.<br />

            9. IP Ownership. We own the Services and all of our trademarks, logos, branding and any other
            Content that we create in connection with the Services (“BRiX Content”), including, without
            limitation, current and future proprietary rights of every kind and nature, however denominated
            throughout the world, registered or unregistered, associated with such BRiX Content and the
            Services (collectively, “BRiX IP”). Except as expressly provided herein, we do not grant you any
            express or implied rights, and all rights in and to the BRiX IP are retained by us. We are not
            responsible or liable to any third party for the content or accuracy of any materials shared by
            you or any other user through the Services. You understand that when using the Services, you
            may be exposed to text, images, photos, audio, video, location data and/or other forms of data
            or communication (“Content”) from a variety of sources, and that BRiX is not responsible for the
            accuracy, usefulness, safety or intellectual property rights of or relating to such Content. We do
            not endorse any Content made available through the Services by any user or other licensor, or
            any opinion, recommendation or advice expressed therein, and we expressly disclaim any and
            all liability in connection with such Content.<br />

            10. Feedback. All feedback, comments, requests for technical support and other communications
            relating to the Services should be directed to info@realtybrix.com. By sending us any ideas,
            suggestions, documents and/or proposals (“Feedback”), you agree that (a) the Feedback does
            not contain the confidential or proprietary information of third parties; (b) we are under no
            obligation of confidentiality, express or implied, with respect to the Feedback; (c) we may have
            something similar to the Feedback already under consideration or in development that is owned
            by us; and (d) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide
            license to use, modify, prepare derivative works, publish, distribute and/or sublicense the
            Feedback, and you irrevocably waive, and agree to cause to be waived, against BRiX and its
            users any claims and assertions of any moral rights contained in such Feedback.<br />

            11. Links to other Sites. If there are other websites and/or resources linked to on the Services,
            these links are provided only for the convenience of our users. We have no control over the
            contents of those websites or resources, and therefore cannot accept responsibility for them or
            for any loss or damage that may arise from your use of them. If you decide to access any of the
            third-party websites linked to the Services, you do so entirely at your own risk and subject to the
            privacy policies and/or terms and conditions of use for such websites.<br />

            12. NO WARRANTY. BRIX HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, QUIET ENJOYMENT, ACCURACY AND/OR FITNESS FOR
            PARTICULAR PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
            SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. BRiX DOES NOT
            GUARANTEE THE ACCURACY, TIMELINESS, COMPLETENESS OR USEFULNESS OF THE SERVICES OR
            ANY CONTENT. YOU AGREE TO USE THE SERVICES AT YOUR OWN RISK. BRiX DOES NOT
            WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE SERVICES, THAT THE
            FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY OR THROUGH, THE
            SERVICES WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE SERVICES WILL BE
            UNINTERRUPTED OR ERROR-FREE AND/OR THAT DEFECTS IN THE SERVICES WILL BE
            CORRECTED. WITHOUT LIMITING THE FOREGOING, BRiX DISCLAIMS ANY WARRANTIES ARISING
            FROM TRADE USAGE, COURSE OF DEALING OR COURSE OF PERFORMANCE. NO ORAL OR
            WRITTEN INFORMATION OR ADVICE GIVEN BY BRiX OR ITS AUTHORIZED REPRESENTATIVE(S)
            SHALL CREATE A WARRANTY. IF YOU ARE DISSATISFIED WITH THE SERVICES OR ANY OF THESE
            TERMS, YOUR SOLE REMEDY IS TO DISCONTINUE USING THE SERVICES. SOME JURISDICTIONS
            DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE
            STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT
            APPLY TO YOU.<br />

            13. LIMITATION OF LIABILITY. YOU ACKNOWLEDGE THAT YOUR ACCESS TO AND USE OF THE
            SERVICES AND ANY RELIANCE UPON EITHER ARE AT YOUR SOLE RISK. TO THE MAXIMUM
            EXTENT PERMITTED BY LAW, IN NO EVENT SHALL BRiX OR ANY OF ITS OWNERS, MANAGERS,
            OFFICERS, DIRECTORS, MEMBERS, SHAREHOLDERS, AFFILIATES, SUBSIDIARIES, EMPLOYEES,
            CONTRACTORS, AGENTS, REPRESENTATIVES OR LICENSORS BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT
            LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION
            AND/OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION
            WITH THE SERVICES, WHETHER OR NOT BRiX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, ON ANY THEORY OF LIABILITY (INCLUDING CONTRACT, TORT NEGLIGENCE OR
            OTHERWISE) ARISING OUT OF, IN CONNECTION WITH OR RESULTING FROM THE SERVICES. YOU
            SPECIFICALLY ACKNOWLEDGE THAT DOWNTIME AND COMPUTER VIRUSES ARE RISKS INHERENT
            IN THE USE OF THE INTERNET AND SOFTWARE PRODUCTS, AND YOU AGREE TO ASSUME
            RESPONSIBILITY FOR ANY HARM OR DAMAGES OF ANY KIND OR CHARACTER WHATSOEVER
            RESULTING FROM THESE POSSIBLE HARMS. YOU ALSO SPECIFICALLY ACKNOWLEDGE THAT YOU
            MAY BE DISCLOSING SENSITIVE, PRIVATE AND CONFIDENTIAL INFORMATION ABOUT YOURSELF
            IN YOUR USE OF THE SERVICES AND YOU AGREE TO ASSUME RESPONSIBILITY FOR ANY HARM
            OR DAMAGES OF ANY KIND OR CHARACTER WHATSOEVER RESULTING FROM YOUR RELEASE OF
            SUCH CONTENT. IN NO EVENT SHALL THE COMPANY&#39;S TOTAL LIABILITY TO YOU FOR ALL
            DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING
            PERSONAL INJURY) EXCEED THE GREATER OF (A) THE AMOUNT PAID BY YOU FOR THE SERVICES
            IN THE SIX (6) MONTHS PRECEDING THE APPLICABLE CLAIM, IF ANY, OR (B) ONE HUNDRED
            DOLLARS ($100.00). IT IS THE INTENTION OF YOU AND US THAT THIS PROVISION BE CONSTRUED
            BY A COURT AS BEING THE BROADEST LIMITATION OF LIABILITY CONSISTENT WITH APPLICABLE
            LAW. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF INCIDENTAL,
            CONSEQUENTIAL OR OTHER TYPES OF DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY
            NOT APPLY TO YOU. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE-STATED
            REMEDY FAILS OF ITS ESSENTIAL PURPOSE.<br />


            14. Indemnification. You agree to indemnify and hold harmless BRiX and its subsidiaries, affiliates
            and licensors and their respective owners, managers, officers, directors, members,
            shareholders, employees, contractors, agents, licensors and suppliers from and against any
            claims, liabilities, damages, judgments, awards, losses, costs, expenses and/or fees (including
            reasonable attorneys&#39; fees) resulting from (a) your violation of these Terms; (b) your use or
            access of the Services; (c) your violation of any third-party right, including, without limitation,
            any intellectual property right or right of publicity; (d) any claim that your use of the Services
            caused damage to a third party; or (e) from any decision that you make based on or through, as
            applicable, the Services, Content or any other investment vehicle made available via the
            Services.<br />

            15. Governing Law. These Terms, and the license granted herein, shall be governed by the laws of
            the State of Florida, without respect to its conflict of laws principles. You agree to submit to the
            personal jurisdiction of the federal and state courts located in Miami-Dade County, Florida. Your
            use of the Services may also be subject to other local, state, national or international laws.<br />

            16. Arbitration. READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE
            THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM BRiX. For any
            dispute with BRiX, you agree to first contact us at info@realtybrix.com and attempt to resolve
            the dispute with us informally. In the unlikely event that BRiX has not been able to resolve a
            dispute after sixty (60) days, we each agree to resolve any claim, dispute or controversy
            (excluding any claims for injunctive or other equitable relief as provided below) arising out of, in
            connection with or relating to these Terms, or the breach or alleged breach hereof (collectively,
            “Claims”), by binding arbitration by Third Party Arbitration Company, under the “Optional
            Expedited Arbitration Procedures” then in effect for Third Party Arbitration Company, except as
            provided herein. The arbitration will be conducted in Miami-Dade County, Florida, unless you
            and BRiX agree otherwise. If you are using the Services for commercial purposes, each party will
            be responsible for paying any Third Party Arbitration Company filing, administrative and/or
            arbitrator fees in accordance with Third Party Arbitration Company rules, and the award
            rendered by the arbitrator shall include costs of arbitration, reasonable attorneys&#39; fees and
            reasonable costs for expert and other witnesses. If you are an individual using the Services for
            non-commercial purposes: (a) Third Party Arbitration Company may require you to pay a fee for
            the initiation of your case, unless you apply for and successfully obtain a fee waiver from Third
            Party Arbitration Company; (b) the award rendered by the arbitrator may include your costs of
            arbitration, your reasonable attorneys&#39; fees and your reasonable costs for expert and other
            witnesses; and (c) you may sue in a small claims court of competent jurisdiction without first
            engaging in arbitration, but this does not absolve you of your commitment to engage in the
            informal dispute resolution process. Any judgment on the award rendered by the arbitrator may
            be entered in any court of competent jurisdiction. Nothing in this section shall be deemed as
            preventing BRiX from seeking injunctive or other equitable relief from the courts as necessary to
            prevent the actual or threatened infringement, misappropriation or violation of our data
            security, intellectual property rights or other proprietary rights.<br />

            17. CLASS ACTION &amp; JURY TRIAL WAIVERS. WITH RESPECT TO ALL PERSONS AND ENTITIES,
            REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED THE SERVICES FOR PERSONAL,
            COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES&#39;
            INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
            ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER
            REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS
            WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON&#39;S
            CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND BRiX ARE EACH
            WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE
            ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING OF
            ANY KIND.<br />

            18. Changes to these Terms. We reserve the right to modify these Terms, and to add new or
            additional terms or conditions on your use of the Services, for any reason, without notice, at any
            time. Such modifications and additional terms and conditions will be effective immediately and
            incorporated into these Terms. Your continued use of the Services will be deemed acceptance
            hereof. We will notify you of material changes by email or posting a notice on our website prior
            to the effective date of such changes. What constitutes “material changes” will be determined
            at our sole discretion, in good faith and using common sense and reasonable judgment.<br />

            19. Termination. BRiX may terminate your access to all or any part of the Services at any time,
            with or without cause, with or without notice, effective immediately. If you wish to terminate
            your registration and account, you may do so at any time by contacting us at
            info@realtybrix.com. Upon any termination, all rights and obligations of the parties shall
            cease, and you shall immediately cease using the Services, except that (a) all obligations that
            accrued prior to the effective date of termination, (b) all remedies for breach of these Terms
            and (c) the provisions of Sections 8, 9, 11-15 and 17-19 shall survive.<br />

            20. Payment Terms. We may process electronic funds transfers (“EFTs”) as a method to receive
            electronic deposits from users, to purchase and redeem investments, to pay dividends and
            interest, and to issue refunds to users. EFTs are processed through an automated clearing house
            (ACH) and Fedwire. EFTs allow you to participate in, by purchasing securities, offerings by our
            affiliates. In order to use this payment functionality, you authorize us to share your identity and
            bank account data with a third party, a broker-dealer registered with the SEC and member
            FINRA and SIPC, and with our third party for the purpose of processing your EFTs, and you are
            responsible for the accuracy and completeness of that data. You understand that you will access
            and are responsible for managing your bank account data through the Services. You also
            authorize BRiX to process EFTs as you direct through this Services. You acknowledge that BRiX
            will not be liable for any loss, expense or cost arising out of EFT services provided through your
            use of the Services, which EFTs are based on your instruction.<br />

            21. General Terms. These Terms constitute the sole and entire agreement between you and BRiX
            with respect to the Services and supersede all prior and contemporaneous understandings,
            agreements, representations and warranties, both written and oral, with respect to the Services.
            No waiver of these Terms by BRiX shall be deemed a further or continuing waiver of such term
            or condition or any other term or condition, and any failure of BRiX to assert a right or provision
            under these Terms shall not constitute a waiver of such right or provision. If any provision of
            these Terms is held by a court of competent jurisdiction to be invalid, illegal or unenforceable
            for any reason, such provision shall be modified to reflect the parties&#39; intention or eliminated to
            the minimum extent such that the remaining provisions of the Terms will continue in full force
            and effect. The Terms, and any rights or obligations hereunder, are not assignable, transferable
            or sublicensable by you except with our prior written consent, but may be assigned or
            transferred by us without restriction. Any attempted assignment by you shall violate these
            Terms and be void. The section titles in these Terms are for convenience only and have no legal
            or contractual effect. You hereby grant BRiX the right to take steps we believe are reasonably
            necessary or appropriate to enforce and/or verify compliance with any part of these Terms. You
            agree that we have the right, without liability to you, to disclose any data and/or information to
            law enforcement authorities, government officials and/or a third party, as we believe is
            reasonably necessary or appropriate to enforce and/or verify compliance with any part of these
            Terms (including, but not limited to, our right to cooperate with any legal process relating to
            your use of the Services, and/or a third-party claim that your use of the Services is unlawful
            and/or infringes such third party&#39;s rights). In the event of loss or theft of any device on which the
            Services linked to your account is installed or connected, or you otherwise learn that your
            account is compromised, you shall notify us immediately by contacting us at
            info@realtybrix.com.<br />

            22. Contact Us. We welcome your questions and comments about these Terms. Please contact us
            via email at info@realtybrix.com.<br />
        </Typography>
    );
};
