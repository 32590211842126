import {useState} from 'react';
import ProgressHeader from './ProgressHeader';
import AccountDetails from './AccountDetails';
import InvestorType from './InvestorType';
import PersonalInformation from './PersonalInformation';
import Congratulations from './Congratulations';
import Review from './Review';
import {RegisterProvider} from '@panel/contexts/RegisterContext';
import {RegisterWizardProps} from '@panel/interfaces';

const RegistrationWizard = () => {
  const [user, setUser] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [direction, setDirection] = useState<string>('forward');

  const next = () => {
    if (currentStep >= 5) {
      setCurrentStep(5);
    } else {
      setCurrentStep(currentStep + 1);
    }
    setDirection('forward');
  };

  const prev = () => {
    if (currentStep <= 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
    setDirection('backward');
  };

  const updateUser = (data: RegisterWizardProps) => {
    Object.assign({...user}, {...data});
    setUser({...user, ...data});
  };

  return (
    <RegisterProvider>
      <ProgressHeader currentStep={currentStep}/>
      {currentStep === 1 && <AccountDetails nextStep={next} prevStep={prev} updateUser={updateUser}/>}
      {currentStep === 2 && <InvestorType nextStep={next} prevStep={prev} updateUser={updateUser}/>}
      {currentStep === 3 && (
        <PersonalInformation nextStep={next} prevStep={prev} updateUser={updateUser} direction={direction}/>
      )}
      {currentStep === 4 && <Review prevStep={prev} nextStep={next} updateUser={updateUser}/>}
      {currentStep === 5 && <Congratulations/>}
    </RegisterProvider>
  );
};

export default RegistrationWizard;
