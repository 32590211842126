import logo from '../assets/svg/logo-demo.svg';

interface LogoProps {
  width: number;
  height: number;
}

const Logo = (props: LogoProps) => {
  return <img alt="Logo" src={logo} {...props} />;
};

export default Logo;
