/* eslint-disable @typescript-eslint/no-explicit-any */
import {yupResolver} from '@hookform/resolvers/yup';
import {Alert, Box, Button, FormHelperText, TextField, Link} from '@mui/material';
import {useLocation, useHistory} from 'react-router-dom';
import qs from 'qs';
import api from '@panel/utils/api';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {useMutation} from 'react-query';

interface IData {
  password: string;
  token: string;
  confirmPassword: string;
}

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Password requires to be at least 8 characters long').required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const ResetPasswordForm = ({...rest}) => {
  const history = useHistory();
  const location = useLocation();
  const token = qs.parse(location.search, {ignoreQueryPrefix: true})['token'] || null;
  if (!token) {
    history.push('/');
  }

  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState(false);

  const {register, handleSubmit, formState} = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const {errors} = formState;

  const mutation = useMutation(
    ({token, password, confirmPassword}: IData): Promise<any> =>
      api['auth'].post(`/reset-password`, {password, resetToken: token, confirmPassword}),
    {
      onSuccess: ({status}) => {
        setSuccess(status === 200);
      },
      onError: () => {
        setServerError(true);
      },
    }
  );
  const onSubmit = async (data: any) => {
    setSuccess(false);
    setServerError(false);
    mutation.mutate(data as IData);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
      {!success && (
        <>
          <input type="hidden" {...register('token')} defaultValue={token?.toString()} />
          <TextField
            {...register('password')}
            error={Boolean(errors?.['password'])}
            fullWidth
            helperText={errors?.['password']?.message}
            label="Password"
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
          />
          <TextField
            {...register('confirmPassword')}
            error={Boolean(errors?.['confirmPassword'])}
            fullWidth
            helperText={errors?.['confirmPassword']?.message}
            label="Confirmation"
            margin="normal"
            name="confirmPassword"
            type="password"
            variant="outlined"
          />
          {serverError && (
            <Box mt={3}>
              <FormHelperText error>Unknown server error. Please, try again later.</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="primary"
              disabled={formState.isSubmitting || success}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Reset Password
            </Button>
          </Box>
        </>
      )}
      <Box mt={2}>
        {success && (
          <Alert severity="success">
            Password has been reset successfully. You can now <Link href="/login">login</Link> with your new password.
          </Alert>
        )}
      </Box>
    </form>
  );
};

export default ResetPasswordForm;
