import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Box, Card, Container, Grid, TextField, Typography} from '@mui/material';
import StepperPager from '@panel/components/StepperPager';
import {Image} from 'mui-image';
import {makeStyles} from '@mui/styles';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {formatPrice} from '@brix/shared-frontend';
import {useFees} from '@panel/contexts/FeesContext';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(255, 255, 255)",
      fontSize: '1.4rem',
      fontWeight: 'bold',
      border: '0',
    },
    "& .MuiFilledInput-root:hover": {
      background: "rgb(255, 255, 255)",
      border: '0',
    },
    "& .MuiFilledInput-root.Mui-focused": {
      background: "rgb(255, 255, 255)",
      border: '0',
    }
  },
  box: {
    marginTop: '60px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    pt: {
      xs: 2,
      md: 0,
      lg: 0,
    },
  },
  card: {
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,.50)',
    borderWidth: 2,
    boxShadow: 'none',
    opacity: 1,
    padding: '1.6em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const ResaleSchema = Yup.object().shape({
  balance: Yup.number()
    .typeError('Balance to transfer must be a number')
    .min(0, 'Balance to transfer must be more than $0.00')
    .required('This field is required'),
});

interface StepperParams {
  step: number,
  setStep: Dispatch<SetStateAction<number>>
  balance: number,
  setBalance: Dispatch<SetStateAction<number>>
}

const SelectAmountToTransfer: FC<StepperParams> = ({step, setStep, balance, setBalance}: StepperParams) => {

  const {fees} = useFees();
  const handleNext = () => setStep(++step);
  const handlePrevios = () => setStep(--step);
  const {box, root, card, container} = useStyles();
  const [feeAmount, setFeeAmount] = useState(0);

  const {register, handleSubmit, setValue, getValues, watch} = useForm<{ balance: number }>({
    resolver: yupResolver(ResaleSchema),
    mode: "all",
  });

  const handleSetBalanceToTransfer = () => {
    setBalance(getValues('balance'));
    handleNext();
  }

  useEffect(() => {
    if (getValues('balance') !== balance) {
      setBalance(getValues('balance'));
    }
  }, [watch('balance')]);

  useEffect(() => {
    setValue('balance', balance);
  }, []);

  useEffect(() => {
    setFeeAmount(Number(fees?.find(f => f.type === 'transferFee')?.amount ?? 0));
  }, [fees]);

  const canContinue = (): boolean => {
    return getValues('balance') !== undefined && getValues('balance') >= 100 && 100 <= Number(balance);
  }

  return (
    <>
      <Box className={box}>
        <Typography variant="h3" align="center">
          Transfer Details
        </Typography>
        <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 4}}>
          Please, select the balance you would like to transfer to your financials.
        </Typography>
      </Box>
      <Container maxWidth="sm" className={container}>
        <Card sx={{p: 4, border: 'none', boxShadow: 'none'}}>
          <Box>
            <form onSubmit={handleSubmit(handleSetBalanceToTransfer)}>
              <Card className={card}>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Image
                      src={'../assets/images/money-icon.png'}
                      alt={'BRiX'}
                      duration={10}
                      height={'40px'}
                      fit="contain"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Box sx={{mt: '-20px', display: 'flex', alignItems: 'flex-end',}}>
                      <sup className={'label-sup'}>
                        $
                      </sup>
                      <TextField
                        {...register('balance')}
                        type="text"
                        className={root}
                        sx={{backgroundColor: '#fff'}}
                        inputMode="numeric"
                        fullWidth variant="filled"/>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </form>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Box display={'inline-flex'} sx={{float: 'right'}} alignItems={'end'}>
                <Typography color="black" align="right" variant="body1" sx={{mt: 2}}>
                  Minimal transfer amount:
                </Typography>
                <Typography color="primary" align="right" variant="body1" sx={{mt: 2, ml: 1}}>
                  {formatPrice(100)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'inline-flex'} sx={{float: 'right'}} alignItems={'end'}>
                <Typography color="black" align="right" variant="body1">
                  Transfer Fee:
                </Typography>
                <Typography color="primary" align="right" variant="body1" sx={{ml: 1}}>
                  {formatPrice(feeAmount)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <StepperPager step={step} canContinue={canContinue()}
                        next={handleSetBalanceToTransfer} prev={handlePrevios}/>
        </Card>
      </Container>
    </>
  )
}

export default SelectAmountToTransfer;
