import { Box, Container, Grid, Typography, Button } from '@mui/material';
import { formatWithDigits } from '@brix/shared-frontend';
import { useHistory } from 'react-router-dom';
import Insights from './Insights';


const CurrentWalletBalance = (props: any) => {
  const history = useHistory();

  const { balance, insights } = props;

  return (
    <Container
      sx={{
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        ml: 0,
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item md={12} lg={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '15px',
              marginTop: '10px',
              marginLeft: '30px',
              marginRight: '30px',
              marginBottom: '30px',
              padding: '10px',
              paddingBottom: '10px',
            }}
          >
            <Typography variant="body2" fontFamily={'Poppins'} fontSize={18}>
              Current Financial Balance
            </Typography>
            <Typography variant="body2" fontFamily={'Poppins'} fontSize={40}>
              {formatWithDigits(balance)} <span style={{ fontSize: '14px', color: 'gray', fontFamily: 'Poppins' }}>Available</span>{' '}
            </Typography>
            <Button
              variant="contained"
              disabled={balance <= 0}
              onClick={() => history.push('/transfer/from-wallet')}
              color="primary"
              sx={{
                padding: ' 16px 32px',
                gap: '6px',
                height: '32px',
                width: { xs: '100%', md: '40%', lg: '100%' },
                borderRadius: '5px',
              }}
            >
              Transfer to my bank
            </Button>
            <Button variant="contained"
              onClick={() => history.push('/transfer/from-account')}
              color="success"
              sx={{
                padding: ' 16px 32px',
                gap: '6px',
                height: '32px',
                width: { xs: '100%', md: '40%', lg: '100%' },
                borderRadius: '5px',
              }}
            >
              Transfer from my bank
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} lg={7}>
          <Insights
            insights={insights}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CurrentWalletBalance;
