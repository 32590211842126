import {useCartContext} from '@panel/contexts/CartContext';
import useCartProducts from './useCartProducts';
import useCartTotal from './useCartTotal';
import 'gun/lib/load';
import 'gun/sea';


const useCart = () => {
  const {isOpen, setIsOpen} = useCartContext();
  const {
    products,
    addProduct,
    removeProduct,
    removeSelectedProducts,
    removeAllProducts,
    increaseProductQuantity,
    decreaseProductQuantity,
    setProductQuantity,
  } = useCartProducts();
  const {total, updateCartTotal} = useCartTotal();

  const openCart = () => setIsOpen(true);
  const closeCart = () => setIsOpen(false);

  return {
    isOpen,
    openCart,
    closeCart,
    products,
    addProduct,
    removeProduct,
    removeSelectedProducts,
    removeAllProducts,
    increaseProductQuantity,
    decreaseProductQuantity,
    setProductQuantity,
    total,
    updateCartTotal,
  };
};

export default useCart;
