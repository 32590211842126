import { useContext } from 'react';
import { MenuItem, Typography } from '@mui/material';
import { SaleTypeContext } from '@panel/components/SaleTypeContext';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const SaleTypeMenu = () => {
    const { saleTypeSelected, setSaleTypeSelected } = useContext(SaleTypeContext);

    const handleChange = (event: SelectChangeEvent) => {
        setSaleTypeSelected(event.target.value as string);
    };

    return (
        <>
            <Typography color="gray" variant="subtitle2" sx={{ fontWeight: 600, fontSize: '11px', mb: 2 }}>
                SALE TYPE
            </Typography>
            <Select
                onChange={handleChange}
                value={saleTypeSelected}
                MenuProps={{ disableScrollLock: true }}
                sx={{ minWidth: '175px', backgroundColor: 'white', bottom: 5, mb: 2 }}
            >
                <MenuItem sx={{ color: 'black' }} value='0'>
                    Pre-sale
                </MenuItem>
                <MenuItem sx={{ color: 'black' }} value='1'>
                    Offered
                </MenuItem>
                <MenuItem sx={{ color: 'black' }} value='2'>
                    Open-market
                </MenuItem>
            </Select>
        </>
    );
};

export default SaleTypeMenu;
