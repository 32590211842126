import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import {IPortfolioInsights, IResponseAPI, ITableState} from '@brix/shared-frontend';
import {IPortfolio} from '@panel/interfaces';
import {api} from '@panel/utils';
import {AxiosResponse} from 'axios';
import useAuth from '@panel/hooks/useAuth';

export type PortfolioProviderParam = { children: ReactElement };

interface PortfolioProviderValue {
  total?: number,
  insightsData?: IPortfolioInsights,
  needsToRefetch?: () => void,
  isInsightLoading?: boolean,
  isPortfolioLoading?: boolean,
  portfolios: Array<IPortfolio>,
  tableState?: Omit<ITableState, 'search'>
  setTableState?: Dispatch<SetStateAction<any>>
}

export const PortfolioContext = createContext<PortfolioProviderValue>({portfolios: []});

export const PortfolioProvider: FunctionComponent<PortfolioProviderParam> = ({children}: PortfolioProviderParam) => {
  const {user, isAuthenticated} = useAuth();
  const [portfolios, setPortfolios] = useState<IResponseAPI<IPortfolio>>();
  const [insightsData, setInsightsData] = useState<IPortfolioInsights>();
  const [isPortfolioLoading, setIsPortfolioLoading] = useState(false);
  const [isInsightLoading, setIsInsightLoading] = useState(false);

  useEffect(() => {
    isAuthenticated && user && needsToRefetch();
  }, [user]);

  const [tableState, setTableState] = useState<Omit<ITableState, 'search'>>({
    limit: 10,
    page: 0,
    sort: 'createdAt,DESC',
  });

  const needsToRefetch = () => {
    loadPortfolio();
    loadInsights();
  }

  const loadPortfolio = (): void => {
    const userId = user?.id;
    const limit = tableState.limit;
    const offset = tableState.page * tableState.limit;
    setIsPortfolioLoading(true);
    api['portfolio'].get(`/`, {filter: 'userId||$eq||' + userId, limit, offset,}).then((response: AxiosResponse) => {
      setPortfolios(response.data);
      setIsPortfolioLoading(false);
    });
  };

  const loadInsights = (): void => {
    const userId = user?.id;
    setIsInsightLoading(true);
    api['portfolio'].get(`/user/${userId}/insights`).then((response: AxiosResponse) => {
      setInsightsData(response.data);
    }).finally(() => {
      setIsInsightLoading(false);
    });
  };

  const getValues = (): PortfolioProviderValue => {
    return {
      isPortfolioLoading,
      isInsightLoading,
      portfolios: portfolios?.data ?? [],
      total: portfolios?.total ?? 0,
      setTableState,
      tableState,
      insightsData,
      needsToRefetch
    };
  }

  return (
    <PortfolioContext.Provider
      value={getValues()}>
      {children}
    </PortfolioContext.Provider>
  );
}

export const usePortfolioContext = () => {
  return useContext(PortfolioContext);
};

export const PortfolioConsumer = PortfolioContext.Consumer;
