import {Box, Card, Grid, Switch, Typography} from '@mui/material';
import {useMFAContext} from '@panel/contexts/MFAContext';

const Security = () => {

  const {isEnabled, toggleMFA} = useMFAContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{px: 3, pt: 4, pb: 2, height: '75vh'}}>
          <Typography mb={4} variant="h6">
            Security
          </Typography>

          <Box sx={{display: 'flex', justifyContent: 'flex-start', mb: 2}}>
            <Grid container>
              <Grid item>
                <Typography variant="subtitle2">2FA</Typography>
                <Typography my={1} variant="body1">
                  Enable two factor authentication before completing a transaction.
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{flexGrow: 1, mx: 1, mb: 1}}/>
            <Switch checked={isEnabled} onChange={toggleMFA}/>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Security;
