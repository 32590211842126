import {Stack, Step, Stepper, StepLabel, Typography} from '@mui/material';
import {QontoStepIcon, QontoConnector} from '@panel/components/QontoStepIcon';

const steps = [
  {
    label: 'Account Details',
    number: 1,
  },
  {
    label: 'Investor Type',
    number: 2,
  },
  {
    label: 'Personal Info',
    number: 3,
  },
  {
    label: 'Review',
    number: 4,
  },
];

const ProgressHeader = ({currentStep}: {currentStep: number}) => {
  return (
    <Stack sx={{width: '100%', py: 2, backgroundColor: 'background.default', color: 'text.secondary'}} spacing={4}>
      <Stepper sx={{mt: 2}} alternativeLabel activeStep={currentStep - 1} connector={<QontoConnector />}>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Typography sx={{color: step.number <= currentStep ? 'primary.main' : 'text.secondary', mt: '-12px'}}>
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default ProgressHeader;
