import {Button, Grid, SvgIcon} from '@mui/material';
import {IHeader} from '@panel/interfaces/header';
import {useHistory} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BreadCrumbs from './BreadCrumbs';

const Header: React.FunctionComponent<IHeader> = ({breadCrumbTitle, breadCrumbItems, ...rest}) => {
  const history = useHistory();
  return (
    <Grid container justifyContent="space-between" {...rest}>
      <Button
        variant="outlined"
        size="small"
        sx={{borderRadius: '25px', color: 'black', borderColor: 'rgba(0,0,0,0.1)', my: {xs: 2, sm: 0}}}
        onClick={() => history.push(`/marketplace`)}
        startIcon={
          <SvgIcon fontSize="small">
            <ArrowBackIosIcon />
          </SvgIcon>
        }
      >
        {'Go to Marketplace'}
      </Button>
      <BreadCrumbs items={breadCrumbItems} />
    </Grid>
  );
};

export default Header;
