import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {DashboardNavbar} from './Navbar';
import {DashboardSidebar} from './Sidebar';
import {Box} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import {AuthLayoutFooter} from '@panel/layouts/AuthLayout/Footer';
import {Scrollbar} from '@panel/components';

const DashboardLayoutRoot = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  flexDirection: 'column',
  paddingTop: 64,
}));

export const DashboardLayout: React.FC<{ children?: React.ReactNode }> = ({children}): JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Box sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
      }}>
      <DashboardLayoutRoot>{children}</DashboardLayoutRoot>
      <DashboardNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <DashboardSidebar onClose={() => setIsSidebarOpen(false)} open={isSidebarOpen} />
      <AuthLayoutFooter />
    </Box>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
