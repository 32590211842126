const FormErrorMessage = ({message}: {message: string}) => {
  return (
    <span
      style={{
        color: '#D14343',
        fontWeight: '400',
        fontSize: '0.75rem',
        lineHeight: '1.66',
        margin: '3px 14px 0px 4px',
        fontFamily: 'Segoe UI, Helvetica,Arial,sans-serif',
      }}
      className={message.length > 2 ? 'form__error-text active' : 'form__error-text'}
    >
      {message}
    </span>
  );
};
export default FormErrorMessage;
