import {FC} from 'react';
import {Button, Grid} from '@mui/material';

export type StepperPagerParams = {
  step: number,
  label?: string,
  prev: VoidFunction,
  next: VoidFunction,
  canContinue: boolean;
}
const StepperPager: FC<StepperPagerParams> = ({prev, step, next, label, canContinue}: StepperPagerParams) => {

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item xs={12} md={6}>
        <Button onClick={prev}
                disabled={step <= 1}
                sx={{color: '#000000', borderColor: '#000000'}}
                fullWidth
                size="large"
                variant="outlined">
          Back
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button disabled={!canContinue}
                onClick={next}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained">
          {label || 'Next'}
        </Button>
      </Grid>
    </Grid>
  )
}
export default StepperPager;
