import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import { Scrollbar } from '@panel/components';
import { getInitials } from '@panel/utils/get-initials';
import { BrixTypes, formatPrice, formatWithDigits } from '@brix/shared-frontend';
import { api, brixieToUnit } from '@panel/utils';
import React, { useState } from 'react';
import { IPortfolio } from '@panel/interfaces';
import BrixSelectionModal from '@panel/components/BrixSelectionModal';
import moment from 'moment';
import { toast } from 'react-toastify';
import PorcentDividends from './PorcentDividends'
import BrixResaleModal from '@panel/components/BrixResaleModal';
import { usePortfolioContext } from '@panel/contexts/PortfolioContext';
import { BrixMortgageUnableProperty } from '@panel/components/BrixMortgageUnableProperty';

const Portfolio = () => {
  const [isBrixModalOpen, setIsBrixModalOpen] = useState(false);
  const [isSellModalOpen, setIsSellModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const { portfolios, tableState, setTableState, isPortfolioLoading, total } = usePortfolioContext();

  const onPageChange = async (page: number) => {
    setTableState && setTableState({ ...tableState, page });
  };

  const onRowsPerPageChange = async (limit: number) => {
    if (tableState) {
      tableState.limit = limit;
    }
    setTableState && setTableState({ ...tableState });
  };

  const handleOpenBrixModal = async (property: any) => {
    const res = await api['properties'].get(`/${property?.propertyId}`, { include: ['address', 'poster', 'media'] });
    console.log(res);
    setSelectedProperty({
      propertyName: res?.data?.name,
      propertyId: res?.data?.id,
      city: res?.data?.__address__?.city,
      stateCode: res?.data?.__address__?.stateCode,
      propertyImage: res?.data?.__poster__?.key,
      hasMortgage: Number(res?.data?.__financial__?.mortgageBalanceAmount ?? 0) > 0
    });
    setIsBrixModalOpen(true);
  };

  const handleOpenSellModal = async (portfolio: IPortfolio) => {
    if (available(portfolio.publishedAt) && portfolio.brixCount > 0) {
      const res = await api['properties'].get(`/${portfolio?.propertyId}`, { include: ['address', 'poster', ' media', 'financial'] });
      setSelectedProperty({
        propertyName: res?.data?.name,
        propertyId: res?.data?.id,
        city: res?.data?.__address__?.city,
        stateCode: res?.data?.__address__?.stateCode,
        propertyImage: res?.data?.__poster__?.key,
        brixCount: portfolio.brixCount,
        hasMortgage: Number(res?.data?.__financial__?.mortgageBalanceAmount ?? 0) > 0
      });
      setIsSellModalOpen(true);
    } else if (available(portfolio.publishedAt)) {
      toast.warning('Insufficient total owned-brix amount');
    } else {
      toast.warning(propertyMessage(portfolio));
    }
  };

  const handleClose = (value: string) => {
    setIsBrixModalOpen(false);
    setSelectedValue(value);
  };

  const handleCloseSell = () => {
    setIsSellModalOpen(false);
  };

  const dayDiff = (publishedAt: Date | undefined) => {
    return Math.abs(moment().diff(moment(publishedAt ?? new Date()), 'days'));
  };

  const available = (publishedAt: Date | undefined) => {
    return dayDiff(publishedAt) > 90;
  };

  const propertyMessage = (property: IPortfolio) => {
    return available(property.publishedAt)
      ? ''
      : `Sell is not available until: ${90 - dayDiff(property.publishedAt)} days`;
  };

  const YearlyNetDividendText = 'Estimated Yearly Net Dividend is the percentage calculated by Total rent net of the year/The property closing value'

  return (
    <Card sx={{ backgroundColor: 'white', p: 3, pt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600, mt: 2 }}>
          Portfolio
        </Typography>
        <Box sx={{ flexGrow: 1, mx: 1, mb: 1 }} />
        <RouterLink to={'/portfolio'} style={{ textDecoration: 'initial' }}>
          <Button size="large" variant="outlined">
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              See all
            </Typography>{' '}
          </Button>
        </RouterLink>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700, whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell>PROPERTY</TableCell>
              <TableCell align="center">PROPERTY VALUE</TableCell>
              <TableCell align="center"># OF BRIX</TableCell>
              <TableCell align="center">BRIX VALUE</TableCell>
              <TableCell align="center">Est. Yearly Net Dividend
                <Tooltip title={YearlyNetDividendText}>
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="center">ACTION</TableCell>
            </TableRow>
          </TableHead>
          {(portfolios && portfolios.length > 0) ? (
            <TableBody>
              {portfolios?.map((portfolio: IPortfolio, index: number) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell align="center">
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <RouterLink style={{ textDecoration: 'initial', color: 'black' }}
                          to={`/marketplace/${portfolio.propertyId}`} />
                        <Avatar variant="square"
                          src={portfolio?.poster}
                          alt={portfolio?.propertyName}
                          sx={{
                            height: 60,
                            width: 60,
                            borderRadius: '5px',
                            bgcolor: 'rgba(0,0,0,0.1)'
                          }}>
                          {getInitials(portfolio?.name)}
                        </Avatar>
                        <Box sx={{ ml: 1 }}>
                          <RouterLink style={{ textDecoration: 'initial', color: 'black' }}
                            to={`/marketplace/${portfolio.propertyId}`}>
                            {portfolio?.name}
                          </RouterLink>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{formatPrice(portfolio?.propertyValue)}</TableCell>
                    <TableCell align="center">
                      {brixieToUnit(portfolio.brixCount ?? 0, portfolio.brixType)}
                      {portfolio.brixType === BrixTypes.Brix && ' BRiX'}
                      {portfolio.brixType === BrixTypes.Brixie && ' BRiXies'}
                      {portfolio.brixType === BrixTypes.Stax && ' Stax'}
                      {portfolio.brixType === BrixTypes.HalfBrix && ' HalfBrix'}
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="success.main" variant="subtitle2">
                        {formatWithDigits(portfolio?.brixieValue)}
                      </Typography>
                    </TableCell>
                    <PorcentDividends propertyId={portfolio?.propertyId} />
                    <TableCell align="center">
                      <Box sx={{
                        display: 'flex',
                        flexDirection: {
                          xs: 'column',
                          lg: 'row'
                        },
                        gap: {
                          xs: '10px',
                          lg: '0px'
                        },
                        justifyContent: 'center'
                      }}>
                        <Button onClick={() => handleOpenBrixModal(portfolio)} variant="outlined" color="info">
                          Buy
                        </Button>
                        {/*
                        this way will be a post lunch activity
                        <Tooltip title={propertyMessage(portfolio)}></Tooltip>
                        */
                      }
                        <Tooltip title={'Sell feature currently not available for this property'}>
                        <span>
                          <Button
                            onClick={() => handleOpenSellModal(portfolio)}
                            variant="outlined"
                            color="primary"
                            sx={{ ml: 1 }}
                            disabled
                          >
                            Sell
                          </Button>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : isPortfolioLoading ? (
            <TableBody>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      pt: 4,
                      pb: 2,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <Alert severity="info">
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Your portfolio is empty.
                    </Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={total || 0}
        onPageChange={(e, page) => onPageChange(page)}
        onRowsPerPageChange={(e) => onRowsPerPageChange(Number(e.target.value) || 10)}
        page={tableState?.page ?? 0}
        rowsPerPage={tableState?.limit ?? 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {isBrixModalOpen && !isSellModalOpen && (
        <BrixSelectionModal
          selectedPropertyId={selectedProperty?.propertyId}
          selectedProperty={{
            propertyId: selectedProperty?.propertyId,
            propertyName: selectedProperty?.propertyName,
            propertyAddress: selectedProperty?.city + ', ' + selectedProperty?.stateCode,
            propertyImage: selectedProperty?.propertyImage,
          }}
          selectedValue={selectedValue}
          open={isBrixModalOpen}
          onClose={handleClose}
        />
      )}

      {isSellModalOpen && !isBrixModalOpen && !selectedProperty.hasMortgage && (
        <BrixResaleModal
          selectedPropertyId={selectedProperty?.propertyId}
          brixieAmount={selectedProperty.brixCount}
          staxAmount={0}
          brixAmount={0}
          open={isSellModalOpen}
          onClose={handleCloseSell}
        />
      )}

      {isSellModalOpen && !isBrixModalOpen && selectedProperty.hasMortgage && (
        <BrixMortgageUnableProperty onClose={handleCloseSell} />
      )}
    </Card>
  );
};

export default Portfolio;
