import {useContext, useEffect} from 'react';
import {Box, Button, Card, Container, Grid, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import RegisterContext from '@panel/contexts/RegisterContext';
import {useMutation} from 'react-query';
import useAuth from '@panel/hooks/useAuth';
import {toast} from 'react-toastify';
import { api } from '@panel/utils';

type EmailPassword = { email: string, password: string, redirectUri: string };

const Congratulations = () => {
  const {login} = useAuth();
  const history = useHistory();
  const registerContext = useContext(RegisterContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    api['/auth'].post('/notify-registration', {userId: registerContext?.state?.id}).then(() => {
      toast.info('We\'ve send you a welcome email');
    }, () => {
      toast.info('Unable to send welcome email');
    })
  }, []);

  const mutation = useMutation((data: EmailPassword) => login(data.email, data.password, data.redirectUri), {
    onError: () => {
      history.push('/');
    },
  });

  const handleMarketPlace = () => {
    loginAndLeave('/marketplace');
  };

  const handleCompleteProfile = () => {
    loginAndLeave('/profile');
  };

  const loginAndLeave = (redirectUri: '/profile' | '/marketplace') => {
    const credentials = {
      redirectUri: redirectUri,
      email: registerContext?.state.email ?? '',
      password: registerContext?.state.password ?? ''
    }
    mutation.mutate(credentials);
  }
  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: {
          xs: 2,
          md: 0,
          lg: 0,
        },
      }}
    >
      <Card sx={{pt: 4, border: 'none', boxShadow: 'none'}}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" align="center">
            Congratulations!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <img width="75" height="100" alt="brick" src={'../assets/images/brick-congratulation1.png'}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
                You can add an extra layer of security and put more information on{' '}
                <span style={{color: 'red'}}>your profile</span>.
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <img width="60" height="60" alt="brick" src={'../assets/images/brick-congratulation2.png'}/>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          flexGrow: 1,
          mt: 4,
          ml: {
            md: 8,
            xs: 1,
          },
          mr: {
            md: 8,
            xs: 1,
          },
        }}
        />
        <Box mt={2}>
          <Grid container mt={2} spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                sx={{color: '#000000', borderColor: '#000000'}}
                onClick={handleCompleteProfile}
                fullWidth
                size="large"
                variant="outlined"
              >
                Complete Profile
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button color="primary" fullWidth size="large" variant="contained" onClick={handleMarketPlace}>
                Explore Marketplace
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Container>
  );
};

export default Congratulations;
