export interface DataPlaceholder {
  type: number;
  id: string;
  name: string;
  address: string;
  grossValue: string;
  lotSize: number;
  __poster__: string;
  publicationDate: Date;
}

const dataPlaceholder: Array<DataPlaceholder> = [
  {
    type: 1,
    id: '123',
    name: 'Brixiom 94 LLC',
    address: 'West Leonel, Vermont',
    grossValue: '93454.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-1.jpg',
    publicationDate: new Date('2022-06-11T11:16:49.756Z'),
  },
  {
    type: 2,
    id: '124',
    name: 'Brixiom 97 LLC',
    address: 'South Murlmouth, Indiana',
    grossValue: '86453.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-2.jpg',
    publicationDate: new Date('2022-05-11T11:16:49.756Z'),
  },
  {
    type: 1,
    id: '125',
    name: 'Brixiom 95 LLC',
    address: 'East Jaqueline, Alabama',
    grossValue: '7425.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-3.jpg',
    publicationDate: new Date('2022-06-12T11:16:49.756Z'),
  },
  {
    type: 3,
    id: '153',
    name: 'Brixiom 99 LLC',
    address: 'Tillmanshire, Pennsylvania',
    grossValue: '45765.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-4.jpg',
    publicationDate: new Date('2022-05-12T11:16:49.756Z'),
  },
  {
    type: 4,
    id: '156',
    name: 'Brixiom 91 LLC',
    address: 'West Leonard, New Jersey',
    grossValue: '13542.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-5.jpg',
    publicationDate: new Date('2022-06-15T11:16:49.756Z'),
  },
  {
    type: 2,
    id: '253',
    name: 'Brixiom 93 LLC',
    address: 'Mrazstad, Hawaii',
    grossValue: '8829.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-6.jpg',
    publicationDate: new Date('2022-04-11T11:16:49.756Z'),
  },
  {
    type: 1,
    id: '6553',
    name: 'Brixiom 98 LLC',
    address: 'West Leonel, Vermont',
    grossValue: '4829.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-7.jpg',
    publicationDate: new Date('2022-05-10T11:16:49.756Z'),
  },
  {
    type: 3,
    name: 'Brixiom 92 LLC',
    id: '1535',
    address: 'West Leonel, Vermont',
    grossValue: '14573.99',
    lotSize: 999,
    __poster__: '../../../assets/images/marketplace-property-placeholder-8.jpg',
    publicationDate: new Date('2022-05-19T11:16:49.756Z'),
  },
];

export default dataPlaceholder;
