import {Box, Grid, Typography, Tooltip, Button} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import useAuth from '@panel/hooks/useAuth';

const Welcome = () => {
  const {user} = useAuth();

  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-start', mb: 2}}>
      <Typography variant="subtitle2" sx={{fontWeight: 600, mt: 2}}>
        Welcome, {user?.firstName}
      </Typography>
      <Box sx={{flexGrow: 1, mx: 1, mb: 1}} />
      <RouterLink to={'/portfolio'} style={{textDecoration: 'initial'}}>
        <Button size="large" variant="outlined">
          <Typography variant="body2" sx={{fontSize: '12px'}}>
            Sell my BRiX
          </Typography>{' '}
        </Button>
      </RouterLink>
      <RouterLink to={'/marketplace'} style={{textDecoration: 'initial'}}>
        <Button size="large" variant="contained" sx={{ml: 1}}>
          <Typography variant="body2" sx={{fontSize: '12px'}}>
            Buy more BRiX
          </Typography>
        </Button>
      </RouterLink>
    </Box>
  );
};

export default Welcome;
