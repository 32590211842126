import {createSvgIcon} from '@mui/material/utils';

export const Filter = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6667 8.66665C14.5871 8.66665 15.3333 7.92045 15.3333 6.99998C15.3333 6.07951 14.5871 5.33331 13.6667 5.33331C12.7462 5.33331 12 6.07951 12 6.99998C12 7.92045 12.7462 8.66665 13.6667 8.66665Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M5.33334 7H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.3333 7H18.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.66667 13.6666C9.58714 13.6666 10.3333 12.9205 10.3333 12C10.3333 11.0795 9.58714 10.3333 8.66667 10.3333C7.74619 10.3333 7 11.0795 7 12C7 12.9205 7.74619 13.6666 8.66667 13.6666Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33334 12H7.00001"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3333 12H18.6667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1667 18.6666C17.0871 18.6666 17.8333 17.9205 17.8333 17C17.8333 16.0795 17.0871 15.3333 16.1667 15.3333C15.2462 15.3333 14.5 16.0795 14.5 17C14.5 17.9205 15.2462 18.6666 16.1667 18.6666Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M5.33334 17H14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.8333 17H18.6667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Filter'
);
