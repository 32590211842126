import { Button, Grid, SvgIcon, Typography, Box, Dialog, DialogTitle, DialogContent, Container, DialogActions } from '@mui/material';
import { useState, useEffect } from 'react';
import { ISubHeader } from '@panel/interfaces/subheader';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TourOutlinedIcon from '@mui/icons-material/TourOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import { PropertyTypes, PropertyRentalTypes } from '@brix/shared-frontend';

const SubHeader: React.FunctionComponent<ISubHeader> = ({
  addressItem,
  type,
  status,
  openBrixModal,
}) => {
  const [onWishlist, setOnWishlist] = useState(false);
  const [shareDialog, setShareDialog] = useState(false)

  const handleShareButtonClick = () => {
    console.log('Share in development');
    setShareDialog(true);
  };

  const handlecloseShareDialog = () => {
    setShareDialog(false);
  };

  const handleAddToWishlistButtonClick = () => {
    setOnWishlist(!onWishlist);
  };

  const propertyTypeLabel = PropertyTypes[Number(type)];
  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Box>
            {addressItem !== undefined && (
              <Button disabled startIcon={<LocationOnOutlinedIcon fontSize="small" />}>
                <Typography color="gray" sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600 }} variant="body2">
                  {addressItem !== null
                    ? addressItem.city !== null
                      ? addressItem.city + ', ' + addressItem.stateCode + ', ' + addressItem.zipCode
                      : ' ' + addressItem.stateCode + ', ' + addressItem.zipCode
                    : 'Unspecified'}
                </Typography>
              </Button>
            )}
            {type !== undefined && (
              <Button disabled startIcon={<HomeIcon fontSize="small" />}>
                <Typography color="gray" sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600 }} variant="body2">
                  {type !== null ? propertyTypeLabel?.charAt(0).toUpperCase() + propertyTypeLabel?.slice(1) : ' '}
                </Typography>
              </Button>
            )}
            {status !== undefined && (
              <Button disabled startIcon={<TourOutlinedIcon fontSize="small" />}>
                <Typography color="gray" sx={{ textTransform: 'none', fontSize: '12px', fontWeight: 600 }} variant="body2">
                  {PropertyRentalTypes[status]}
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item sx={{ mt: { xs: 1, md: 0 } }}>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '10px', sm: '0px' },
          }}>
            <Button onClick={openBrixModal} variant="contained" color="primary" sx={{ mr: 1 }}>
              {'Buy'}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleShareButtonClick}
              sx={{ mr: 1 }}
              startIcon={
                <SvgIcon fontSize="small">
                  <ShareIcon />
                </SvgIcon>
              }
            >
              {'Share'}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleAddToWishlistButtonClick}
              startIcon={<SvgIcon fontSize="small">{onWishlist ? <FavoriteIcon /> : <FavoriteBorderIcon />}</SvgIcon>}
            >
              {'Wishlist'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={shareDialog}
        onClose={handlecloseShareDialog}
        maxWidth={'md'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={true}>
              <Box sx={{ maxWidth: '1000px', pb: 3 }}>
                <Typography color="black" variant="h3" >
                  Coming Soon
                </Typography>
              </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlecloseShareDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubHeader;
