import {Box, Card, Container, Divider, Link, Typography} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import {Link as RouterLink} from 'react-router-dom';
import Page from '@panel/components/Page';

import LoginForm from './LoginForm';

const LoginView: React.FC<IPage> = () => {
  return (
    <Page title="Login">
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            pt: {
              xs: 2,
              md: 0,
              lg: 0,
            },
          }}
        >
          <Card sx={{p: 4, border: 'none', boxShadow: 'none'}}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <img width="160" height="70" alt="Logo" src={'../assets/images/brick.png'} />
              <Typography variant="h3" align="center">
                Welcome back to <span style={{color: '#CC4645'}}>BRiX</span>
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 4,
                ml: {
                  md: 8,
                  xs: 1,
                },
                mr: {
                  md: 8,
                  xs: 1,
                },
              }}
            >
              <LoginForm />
              <Divider sx={{my: 3}} />
              <Link
                component={RouterLink}
                to="/forgot-password"
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItems: 'end',
                  flexDirection: 'column',
                  color: 'text.red',
                  fontWeight: '500',
                }}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Box>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
