import Page from '../../../components/Page';
import {Box} from '@mui/material';
import {FunctionComponent, useState} from 'react';
import BalanceTransferProgressBar from '@panel/views/wallet/BalanceTransfer/BalanceTransferProgressBar';
import SelectACHAccountInfo from '@panel/views/wallet/BalanceTransfer/SelectACHAccountInfo';
import Stripe from 'stripe';
import useAuth from '@panel/hooks/useAuth';
import {api} from '@panel/utils';
import {AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
import SelectAmountToTransferToWallet from './SelectAmountToTransferToWallet';
import TransferMessage from '@panel/views/wallet/TransferMessage';
import {useMFAContext} from '@panel/contexts/MFAContext';
import MFAConfirmationDialog from '@panel/components/MFAConfirmationDialog';

type TransferResponse = { succeed: boolean, message: string }
const styles = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'background.paper',
};

const TransferToWalletView: FunctionComponent = () => {
  const {user} = useAuth();
  const {isEnabled} = useMFAContext();
  const [account, setAccount] = useState<Stripe.PaymentMethod>();
  const [balance, setBalance] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [needsToValidate, setNeedsToValidate] = useState<boolean>(false);

  const handleTransferBalance = () => {
    api['user'].post(`/${user?.id}/balance/transfer-to-wallet`, {
      userId: user?.id,
      method: account?.id,
      amount: Number(balance)
    }).then((resp: AxiosResponse) => {
      const response = resp.data as TransferResponse;
      if (response.succeed) {
        toast.success(response.message);
        setStep(3);
      } else {
        toast.warning(response.message);
      }
    }, () => {
      const message = 'There was an error trying to transfer balance. Please try again later.'
      toast.error(message);
    })
  }

  const handleSubmit = (account: Stripe.PaymentMethod) => {
    setAccount(account);
    if (isEnabled) {
      setNeedsToValidate(true);
    } else {
      handleTransferBalance();
    }
  }

  const handleOnValidated = () => {
    setNeedsToValidate(false);
    handleTransferBalance();
  }

  return (
    <Page title="Transfer From Wallet">
      <Box component="main" sx={styles}>
        <BalanceTransferProgressBar currentStep={step}/>
        {step === 1 &&
        <SelectAmountToTransferToWallet balance={balance} setBalance={setBalance} setStep={setStep} step={step}/>}
        {step === 2 &&
        <SelectACHAccountInfo setAccount={setAccount} account={account} submit={handleSubmit} setStep={setStep}
                              step={step}/>}
        {step === 3 && <TransferMessage/>}
      </Box>
      {needsToValidate && <MFAConfirmationDialog onValidated={handleOnValidated}/>}
    </Page>
  );
}

export default TransferToWalletView;
