import {Breadcrumbs, Grid, Link, Typography} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {IBreadCrumb} from '@panel/interfaces/breadcrumb';
import {Link as RouterLink} from 'react-router-dom';

const BreadCrumbs: React.FunctionComponent<{title?: string; items: IBreadCrumb[]}> = ({title, items}) => {
  const renderItems = (items: IBreadCrumb[]) => {
    return items.map((item: {url?: string; text: string}, index: number) => {
      const color = index === items.length - 1 ? 'primary' : 'inherit';

      if (item.url) {
        return (
          <Link
            style={{fontWeight: 600, fontSize: '14px'}}
            key={index}
            variant="subtitle2"
            color={color}
            to={item.url}
            component={RouterLink}
          >
            {item.text}
          </Link>
        );
      } else {
        return (
          <Typography sx={{fontWeight: 600, fontSize: '14px'}} key={index} variant="subtitle2" color={color}>
            {item.text}
          </Typography>
        );
      }
    });
  };

  return (
    <Grid item>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="breadcrumb">
        {renderItems(items)}
      </Breadcrumbs>
      <Typography variant="h3" color="textPrimary">
        {title}
      </Typography>
    </Grid>
  );
};

export default BreadCrumbs;
