import {Box, Container, Grid} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import Page from '@panel/components/Page';
import MyCart from './MyCart';
import OrderSummary from './OrderSummary';
import RecommendedProperties from '../marketplace/PropertyDetailsView/Details/RecommendedProperties';

const CartView: React.FunctionComponent<IPage> = (props) => {

  const { name } = props

  return (
    <Page title={name ?? ''}>
      <Box
        sx={{
          flexDirection: 'column',
          flexGrow: 1,
          py: 8,
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={8}>
              <MyCart />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OrderSummary />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          mx: {xs: 2, md: 4},
          mb: 12,
          maxWidth: '100vw',
          backgroundColor: 'background.paper',
        }}
      >
        <RecommendedProperties />
      </Box>
    </Page>
  );
};

export default CartView;
