import { useMemo, useRef, useEffect, EffectCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {AppBar, Box, Divider, Drawer, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import Logo from '@panel/components/Logo';
import {Scrollbar} from '@panel/components/Scrollbar';
import {DashboardSidebarSection} from './SidebarSection';

const getSections = () => [
  {
    title: '',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
      },
      {
        title: 'Marketplace',
        path: '/marketplace',
      },
      {
        title: 'Portfolio',
        path: '/portfolio',
      },
      {
        title: 'Financials',
        path: '/financials',
      },
      {
        title: 'Reporting',
        path: '/reporting',
      },
    ],
  },
];

const DashboardSidebarRoot = styled(AppBar)(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderRightColor: theme.palette.divider,
        borderRightStyle: 'solid',
        borderRightWidth: 1,
        boxShadow: 'none',
      }),
}));

export const DashboardSidebar: React.FC<{
  onClose: EffectCallback;
  open: boolean;
}> = (props) => {
  const location = useLocation();
  const {onClose, open} = props;
  const sections = useMemo(() => getSections(), []);
  const organizationsRef = useRef(null);

  const handlePathChange: EffectCallback = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div>
          <Box sx={{p: 3}}>
            <Logo width={80} height={80} />
          </Box>
          <Box sx={{px: 2}}>
            <Box
              ref={organizationsRef}
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1,
              }}
            >
              <div>
                <Typography color="inherit" variant="subtitle1">
                  BRiX
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3,
          }}
        />
        <Box sx={{flexGrow: 1}}>
          {sections.map((section) => (
            <DashboardSidebarSection key={section.title} path={location.pathname} {...section} />
          ))}
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748', // dark divider
          }}
        />
      </Box>
    </Scrollbar>
  );

  return (
    <DashboardSidebarRoot>
      <Drawer
        anchor="left"
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: {
            color: '#FFFFFF',
            width: 280,
          },
        }}
        sx={{zIndex: (theme) => theme.zIndex.appBar + 100}}
        variant="temporary"
      >
        {content}
      </Drawer>
    </DashboardSidebarRoot>
  );
};
