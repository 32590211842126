import { Card, CardHeader, Grid, Typography, Tooltip } from '@mui/material';
import { formatWithDigits } from '@brix/shared-frontend';
import { Link as RouterLink } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Image } from 'mui-image';
import { useState, useEffect } from 'react';

const Insights = (props: any) => {
  const [insightCategories, setInsightCategories] = useState<any[]>()

  const { insights } = props

  useEffect(() => {

    setInsightCategories([
      {
        type: `dividends`,
        value: `${formatWithDigits(insights?.dividends)}`,
        description: 'Dividends Earned to date',
        tooltip: 'Dividends earned to date are all the dividends received in total from all currently owned fractions of property you own. Dividends represent your portion of net income per property-owned company.',
        icon: '../assets/images/bill-icon.png',
        actionIcon: '',
        action: '',
      },
      {
        type: `value`,
        value: `${formatWithDigits(insights?.brixieValue)}`,
        description: 'Total BRiX Fractional Values Owned',
        tooltip: 'The current total estimated value of all the fractions of property you own',
        icon: '../assets/images/coins-icon.png',
        actionIcon: '',
        action: '',
      }
    ])

  }, [insights])



  return (
    <Grid container spacing={2}>
      {insightCategories?.map((stat) => (
        <Grid sx={{
          marginTop: '-20px',
          paddingTop: '-50px',
        }} key={stat.type} item xs={6}>
          <Card
            sx={{
              backgroundColor: 'white',
              border: '1px solid #E7E5EA',
              borderRadius: '8px',
              p: 3,
              pt: 2
            }}>
            <CardHeader
              sx={{ px: 0, py: 1 }}
              avatar={
                <Image
                  src={stat.icon}
                  alt={stat.type}
                  duration={50}
                  easing="ease-in-out"
                  width={'100%'}
                  height={'40px'}
                  fit="contain"
                />
              }
              subheaderTypographyProps={{ p: 0 }}
            />

            <Typography variant="body2" sx={{ color: 'gray', py: 1 }}>
              {stat.description}
              <Tooltip arrow title={stat.tooltip}>
                <InfoIcon color={'secondary'} fontSize="small" sx={{ cursor: 'pointer', ml: 1, mb: '-6px' }} />
              </Tooltip>
            </Typography>
            <Typography variant="h5" sx={{ pb: 1 }}>
              {stat.value}
              <span style={{ fontSize: '14px', marginRight: 2 }}>{stat.unit}</span>{' '}
              {stat.actionIcon ? (
                <RouterLink to={'/financials'} style={{ textDecoration: 'initial' }}>
                  <Tooltip title={'Request Withdrawal'}>
                    <img alt="Request Withdrawal" height={25} width={25} src={stat.actionIcon} />
                  </Tooltip>
                </RouterLink>
              ) : null}
            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Insights;
