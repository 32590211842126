import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {Box, Button, Collapse, ListItem} from '@mui/material';

export const DashboardSidebarItem: React.FC<{
  active: boolean;
  children?: React.ReactNode;
  chip?: React.ReactNode;
  depth: number;
  icon?: React.ReactNode;
  info?: string;
  open?: boolean;
  path: string;
  title: string;
}> = (props) => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen: boolean | undefined) => !prevOpen);
  };

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          py: 0,
          px: 2,
        }}
        {...other}
      >
        <Button
          disableRipple
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: active ? 'primary.main' : 'text.secondary',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)',
            },
            '& .MuiButton-startIcon': {
              color: active ? 'primary.main' : 'neutral.400',
            },
            '& .MuiButton-endIcon': {
              color: 'neutral.400',
            },
          }}
        >
          <Box sx={{flexGrow: 1}}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open} sx={{mt: 0.5}}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
      component={RouterLink}
      to={path}
    >
      <Button
        startIcon={icon}
        endIcon={chip}
        disableRipple
        sx={{
          backgroundColor: active ? 'rgba(255,255,255, 0.08)' : 'transparent',
          borderRadius: 1,
          color: active ? 'primary.main' : 'text.secondary',
          fontWeight: active ? 'fontWeightBold' : 'fontWeightRegular',
          justifyContent: 'flex-start',
          pl: `${paddingLeft}px`,
          pr: 3,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          '& .MuiButton-startIcon': {
            color: active ? 'primary.main' : 'neutral.400',
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)',
          },
        }}
      >
        <Box sx={{flexGrow: 1}}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  );
};

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false,
};
