import React, {useEffect, useState, useContext} from 'react';
import {Box, Button, Card, Container, MenuItem, Select, TextField, Typography, SelectChangeEvent} from '@mui/material';
import {useForm, Controller} from 'react-hook-form';
import FormErrorMessage from '@panel/components/FormErrorMessage';
import RegisterContext from '@panel/contexts/RegisterContext';
import {errorHandler, useYupValidationResolver} from '@brix/shared-frontend';
import {RegisterWizardProps, IAccountDetails, IQuestion} from '@panel/interfaces';
import {IRegistrationWizard} from '@panel/interfaces/registration-wizard';
import api from '@panel/utils/api';

import {loadQuestions} from '../utils';
import {AccountDetailsValidation} from '../validations';

const AccountDetails = ({prevStep, nextStep, updateUser}: IRegistrationWizard) => {
  const resolver = useYupValidationResolver(AccountDetailsValidation);
  const [securityQuestionsList, setSecurityQuestionsList] = useState<IQuestion[]>([]);
  const registerContext = useContext(RegisterContext);

  const {register, handleSubmit, formState, clearErrors, control, setError, setValue} = useForm<IAccountDetails>({
    resolver,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const {errors} = formState;

  const onSubmit = async (data: RegisterWizardProps) => {
    updateUser(data);
    registerContext?.dispatch({type: 'SET_DATA', payload: data});
    nextStep && nextStep();
  };

  const validateUserName = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 3) {
      try {
        await api['users'].post('/validate-username', {userName: event.target.value}, {data: {skipAuthRefresh: true}});
        clearErrors('userName');
      } catch (e: any) {
        setError('userName', {type: 'manual', message: errorHandler(e?.data)});
      }
    }
  };

  const handleOnChangeSelect = (e: SelectChangeEvent) => {
    clearErrors('securityQuestion1');
    setValue('securityQuestion1', e.target.value);
    registerContext?.dispatch({type: 'SET_DATA', payload: {securityQuestion1: e.target.value}});
  };

  const handleInitialLoad = async () => {
    const questions = await loadQuestions();
    setSecurityQuestionsList(questions);
  };

  useEffect(() => {
    handleInitialLoad();
    if (registerContext?.state.userName) {
      setValue('userName', registerContext?.state.userName);
      setValue('password', registerContext?.state.password);
      setValue('confirmPassword', registerContext?.state.confirmPassword);
      setValue('securityAnswer1', registerContext?.state.securityAnswer1);
      setValue('securityQuestion1', registerContext?.state.securityQuestion1);
    }
  }, []);

  return (
    <Container maxWidth="sm" sx={{pt: {xs: 2, md: 0, lg: 0}}}>
      <Card sx={{p: 4, border: 'none', boxShadow: 'none'}}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" align="center">
            Account Details
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
            Please complete your account information.
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: 4,
            ml: {md: 8, xs: 1},
            mr: {md: 8, xs: 1},
          }}
        ></Box>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{mt: 2}} variant="body2">
            USER NAME
          </Typography>
          <TextField
            {...register('userName')}
            error={Boolean(errors?.['userName'])}
            fullWidth
            autoFocus
            helperText={errors?.['userName']?.message}
            margin="normal"
            name="userName"
            type="userName"
            variant="outlined"
            onChange={validateUserName}
          />
          <Typography sx={{mt: 2}} variant="body2">
            PASSWORD
          </Typography>
          <TextField
            {...register('password')}
            error={Boolean(errors?.['password'])}
            fullWidth
            helperText={errors?.['password']?.message}
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
          />
          <Typography sx={{mt: 2}} variant="body2">
            CONFIRM PASSWORD
          </Typography>
          <TextField
            {...register('confirmPassword')}
            error={Boolean(errors?.['confirmPassword'])}
            fullWidth
            helperText={errors?.['confirmPassword']?.message}
            margin="normal"
            name="confirmPassword"
            type="password"
            variant="outlined"
          />
          <Typography sx={{mt: 2, mb: 1}} variant="body2">
            SECURITY QUESTION
          </Typography>
          <Controller
            name="securityQuestion1"
            control={control}
            render={({field}) => {
              return (
                <Select
                  {...field}
                  id="securityQuestion1"
                  defaultValue={'0'}
                  required
                  fullWidth
                  onChange={handleOnChangeSelect}
                  error={Boolean(errors?.['securityQuestion1'])}
                  value={
                    securityQuestionsList.length > 0 && registerContext?.state.securityQuestion1
                      ? `${registerContext?.state.securityQuestion1}`
                      : '0'
                  }
                >
                  <MenuItem key={0} sx={{color: 'black'}} value={0} disabled>
                    Select a question
                  </MenuItem>
                  {securityQuestionsList.map((data: IQuestion) => {
                    return (
                      <MenuItem
                        key={data.id}
                        sx={{color: 'black', whiteSpace: 'unset', wordBreak: 'break-all'}}
                        value={data.id}
                      >
                        {data.question}
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            }}
          />
          {errors?.['securityQuestion1'] && <FormErrorMessage message={errors?.['securityQuestion1'].message || ''} />}
          <TextField
            {...register('securityAnswer1')}
            error={Boolean(errors?.['securityAnswer1'])}
            fullWidth
            helperText={errors?.['securityAnswer1']?.message}
            margin="normal"
            name="securityAnswer1"
            type="securityAnswer1"
            variant="outlined"
            placeholder="Answer"
          />
          <Box mt={2}>
            <Button
              color="primary"
              disabled={formState.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </Box>
        </form>
      </Card>
    </Container>
  );
};

export default AccountDetails;
