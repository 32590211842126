import {useStripe} from '@stripe/react-stripe-js';
import useAuth from '../useAuth';
import {useQuery} from 'react-query';
import useApi from '@panel/utils/apis';
import {AxiosResponse} from 'axios';
import api from '../../utils/api';

export interface BankAccountInfo {
  country: string;
  currency: string;
  routing_number: string;
  account_number: string;
  account_holder_name: string;
  account_holder_type: string;
}

export interface RegisteredBankAccountInfo {
  id?: string;
  object?: string;
  account_holder_name?: string;
  account_holder_type?: string;
  account_type?: null;
  bank_name?: string;
  country?: string;
  currency?: string;
  customer?: string;
  fingerprint?: string;
  last4?: string;
  routing_number?: string;
  status?: string;
}

const loadPayouts = (userId?: string) => api['payment-method']
  .get(`/payouts/registered-methods?userId=${userId}`)
  .then((response: AxiosResponse) => response.data as Array<RegisteredBankAccountInfo>);

const useStripeBankAccount = () => {
  const stripe = useStripe();
  const {user} = useAuth();
  const {paymentMethodApi} = useApi();
  const queryKey = ['payment-method', 'payouts', 'user', user?.id, 'registered'];

  const {data: bankAccounts, isLoading, refetch} = useQuery(queryKey, () => loadPayouts(user?.id));

  const addNewBankAccount = async (info: BankAccountInfo) => {
    return new Promise((resolve, reject) => {
      stripe?.createToken('bank_account', info).then(stripeResp => {
        if (stripeResp.error) {
          reject({message: 'Unable to register bank account'});
        } else {
          const endpoint = `/payouts/setup-payout-account?userId=${user?.id}`;
          paymentMethodApi.post(endpoint, stripeResp.token).then((response: AxiosResponse) => {
            refetch();
            resolve(response.data);
          }, (error: AxiosResponse) => {
            reject({message: error?.data?.message ?? 'Could not register bank account'});
          });
        }
      });
    })
  };

  const deleteBankAccount = async (bankAccountId?: string) => {
    const endpoint = `/payouts/delete-payout-account?accountId=${bankAccountId}&userId=${user?.id}`;
    await paymentMethodApi.delete(endpoint);
    await refetch();
  };

  return {
    isLoading,
    addNewBankAccount,
    deleteBankAccount,
    bankAccounts: bankAccounts as Array<RegisteredBankAccountInfo>
  };
};

export default useStripeBankAccount;
