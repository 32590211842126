import {Box, Button, Card, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import useCart from '@panel/hooks/cart/useCart';
import React, {useEffect} from 'react';
import useAuth from '@panel/hooks/useAuth';
import InfoIcon from '@mui/icons-material/Info';
import {useHistory} from 'react-router-dom';
import {formatPrice} from '@brix/shared-frontend';

const OrderSummary: React.FC = () => {
  const {user} = useAuth();
  const history = useHistory();
  const {products, total} = useCart();

  return (
    <Card color="white" sx={{px: 2, py: 2, mr: {xs: 0, sm: 4}}}>
      <Grid container justifyContent={'space-between'} spacing={0} sx={{px: 2, py: 2}}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Order Summary</Typography>
        </Grid>
        <Grid item xs={12} sx={{px: 2, py: 0}}>
          {products.map((product) => {
            return (
              <Grid key={product.id} container sx={{my: 3}}>
                <Grid item xs={8} md={9}>
                  <Typography variant="body1" color="gray">
                    {product.propertyName}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={3}>
                  <Typography variant="body2">{formatPrice(product.quantity * product.currentValue)}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mb: 2,
              backgroundColor: 'background.default',
              borderRadius: '5px',
              px: 2,
              py: 2,
            }}
          >
            <Typography variant="body1" color="black">
              Total
              <Tooltip title="Fees: % for credit cards and % for debit cards.">
                <IconButton>
                  <InfoIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Typography>
            <Box sx={{flexGrow: 0.9}} />
            <Typography variant="subtitle2">{formatPrice(total.totalPrice)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={user?.status !== 'Approved' || total.propertyQuantity === 0}
            onClick={() => history.push(`/checkout`)}
            fullWidth
            variant="contained"
            color="primary"
          >
            Checkout
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
export default OrderSummary;
