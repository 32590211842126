import {Box} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import CheckoutWizard from './CheckoutWizard';
import Page from '@panel/components/Page';

const CheckoutView: React.FC<IPage> = () => {
  return (
    <Page title="Checkout">
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
        }}
      >
        <CheckoutWizard />
      </Box>
    </Page>
  );
};

export default CheckoutView;
