import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, Grid, IconButton, LinearProgress, TextField, Typography } from '@mui/material';
import MarketplaceItem from './MarketplaceItem';
import MarketplaceItemPlaceholder from './MarketplaceItemPlaceholder';
import { IMarketplaceItem } from '@brix/shared-frontend';
import api from '@panel/utils/api';
import { useQuery } from 'react-query';
import SearchIcon from '@mui/icons-material/Search';
import { Pagination } from '@panel/components';
import dataPlaceholder, { DataPlaceholder } from './rowDataPlaceHolder';
import { FilterContext } from '@panel/components/PropertyFilter';
import SelectOrderMenu from '@panel/views/marketplace/SelectOrderMenu';
import { OrderContext } from '@panel/components/SelectOrderContext';
import { SaleTypeContext } from '@panel/components/SaleTypeContext';
import { debounce } from 'lodash';
import {IFilters} from '@panel/interfaces/filters-marketplace';

const applyPagination = (properties: any, page: number, rowsPerPage: number) =>
  properties.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);

const PropertyGridView: React.FC<any> = (): JSX.Element => {
  const [properties, setProperties] = useState<IMarketplaceItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [filteredProperties, setFilteredProperties] = useState(properties);
  const { filtersSelected } = useContext(FilterContext);
  const [placeholderProperties] = useState(dataPlaceholder);
  const [filteredPlaceholder, setFilteredPlaceholder] = useState([...placeholderProperties]);
  const { orderSelected } = useContext(OrderContext);
  const { saleTypeSelected } = useContext(SaleTypeContext);
  const [openSearchField, setOpenSearchField] = useState(false);
  const [searchValue, setSearchValue] = useState('')
  const [searchParams, setSearchParams] = useState('{}')

  const handleChangeSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }

  const SearchParams = (filtersSelected: IFilters, orderSelected: string, saleTypeSelected: string, searchValue:string) => {

    if (searchValue.length > 0) {
      return (
        {
          limit: '100',
          page: page,
          sort: `${orderSelected === 'recent' ? 'createdAt,DESC' : orderSelected === 'popular' ? 'createdAt,ASC' : orderSelected === 'lowestPrice' ? 'grossValue,ASC' : orderSelected === 'highestPrice' ? 'grossValue,DESC' : orderSelected === 'largest' ? 'size,DESC' : orderSelected === 'smallest' ? 'size,ASC' : 'createdAt,DESC'}`,
          filter: [
            `type||$in||${filtersSelected.types.length === 0 ? '0,1,2,3,4' : filtersSelected.types.join(',')
            }`,
            `grossValue||$between||${filtersSelected.range.length === 0 ? '50000,1000000' : filtersSelected.range.join(',')}`,
            `publishedAt||$notnull`,
            `saleType||$eq||${saleTypeSelected}`,
            `name||$cont||${searchValue}`,
          ],
          include: ['address', 'poster', 'financial'],
        }
      )
    } else {
      return (
        {
          limit: '100',
          page: page,
          sort: `${orderSelected === 'recent' ? 'createdAt,DESC' : orderSelected === 'popular' ? 'createdAt,ASC' : orderSelected === 'lowestPrice' ? 'grossValue,ASC' : orderSelected === 'highestPrice' ? 'grossValue,DESC' : orderSelected === 'largest' ? 'size,DESC' : orderSelected === 'smallest' ? 'size,ASC' : 'createdAt,DESC'}`,
          filter: [
            `type||$in||${filtersSelected.types.length === 0 ? '0,1,2,3,4' : filtersSelected.types.join(',')
            }`,
            `grossValue||$between||${filtersSelected.range.length === 0 ? '50000,1000000' : filtersSelected.range.join(',')}`,
            `publishedAt||$notnull`,
            `saleType||$eq||${saleTypeSelected}`,
          ],
          include: ['address', 'poster', 'financial'],
        }
      )
    }
  }

  const {
    isLoading,
    isError,
    data: fetchedProperties,
    refetch,
  } = useQuery(['property', 'investorPanel', filtersSelected, orderSelected, saleTypeSelected, searchParams], () =>
    api['properties'].get(`/`, SearchParams(filtersSelected, orderSelected, saleTypeSelected, searchValue))
  );

  useEffect(() => {
    refetch();
  }, [filtersSelected, orderSelected, saleTypeSelected, searchValue]);

  useEffect(() => {
    if (fetchedProperties) {
      setProperties(fetchedProperties?.data?.data);
      setNumberOfPages(1 + Math.trunc((fetchedProperties?.data?.data?.length - 1) / 8));
      setFilteredProperties(fetchedProperties?.data?.data);
    }
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, [fetchedProperties, filtersSelected]);

  useEffect(() => {
    handleOrderChange();
  }, [orderSelected, filtersSelected]);

  
  const handleOrderChange = () => {
    switch (orderSelected) {
      case 'recent':
        setFilteredPlaceholder(
          filteredPlaceholder.sort((a, b) => b?.publicationDate?.getTime() - a?.publicationDate?.getTime())
        );
        break;
      case 'popular':
        setFilteredPlaceholder(filteredPlaceholder.reverse());
        break;
      case 'highestPrice':
        setFilteredPlaceholder(filteredPlaceholder.sort((a, b) => Number(b.grossValue) - Number(a.grossValue)));
        break;
      case 'lowestPrice':
        setFilteredPlaceholder(filteredPlaceholder.sort((a, b) => Number(a.grossValue) - Number(b.grossValue)));
        break;
      case 'largest':
        setFilteredPlaceholder(filteredPlaceholder.sort((a, b) => b.lotSize - a.lotSize));
        break;
      case 'smallest':
        setFilteredPlaceholder(filteredPlaceholder.sort((a, b) => a.lotSize - b.lotSize));
        break;
    }
  };

  useEffect(() => {
    if (filtersSelected.types.length === 0) {
      setFilteredPlaceholder(placeholderProperties)
    } else {
      const data = [...placeholderProperties].filter(x => filtersSelected?.types?.includes(x.type))
      setFilteredPlaceholder(data)
    }
  }, [filtersSelected]);

  const paginatedProperties = applyPagination(filteredProperties, page, 8);

  const paginatedPropertiesPlaceholder = applyPagination(filteredPlaceholder, 1, 8);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={11}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              alignSelf: {
                md: 'flex-start',
              },
              marginBottom: {
                xs: '10px',
              },
            }}
          >
            Marketplace
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignSelf: {
                md: 'flex-end',
              },
              gap: '10px',
            }}
          >
              <SearchIcon sx={{
                backgroundColor: 'primary.main',
                borderRadius: '25px',
                color: 'white',
                '&:hover': { color: 'gray' },
                mt: 2.5,
                bottom: 5
              }}
              fontSize="large"
              onClick={() => setOpenSearchField(!openSearchField)} />
            {
              openSearchField && (
                <TextField
                  id="outlined-search"
                  label="Search property"
                  type="search"
                  defaultValue={searchValue}
                  onChange={debounce(handleChangeSearchField, 500)}
                  sx={{
                    mt: 2,
                bottom: 5
                  }}
                />
              )
            }
            <SelectOrderMenu />
          </Box>
        </Grid>
      </Grid>
      {isLoading && (
        <Box>
          <LinearProgress />
        </Box>
      )}
      {isError && (
        <Box my={3}>
          <Alert severity="error">Unable to collect properties from the database.</Alert>
        </Box>
      )}
      <Grid container spacing={3}>
        {paginatedProperties.map((data: any) => {
          return (
            <Grid key={data.id} item xs={11} sm={4} md={3}>
              <MarketplaceItem
                {...data}
                __poster__={data?.__poster__?.key}
                publicationDate={data?.publishedAt}
                financial={data?.__financial__}
                saleType={data.saleType}
              />
            </Grid>
          );
        })}
      </Grid>
      {filteredProperties.length < 8 ? (
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: '8px',
          }}
        >
          {paginatedPropertiesPlaceholder.map((data: DataPlaceholder) => {
            return (
              <Grid key={data.name} item xs={11} sm={4} md={3}>
                <MarketplaceItemPlaceholder
                  id={data.id}
                  type={data.type}
                  name={data.name}
                  address={data.address}
                  grossValue={data.grossValue}
                  lotSize={data.lotSize}
                  __poster__={data.__poster__}
                  publicationDate={data.publicationDate}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      <Pagination setPage={setPage} pageNumber={numberOfPages} />
    </Box>
  );
};

export default PropertyGridView;
