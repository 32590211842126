import {Box, Button, Card, Dialog, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {useState} from 'react';
import ReactImagePickerEditor, {ImagePickerConf} from 'react-image-picker-editor';
import useAuth from '@panel/hooks/useAuth';
import {AxiosResponse} from 'axios';
import {api} from '@panel/utils';
import {toast} from 'react-toastify';

const config: ImagePickerConf = {
  borderRadius: '8px',
  language: 'en',
  objectFit: 'contain',
  compressInitial: null,
};

export const EditProfilePictureModal = ({onClose}: { onClose: () => void }) => {

  const {user, updateAuthUser} = useAuth();
  const [file, setFile] = useState<File>();

  const handleImgSelected = async (image: string) => {
    if (image) {
      const response = await fetch(image);
      const blob = await response.blob();
      const extension = image.split(';')[0].split('/')[1];
      setFile(new File([blob], `${user?.id}.${extension}`));
    } else {
      setFile(undefined);
    }
  }

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      api['users'].post(`/${user?.id}/profile-picture`, formData).then((res: AxiosResponse) => {
        updateAuthUser({...res.data, avatar: res.data.avatarURL});
        toast.info('User avatar updated');
        onClose();
      }, () => {
        toast.error('Unable to update user avatar')
      });
    }
  }

  return (
    <Dialog maxWidth={'lg'} fullWidth={false} disableScrollLock onClose={onClose} open={true}>
      <Card sx={{
        px: 6,
        pt: 2,
        pb: 2,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'background.default',
        overflowY: 'auto',
      }}>
        <Box sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}>
          <IconButton onClick={onClose}>
            <CloseIcon/>{' '}
          </IconButton>
        </Box>
        <Box sx={{
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <Typography variant="h3" align="center">
            Edit Profile Picture
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
            <ReactImagePickerEditor config={config} imageChanged={handleImgSelected}/>
          </Typography>
        </Box>
        <Button fullWidth
                onClick={handleUpload}
                disabled={file === undefined}
                variant="contained"
                color="primary"
                sx={{mt: 2, height: '40px'}}>
          <Typography variant="body2">Save Picture</Typography>
        </Button>
      </Card>
    </Dialog>
  )
}
