import {StrictMode} from 'react';
import * as ReactDOM from 'react-dom';

import App from './app/app';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {environment} from '@panel/environments/environment';

const stripePromise = loadStripe(environment.stripeKey);

ReactDOM.render(
  <StrictMode>
    <Elements stripe={stripePromise}>
      <App/>
    </Elements>
  </StrictMode>,
  document.getElementById('root')
);
