/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Button, Card, Dialog, Grid, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import {formatPrice} from '@brix/shared-frontend';

type ResaleOptions = {
  open: boolean;
  brixAmount: number;
  brixieAmount: number;
  staxAmount: number;
  selectedPropertyId: string | undefined;
  onClose: () => void;
};

const ResaleSchema = Yup.object().shape({
  stax: Yup.number()
    .typeError('Stax must be a number')
    .min(0, 'Stax value must be more than $0.00')
    .required('This field is required'),
  brix: Yup.number()
    .typeError('BRiX must be a number')
    .min(0, 'BRiX value must be more than $0.00')
    .required('This field is required'),
  brixie: Yup.number()
    .typeError('Brixie must be a number')
    .min(0, 'Brixie value must be more than $0.00')
    .required('This field is required'),
  price: Yup.number()
    .typeError('Price must be a number')
    .min(0.01, 'Price value must be more than $0.00')
    .required('This field is required'),
});

const BrixSelectionModal = ({open, brixieAmount, brixAmount, staxAmount, onClose}: ResaleOptions) => {

  const [total, setTotal] = useState<number>(0);
  const {register, setValue, watch, handleSubmit, formState} = useForm({
    resolver: yupResolver(ResaleSchema),
    mode: "all",
  });

  useEffect(() => {
    const subscription = watch((value) => {
      const {brix, brixie, stax, price} = value;
      const total = Number(price ?? 0) * (Number(brixie ?? 0) + (Number(brix ?? 0) * 10) + (Number(stax ?? 0) * 100));
      setTotal(total)
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setValue('brix', brixAmount, {shouldValidate: true});
    setValue('brixie', brixieAmount, {shouldValidate: true});
    setValue('stax', staxAmount, {shouldValidate: true});
  }, []);


  const handleClose = () => {
    onClose();
  };


  const handleSaleBrixies = (data: any) => {
    onClose();
  }

  return (
    <Dialog disableScrollLock onClose={handleClose} open={open}>
      <Card sx={{px: 6, pt: 2, pb: 2, border: 'none', boxShadow: 'none', backgroundColor: 'background.default'}}>
        <form onSubmit={handleSubmit(handleSaleBrixies)}>
          <Box sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
            <IconButton onClick={handleClose}>
              <CloseIcon/>{' '}
            </IconButton>
          </Box>
          <Box sx={{
            position: 'absolute',
            left: 16,
            top: 16,
          }}>
            <Tooltip arrow title={'BRiX will be available to co-owners for 7 days'}>
              <InfoIcon color={'secondary'} fontSize="small" sx={{cursor: 'pointer'}}/>
            </Tooltip>
          </Box>
          <Box sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <Typography variant="h3" align="center">
              Resale My BRiX
            </Typography>
            <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 2}}>
              Enter the price of BRiX to sale:
            </Typography>
          </Box>
          <Grid item xs={12}>
            <Box sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              px: {xs: 2, md: 16},
            }}
            >
              <Grid container>
                <Grid item xs={2}>
                  <AttachMoneyOutlinedIcon sx={{mt: 2, opacity: 0.7}}/>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    {...register('price')}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      boxShadow: 'rgb(0, 0, 0, 0.1) 6px 6px 10px 1px',
                      borderRadius: '4px',
                    }}
                    type="text"
                    inputMode="numeric"
                    placeholder="$"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Box sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <Typography variant="h6" sx={{py: 3}}>
              - AND -
            </Typography>
            <Typography color="gray" align="center" variant="subtitle1" sx={{mt: -1, mb: 2}}>
              Enter the amount of BRiX to sale:
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Card
                  sx={{
                    borderStyle: 'solid',
                    borderColor: 'rgba(0,0,0,.15)',
                    borderWidth: 2,
                    boxShadow: 'none',
                    opacity: brixieAmount > 0 ? 1 : .5,
                    width: '140px',
                    height: '140px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img style={{width: '20px', height: '20px'}}
                       src="../assets/images/brick-wizard3.png"
                       alt="brick"/>
                  <Typography
                    justifyContent="center"
                    color="primary"
                    variant={'body2'}
                    sx={{mt: 2, fontSize: '12px', textTransform: 'uppercase'}}
                  >
                    stax
                  </Typography>
                  <TextField
                    {...register('stax')}
                    className={'centered-input'}
                    disabled={staxAmount === 0}
                    type="text"
                    variant="standard"
                    inputMode="numeric"
                    sx={{pt: 1, px: 6}}
                  />
                  <Typography justifyContent="center" variant={'body2'} sx={{mt: 2, fontSize: '12px'}}>
                    {'0 stax available'}
                  </Typography>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Card sx={{
                  borderStyle: 'solid',
                  borderColor: 'rgba(0,0,0,.15)',
                  borderWidth: 2,
                  boxShadow: 'none',
                  opacity: brixieAmount > 0 ? 1 : .5,
                  width: '140px',
                  height: '140px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <img style={{width: '20px', height: '20px'}}
                       src="../assets/images/brick-wizard2.png"
                       alt="BRiX"/>
                  <Typography
                    justifyContent="center"
                    color="primary"
                    variant={'body2'}
                    sx={{mt: 2, fontSize: '12px', textTransform: 'uppercase'}}
                  >
                    BRiX
                  </Typography>
                  <TextField
                    {...register('brix')}
                    className={'centered-input'}
                    disabled={brixAmount === 0}
                    type="text"
                    variant="standard"
                    inputMode="numeric"
                    sx={{pt: 1, px: 6}}
                  />
                  <Typography justifyContent="center" variant={'body2'} sx={{mt: 2, fontSize: '12px'}}>
                    {'0 BRiX available'}
                  </Typography>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Card
                  sx={{
                    borderStyle: 'solid',
                    borderColor: 'rgba(0,0,0,.15)',
                    borderWidth: 2,
                    boxShadow: 'none',
                    opacity: brixieAmount > 0 ? 1 : .5,
                    width: '140px',
                    height: '140px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img style={{width: '20px', height: '20px'}}
                       src="../assets/images/brick-wizard.png"
                       alt="brick"/>
                  <Typography
                    justifyContent="center"
                    color="primary"
                    variant={'body2'}
                    sx={{mt: 2, fontSize: '12px', textTransform: 'uppercase'}}
                  >
                    BRiXieS
                  </Typography>
                  <TextField
                    {...register('brixie')}
                    disabled={brixieAmount === 0}
                    className={'centered-input'}
                    type="text"
                    variant="standard"
                    inputMode="numeric"
                    sx={{pt: 1, px: 6}}
                  />
                  <Typography justifyContent="center" variant={'body2'} sx={{mt: 2, fontSize: '12px'}}>
                    {`${brixieAmount} Brixies Owned`}
                  </Typography>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Button
                type={'submit'}
                disabled={!formState.isValid}
                fullWidth
                variant="contained"
                color="primary"
                sx={{mt: 2, height: '40px'}}>
                <Typography variant="body2">Put on Sale</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  pt: 3,
                  pb: 4,
                }}
              >
                <Typography sx={{textTransform: 'uppercase'}} variant="body2">
                  Total ($)
                  <Tooltip title="Not including fees">
                    <IconButton>
                      <InfoIcon color="secondary"/>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{flexGrow: 1}}/>
                <Typography variant="h5">{formatPrice(total)}</Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Dialog>
  );
};

export default BrixSelectionModal;
