import {createContext, FunctionComponent, ReactElement, useContext, useEffect, useState} from 'react';
import {api} from '@panel/utils';
import {AxiosResponse} from 'axios';
import useAuth from '@panel/hooks/useAuth';
import {IWalletBalance} from '@panel/interfaces';

export type WalletProviderParam = { children: ReactElement };

interface WalletProviderValue {
  walletBalance?: IWalletBalance,
  needsToRefetch?: () => void,
  isWalletLoading?: boolean,
}

export const WalletContext = createContext<WalletProviderValue>({walletBalance: {available: 0, total: 0}});

export const WalletProvider: FunctionComponent<WalletProviderParam> = ({children}: WalletProviderParam) => {
  const {user, isAuthenticated} = useAuth();
  const [balance, setBalance] = useState<IWalletBalance>();
  const [isWalletLoading, setIsWalletLoading] = useState(false);

  useEffect(() => {
    isAuthenticated && user && needsToRefetch();
  }, [user]);

  const needsToRefetch = () => {
    loadWallet();
  }

  const loadWallet = (): void => {
    const userId = user?.id;
    setIsWalletLoading(true);
    api['users'].get(`/${userId}/wallet/balance`).then((response: AxiosResponse) => {
      setBalance(response.data);
      setIsWalletLoading(false);
    });
  };

  const getValues = (): WalletProviderValue => {
    return {
      walletBalance: balance,
      needsToRefetch,
      isWalletLoading
    };
  }

  return (
    <WalletContext.Provider
      value={getValues()}>
      {children}
    </WalletContext.Provider>
  );
}

export const useWalletContext = () => {
  return useContext(WalletContext);
};

export const WalletConsumer = WalletContext.Consumer;
