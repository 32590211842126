import {Link as RouterLink} from 'react-router-dom';
import {IPage} from '@brix/shared-frontend';
import {Box, Card, Container, Divider, Typography} from '@mui/material';
import ResetPasswordForm from './ResetPasswordForm';
import Logo from '@panel/components/Logo';
import Page from '@panel/components/Page';

const PasswordResetView: React.FC<IPage> = () => {
  return (
    <Page title="Recover password">
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: 2,
              md: 0,
              lg: 0,
            },
          }}
        >
          <Card elevation={16} sx={{p: 4, border: 'none', boxShadow: 'none'}}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <RouterLink to="/">
                <Logo height={80} width={80} />
              </RouterLink>
              <Typography variant="h4">Password Reset</Typography>
              <Typography color="textSecondary" sx={{mt: 2}} variant="body2">
              Enter your new password and confirm it
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                  width: '100%',
                }}
              >
                <ResetPasswordForm />
              </Box>
            </Box>
            <Divider sx={{my: 3}} />
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default PasswordResetView;
