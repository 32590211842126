import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import useAuth from '@panel/hooks/useAuth';
import {makeStyles} from '@mui/styles';
import {PopoverMenuProps} from '@panel/interfaces/popover-menu';
import {getInitials} from '@panel/utils/get-initials';

const useStyles = makeStyles((theme: {spacing: (arg0: number) => string}) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    pointerEvents: 'auto',
    padding: theme.spacing(1),
  },
}));

export const AccountPopover = ({anchorEl, onClose, open, ...other}: PopoverMenuProps) => {
  const classes = useStyles();
  const {logout, user} = useAuth();

  const history = useHistory();

  const handleLogout = async () => {
    try {
      onClose?.();
      await logout(user?.email || '');
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  const handleSettingsClicked = () => {
    onClose?.();
    history.push('/profile');
  };

  return (
    <Popover
      id="mouse-over-popover"
      classes={{
        paper: classes.paper,
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      disableScrollLock
      PaperProps={{sx: {width: 300}}}
      transitionDuration={100}
      sx={{
        pointerEvents: 'none',
      }}
      {...other}
    >
      <MenuItem
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
          '&:hover': {cursor: 'pointer'},
        }}
        onClick={handleSettingsClicked}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
            backgroundColor: '#508cb8',
          }}
          src={user?.avatarURL}
        >
          {getInitials(user?.firstName + ' ' + user?.lastName)}
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">{user?.firstName + ' ' + user?.lastName}</Typography>
          <Typography color="textSecondary" variant="body2">
            {user?.email}
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      <Box sx={{my: 1}}>
        <MenuItem onClick={handleSettingsClicked}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Settings</Typography>} />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
