// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  apiUrl: 'https://api.brix-stag.2amigos.us',
  apiPrefix: '/v1',
  stripeKey: 'pk_test_51Kik6oJrXkvRT9XWfftfOczbKl3qF7GBbqaZIdg5ekxns7HmIBGLw32K7gUhQvt7eHkgzjSWN6cpxmDXv25uZAGS00vO3G3M8P',
  googleMapsApiKey: 'AIzaSyDE8wO1HtbgROb7MAv0COqfUguOuol3HL0',
};
