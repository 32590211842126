import {
  Avatar,
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {ICartProduct} from '@panel/interfaces';
import React, {useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import useCart from '@panel/hooks/cart/useCart';
import {getInitials} from '@panel/utils/get-initials';
import {useHistory} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {toast} from 'react-toastify';
import {Scrollbar} from '@panel/components';
import {formatPrice} from '@brix/shared-frontend';
import BrixSelectionModal from '@panel/components/BrixSelectionModal';

const MyCart: React.FC = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [isBrixModalOpen, setIsBrixModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const [selectedValue, setSelectedValue] = useState('');
  const {products, total, removeSelectedProducts} = useCart();

  const handleSelectAllProducts = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProducts(event.target.checked ? products.map((product) => product.propertyId) : []);
  };

  const handleSelectOneProduct = (propertyId: string) => {
    if (!selectedProducts.includes(propertyId)) {
      setSelectedProducts((prevSelected: string[]) => [...prevSelected, propertyId]);
    } else {
      setSelectedProducts((prevSelected: string[]) => prevSelected.filter((id: string) => id !== propertyId));
    }
  };

  const handleClickOnRemoveIcon = (product: ICartProduct) => {
    setSelectedProducts([product.propertyId]);
    setOpen(true);
  };

  const handleRemoveSelectedProducts = () => {
    removeSelectedProducts(selectedProducts);
    setSelectedProducts([]);
    setTimeout(() => {
      setOpen(false);
    }, 300);
    toast.success('Items removed from cart', {
      position: 'top-left',
      pauseOnHover: false,
      closeOnClick: true,
      autoClose: 3000,
    });
  };

  const handleCloseBrixModal = (value: string) => {
    setIsBrixModalOpen(false);
    setSelectedValue(value);
  };

  const handleOpenBrixModal = (property: any) => {
    setSelectedProperty(property);
    setIsBrixModalOpen(true);
  };

  const enableBulkActions = selectedProducts.length > 0;
  const selectedSomeProducts = selectedProducts.length > 0 && selectedProducts.length < products.length;
  const selectedAllProducts = selectedProducts.length === products.length && enableBulkActions;

  return (
    <Box sx={{ml: {xs: 0, sm: 4}}}>
      <Card color="white">
        <Grid container justifyContent={'space-between'} spacing={0} sx={{px: 2, py: 2}}>
          <Typography variant="subtitle2">My Cart ({total.propertyQuantity})</Typography>
          <Box sx={{flexGrow: 1}} />
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderRadius: '5px',
              color: 'gray',
              borderColor: 'rgba(0,0,0,0.1)',
              height: '30px',
              my: {xs: 0, sm: 0},
            }}
            onClick={() => history.push(`/marketplace`)}
          >
            {'Back to marketplace'}
          </Button>
        </Grid>
        <Scrollbar>
          {products.length > 0 ? (
            <Table sx={{minWidth: 700}}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      disabled={enableBulkActions}
                      checked={selectedAllProducts}
                      indeterminate={selectedSomeProducts}
                      onChange={handleSelectAllProducts}
                    />
                  </TableCell>
                  <TableCell>PROPERTY NAME</TableCell>
                  <TableCell>INVESTMENT AMOUNT</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => {
                  const isProductSelected = selectedProducts.includes(product.propertyId);
                  return (
                    <TableRow hover key={product.id} selected={isProductSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isProductSelected}
                          onChange={() => handleSelectOneProduct(product.propertyId)}
                          value={isProductSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Avatar
                            src={product.propertyImage}
                            variant="square"
                            alt={product.propertyName}
                            sx={{
                              height: 60,
                              width: 60,
                              borderRadius: '5px',
                              bgcolor: 'rgba(0,0,0,0.1)',
                            }}
                          >
                            {getInitials(product.propertyName)}
                          </Avatar>
                          <Box sx={{ml: 2}}>
                            <Typography variant="subtitle2" sx={{mb: 1}}>
                              {product.propertyName}
                            </Typography>
                            <Typography color="textSecondary" variant="body1">
                              {product.propertyAddress}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography color="gray" variant="body2">
                          {formatPrice(product.currentValue * product.quantity)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleOpenBrixModal(product)}>
                          <EditIcon color="primary" fontSize="medium" />
                        </IconButton>
                        <IconButton onClick={() => handleClickOnRemoveIcon(product)}>
                          <DeleteForeverOutlinedIcon color="primary" fontSize="medium" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Alert severity="info">
              <Typography variant="body2" sx={{fontWeight: 600}}>
                Your cart is empty.
              </Typography>
            </Alert>
          )}
        </Scrollbar>
      </Card>
      {products.length > 0 && selectedProducts.length > 0 && (
        <Button
          sx={{my: 1}}
          onClick={() => selectedProducts.length >= 1 && setOpen(true)}
          startIcon={<CloseIcon fontSize="small" />}
        >
          <Typography
            color="primary"
            sx={{textTransform: 'none', fontSize: '13px', fontWeight: 600, opacity: 0.8}}
            variant="body2"
          >
            Remove selected properties
          </Typography>
        </Button>
      )}
      <Dialog disableScrollLock open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 1,
          }}
        >
          <DialogTitle sx={{textAlign: 'center'}} color="black">
            {'Are you sure you want to remove the selected items from your cart?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleRemoveSelectedProducts}>Yes</Button>
            <Button onClick={() => setOpen(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      {isBrixModalOpen && (
        <BrixSelectionModal
          selectedPropertyId={selectedProperty?.id}
          selectedProperty={{
            propertyId: selectedProperty?.id,
            propertyName: selectedProperty?.propertyName,
            propertyAddress: selectedProperty?.propertyAddress,
            propertyImage: selectedProperty?.propertyImage,
          }}
          selectedValue={selectedValue}
          open={isBrixModalOpen}
          onClose={handleCloseBrixModal}
        />
      )}
    </Box>
  );
};
export default MyCart;
