import { useContext, useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Button, Card, Checkbox, Container, Grid, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import RegisterContext from '@panel/contexts/RegisterContext';
import PersonalInformationVerification from './Verification';
import api from '@panel/utils/api';
import { IUser, IToken, errorHandler, useYupValidationResolver, firstLetterUppercase } from '@brix/shared-frontend';
import { IRegistrationWizard, RegisterWizardProps, IPersonalInfo } from '@panel/interfaces';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PrivacyPolicy } from '@panel/components/PrivacyPolicy';
import { TermsOfService } from '@panel/components/TermsOfService';

import { PersonalInfoValidation } from '../../validations';
import { onCreateAnswer, onCreateProfile, onCreateUser } from '../../utils';

const PersonalInformation = ({ prevStep, nextStep, direction }: IRegistrationWizard) => {
  const resolver = useYupValidationResolver(PersonalInfoValidation);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const registerContext = useContext(RegisterContext);
  const [accreditedInvestor, setAccreditedInvestor] = useState(false)
  const [accreditedInvestorDialog, setAccreditedInvestorDialog] = useState(false)
  const [nonUSPersonDialog, setNonUSPersonDialog] = useState(false)
  const [privacyPolicyDialog, setPrivacyPolicyDialog] = useState(false)
  const [termsOfServiceDialog, setTermsOfServiceDialog] = useState(false)

  const handleCloseAccreditedInvestorDialog = () => {
    setAccreditedInvestorDialog(false);
  };

  const handleCloseNonUSPersonDialog = () => {
    setNonUSPersonDialog(false);
  };

  const handleClosePrivacyPolicyDialog = () => {
    setPrivacyPolicyDialog(false);
  };

  const handleCloseTermsOfServiceDialog = () => {
    setTermsOfServiceDialog(false);
  };

  const { register, handleSubmit, formState, setError, clearErrors, setValue, control } = useForm<IPersonalInfo>({
    resolver,
  });
  const { errors } = formState;

  const handleBackButton = () => {
    isSubmitted ? setIsSubmitted(false) : prevStep && prevStep();
  };

  const onRegisterUser = async (registerData: RegisterWizardProps) => {
    try {
      const response = await onCreateUser(registerData);
      if (response.status === 201) {
        const { tokens, user }: { tokens: IToken; user: IUser } = response.data;
        const userToken = tokens.access.token;
        await onCreateProfile(user.id, registerData.phone, userToken);
        await onCreateAnswer(
          user.id,
          registerContext?.state.securityQuestion1,
          registerContext?.state.securityAnswer1,
          userToken
        );
        registerContext?.dispatch({
          type: 'SET_DATA',
          payload: { isUserCreated: true, id: user.id, isQuestionCreated: true, userToken, response: { tokens, user } },
        });
      }
      setIsSubmitted(true);
    } catch (error: any) {
      setRegisterError(errorHandler(error?.data));
    }
  };

  const validateEmail = async (event: any) => {
    const schema = Yup.string().email();
    const isEmailValid = schema.isValidSync(event.target.value);
    if (isEmailValid) {
      try {
        await api['users'].post('/validate-email', { email: event.target.value }, { data: { skipAuthRefresh: true } });
        clearErrors('email');
      } catch (e: any) {
        setError('email', { type: 'manual', message: errorHandler(e.data) });
      }
    }
  };

  const updateUserData = async (userData: RegisterWizardProps) => {
    setIsSubmitted(true);
  };

  const onSubmit = async (data: any) => {
    data.firstName = firstLetterUppercase(data.firstName)
    data.lastName = firstLetterUppercase(data.lastName)
    setLoading(true);
    if (registerContext?.state.isUserCreated) {
      await updateUserData(data);
    } else {
      await onRegisterUser({ ...registerContext?.state, ...data });
    }
    registerContext?.dispatch({ type: 'SET_DATA', payload: data });
    if (isSubmitted && registerContext?.state.isEmailVerified && registerContext?.state.isPhoneVerified) {
      nextStep && nextStep();
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (registerContext?.state?.firstName) {
      setValue('email', registerContext?.state?.email);
      setValue('firstName', registerContext?.state?.firstName);
      setValue('lastName', registerContext?.state?.lastName);
      setValue('phone', registerContext?.state?.phone);
      if (
        direction === 'backward' &&
        registerContext?.state.isEmailVerified &&
        registerContext?.state.isPhoneVerified
      ) {
        setIsSubmitted(true);
      }
    }
  }, []);

  return (
    <Container maxWidth="sm" sx={{ pt: { xs: 2, md: 0, lg: 0 } }}>
      <Card sx={{ p: 2, border: 'none', boxShadow: 'none' }}>
        {!isSubmitted ? (
          <>
            <Typography variant="h3" align="center">
              Personal Information
            </Typography>
            <Typography color="gray" align="center" variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
              Please complete your account information (1/2)
            </Typography>
            <form noValidate onSubmit={onSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12} md={6} sx={{ width: { xs: '350px', sm: '100%' } }}>
                  <Typography sx={{ mt: 2 }} variant="body2">
                    FIRST NAME
                  </Typography>
                  <TextField
                    {...register('firstName')}
                    error={Boolean(errors?.['firstName'])}
                    fullWidth
                    autoFocus
                    helperText={errors?.['firstName']?.message}
                    margin="normal"
                    name="firstName"
                    type="firstName"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ width: { xs: '350px', sm: '100%' } }}>
                  <Typography sx={{ mt: 2 }} variant="body2">
                    LAST NAME
                  </Typography>
                  <TextField
                    {...register('lastName')}
                    error={Boolean(errors?.['lastName'])}
                    fullWidth
                    helperText={errors?.['lastName']?.message}
                    margin="normal"
                    name="lastName"
                    type="lastName"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sx={{ width: { xs: '350px', sm: '100%' } }}>
                  <Typography variant="body2">EMAIL ADDRESS</Typography>
                  <TextField
                    {...register('email')}
                    error={Boolean(errors?.['email'])}
                    fullWidth
                    helperText={errors?.['email']?.message}
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    onChange={validateEmail}
                  />
                </Grid>
                <Grid item xs={12} sx={{ width: { xs: '350px', sm: '100%' } }}>
                  <Typography variant="body2">PHONE NUMBER</Typography>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => {
                      return (
                        <PhoneInput
                          {...field}
                          containerStyle={{ marginTop: '15px' }}
                          inputStyle={{ width: '100%', height: '50px' }}
                          country={'us'}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                          }}
                          onChange={(phone: string) => {
                            setValue('phone', '+' + phone);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              {Boolean(registerError) && (
                <Alert severity="error">
                  <Typography variant="body2">{registerError}</Typography>
                </Alert>
              )}
              <Typography variant="body2">
                <Checkbox
                  checked={registerContext?.state.isTermsAccepted}
                  onChange={(event) => {
                    registerContext?.dispatch({ type: 'SET_DATA', payload: { isTermsAccepted: event.target.checked } });
                  }}
                />
                By creating an account, you agree to our <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setPrivacyPolicyDialog(true)}> Privacy Policy</span>, and{' '}
                <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setTermsOfServiceDialog(true)} >Terms of Service</span>.
              </Typography>
              <Typography variant="body2">
                <Checkbox
                  checked={registerContext?.state.accreditedInvestor}
                  onChange={(event) => {
                    registerContext?.dispatch({ type: 'SET_DATA', payload: { accreditedInvestor: event.target.checked } });
                  }}
                />
                By checking this box I acknowledge that I am either an <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setAccreditedInvestorDialog(true)} > “Accredited Investor”</span> or a <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setNonUSPersonDialog(true)} >“Non-U.S. Person”</span>.
              </Typography>
            </form>
          </>
        ) : (
          <PersonalInformationVerification />
        )}

        <Grid container mt={2} spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              sx={{ color: '#000000', borderColor: '#000000' }}
              onClick={handleBackButton}
              fullWidth
              size="large"
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <LoadingButton
              color="primary"
              loading={loading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={
                (!registerContext?.state.isTermsAccepted || !registerContext.state.accreditedInvestor) ||
                (isSubmitted && (!registerContext?.state.isEmailVerified || !registerContext?.state.isPhoneVerified))
              }
              onClick={handleSubmit(onSubmit)}
            >
              Continue
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
      <Dialog
        open={accreditedInvestorDialog}
        onClose={handleCloseAccreditedInvestorDialog}
        maxWidth={'md'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Accredited Investor</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            An accredited investor, in the context of a natural person, includes anyone who: <br></br>
            <ul>
              <li>Earned income that exceeded $200,000 (or $300,000 together with a spouse or spousal equivalent) in each of the prior two years, and reasonably expects the same for the current year, OR</li>
              <li>Has a net worth over $1 million, either alone or together with a spouse or spousal equivalent (excluding the value of the person's primary residence), OR</li>
              <li>Holds in good standing a Series 7, 65 or 82 license.</li>
              <li>
                In addition:
                <ul>
                  <li>Any trust, with total assets in excess of $5 million, not formed specifically to purchase the subject securities, whose purchase is directed by a sophisticated person, OR</li>
                  <li>Certain entity with total investments in excess of $5 million, not formed to specifically purchase the subject securities, OR</li>
                  <li>Any entity in which all of the equity owners are accredited investors.</li>
                </ul>
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAccreditedInvestorDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={nonUSPersonDialog}
        onClose={handleCloseNonUSPersonDialog}
        maxWidth={'md'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Non-U.S. Person</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <ol>
              <li>
                A “Non-U.S. person” means someone who is NOT:
                <ul>
                  <li>Any natural person resident in the United States.</li>
                  <li>Any partnership or corporation organized or incorporated under the laws of the United States.</li>
                  <li>Any estate of which any executor or administrator is a U.S. person.</li>
                  <li>Any trust of which any trustee is a U.S. person.</li>
                  <li>Any agency or branch of a foreign entity located in the United States.</li>
                  <li>Any non-discretionary account or similar account (other than an estate or trust) held by a dealer or other fiduciary for the benefit or account of a U.S. person.</li>
                  <li>Any discretionary account or similar account (other than an estate or trust) held by a dealer or other fiduciary organized, incorporated, or (if an individual) resident in the United States; and</li>
                  <li>
                    Any partnership or corporation if:
                    <ul>
                      <li>Organized or incorporated under the laws of any foreign jurisdiction; and</li>
                      <li>Formed by a U.S. person principally for the purpose of investing in securities not registered under the Act, unless it is organized or incorporated, and owned, by accredited investors (as defined in § 230.501(a)) who are not natural persons, estates or trusts.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                The following are not “U.S. persons”:
                <ul>
                  <li>Any discretionary account or similar account (other than an estate or trust) held for the benefit or account of a non-U.S. person by a dealer or other professional fiduciary organized, incorporated, or (if an individual) resident in the United States.</li>
                  <li>
                    Any estate of which any professional fiduciary acting as executor or administrator is a U.S. person if:
                    <ul>
                      <li>An executor or administrator of the estate who is not a U.S. person has sole or shared investment discretion with respect to the assets of the estate; and</li>
                      <li>The estate is governed by foreign law.</li>
                    </ul>
                  </li>
                  <li>Any trust of which any professional fiduciary acting as trustee is a U.S. person, if a trustee who is not a U.S. person has sole or shared investment discretion with respect to the trust assets, and no beneficiary of the trust (and no settlor if the trust is revocable) is a U.S. person.</li>
                  <li>An employee benefit plan established and administered in accordance with the law of a country other than the United States and customary practices and documentation of such country.</li>
                  <li>
                    Any agency or branch of a U.S. person located outside the United States if:
                    <ul>
                      <li>The agency or branch operates for valid business reasons; and</li>
                      <li>The agency or branch is engaged in the business of insurance or banking and is subject to substantive insurance or banking regulation, respectively, in the jurisdiction where located; and</li>
                    </ul>
                  </li>
                  <li>The International Monetary Fund, the International Bank for Reconstruction and Development, the Inter-American Development Bank, the Asian Development Bank, the African Development Bank, the United Nations, and their agencies, affiliates and pension plans, and any other similar international organizations, their agencies, affiliates and pension plans.</li>
                </ul>
              </li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNonUSPersonDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={privacyPolicyDialog}
        onClose={handleClosePrivacyPolicyDialog}
        maxWidth={'md'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Privacy Policy</DialogTitle>
        <DialogContent dividers={true}>
          <Container>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Box sx={{ maxWidth: '800px', pb: 3 }}>
                <PrivacyPolicy />
              </Box>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrivacyPolicyDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={termsOfServiceDialog}
        onClose={handleCloseTermsOfServiceDialog}
        maxWidth={'md'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms of Service</DialogTitle>
        <DialogContent dividers={true}>
          <Container>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Box sx={{ maxWidth: '800px', pb: 3 }}>
                <TermsOfService />
              </Box>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTermsOfServiceDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PersonalInformation;
