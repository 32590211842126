import {Box, Button, Container, Divider, Typography} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import Page from '@panel/components/Page';
import React, {useEffect, useState} from 'react';
import InputCode from '../../components/InputCode';
import {api} from '@panel/utils';
import {AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
import axiosInstance from '@panel/utils/axios';
import useQueryParams from '@panel/hooks/useQuery';

const ValidateMFAView: React.FunctionComponent<IPage> = (props) => {
  const {redirectUri} = useQueryParams();
  const [verifying, setVerifying] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const {data} = JSON.parse(localStorage.getItem('accessTokens') ?? '{}');
    const token = JSON.parse(data ?? '{}')?.access?.token;
    if (token) {
      const {sub} = JSON.parse(atob(token.split('.')[1]));
      setUserId(sub);
      sendCode(sub);
    } else {
      location.href = 'login';
    }
  }, []);

  const sendCode = (sub: string) => {
    setVerifying(true);
    api['/mfa'].post(`/${userId ?? sub}/send`).then(() => {
      toast.success('Code sent to your email.');
    }, () => {
      toast.error('Unable to send code. Please try again later.')
    }).finally(() => setVerifying(false));
  }

  const validate = () => {
    setVerifying(true);
    api['/mfa'].post(`/${userId}/validate?code=` + code).then((response: AxiosResponse) => {
      const data = response.data;
      localStorage.setItem('mfaTicket', data.ticket);
      axiosInstance.defaults.headers.common['x-auth-ticket'] = data.ticket;
      location.href = redirectUri ?? '/';
    }, () => {
      toast.error('Invalid code. Please try again with another one.')
    }).finally(() => setVerifying(false));
  }

  return (
    <Page title={props.name ?? ''}>
      <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '20px 20px',
          pt: 8,
          px: 4,
          margin: 4,
          backgroundColor: 'background.paper',
        }}>
        <Container maxWidth="sm" sx={{pt: {xs: 2, md: 0, lg: 0}}}>
          <Typography variant="h3" align="center">
            MFA Validation
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 1}}>
            We've sent you a validation code to your registered email.
          </Typography>
          <Box sx={{mt: 4, textAlign: 'center'}}>
            <Typography variant="body2">Input code here</Typography>
            <Divider sx={{my: 1}}/>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <InputCode
                length={6}
                loading={verifying}
                onComplete={(code: string) => {
                  setCode(code);
                }}
              />
            </Box>
          </Box>

          <Box mt={2} mb={4}>
            <Button color="primary"
                    fullWidth
                    disabled={code === undefined}
                    onClick={validate}
                    size="large"
                    type="submit"
                    variant="contained">
              Validate
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ValidateMFAView;
