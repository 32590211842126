import useAuth from '@panel/hooks/useAuth';
import PropTypes from 'prop-types';
import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';

const AuthGuard = ({children}: { children: React.ReactElement }) => {
  const {isAuthenticated, needsToValidate} = useAuth();
  const location = useLocation();
  if (needsToValidate) {
    return <Redirect to={`/validate?redirectUri=${location.pathname}`}/>;
  } else if (!isAuthenticated) {
    return <Redirect to={`/login?redirectUri=${location.pathname}`}/>;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
