import {Box, Card, Container, Divider, Grid, SvgIcon, Typography} from '@mui/material';
import MonetizationOnIconOutlined from '@mui/icons-material/MonetizationOnOutlined';
import React, {useEffect, useState} from 'react';
import useCart from '@panel/hooks/cart/useCart';
import {Image} from 'mui-image';
import {calcBrixFeeAmount, formatBrixies, formatWithDigits, IPaymentMethod} from '@brix/shared-frontend';
import StepperButtons from './StepperButtons';
import moment from 'moment';
import {useWalletContext} from '@panel/contexts/WalletContext';
import {FeesType, useFeesContext} from '@panel/contexts/FeesContext';
import {ICartProduct} from '@panel/interfaces';
import {ReviewBrixItem} from '@panel/views/marketplace/CheckoutView/CheckoutWizard/ReviewBrixItem';

type BrixInitialFee = { brix?: Partial<FeesType>, stax?: Partial<FeesType>, brixie?: Partial<FeesType> };

const initialFees: BrixInitialFee = {
  brix: {
    type: 'buyBrix',
    amount: 0,
    isPercentage: false
  },
  stax: {
    type: 'buyStax',
    amount: 0,
    isPercentage: false
  },
  brixie: {
    type: 'buyBrixie',
    amount: 0,
    isPercentage: false
  }

}

const Review: React.FunctionComponent<{
  prevStep: () => void;
  nextStep: () => void;
  paymentMethod: IPaymentMethod | null | undefined;
  submitPayment: () => void;
  loading?: boolean;
}> = ({prevStep, loading, paymentMethod, submitPayment}) => {
  const {products} = useCart();
  const {walletBalance} = useWalletContext();
  const [fee, setFee] = useState<BrixInitialFee>(initialFees);
  const [grantTotal, setGrantTotal] = useState<number>(0);

  const {fees} = useFeesContext();

  useEffect(() => {
    if (fees) {
      const brix = fees?.find(fee => fee.type === 'buyBrix');
      const stax = fees?.find(fee => fee.type === 'buyStax');
      const brixie = fees?.find(fee => fee.type === 'buyBrixie');
      setFee({
        brix,
        stax,
        brixie
      })
    }
  }, [fees])

  useEffect(() => {
    if (fee && products) {
      const grantTotal = products.reduce((a: number, product: ICartProduct) => {
        const quantity = product.quantity, price = product.currentValue;
        const {brixie, brix, stax} = formatBrixies(quantity);
        const staxFee = calcBrixFeeAmount(stax * price * 100, fee.stax);
        const brixFee = calcBrixFeeAmount(brix * price * 10, fee.brix);
        const brixieFee = calcBrixFeeAmount(brixie * price, fee.brixie);
        return a + (price * quantity) + brixFee + brixieFee + staxFee;
      }, 0);
      setGrantTotal(grantTotal ?? 0);
    }
  }, [fees, products])

  return (
    <Container maxWidth="sm" sx={{pt: {xs: 2, md: 0, lg: 0,}}}>
      <Card sx={{p: 0, border: 'none', boxShadow: 'none'}}>
        <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Typography variant="h3" align="center">
            Review
          </Typography>
          <Typography color="gray" align="center" variant="subtitle1" sx={{mt: 2, mb: 4}}>
            Please review your information.
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>Payment Details</Typography>
            <Grid container spacing={2} sx={{p: 2}}>
              <Grid style={{padding: '10px 0 0'}} item xs={12}>
                <Divider/>
                {products.map((product: ICartProduct, index: number) => {
                  const quantity = product.quantity, price = product.currentValue;
                  const {brixie, brix, stax} = formatBrixies(quantity);
                  return (
                    <div key={index}>
                      <Grid sx={{paddingY: '10px'}} key={index} container spacing={0}>
                        <Grid item xs={10}>
                          <Typography variant="subtitle2" sx={{mb: 1,}}>
                            {product.propertyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography sx={{float: 'right'}} variant="body2">
                            {formatWithDigits(price * quantity)}
                          </Typography>
                        </Grid>
                        {brixie > 0 && <ReviewBrixItem fee={fee.brixie} amount={brixie} price={price} unit={'BRiXie'}/>}
                        {brix > 0 && <ReviewBrixItem fee={fee.brix} amount={brix} price={price * 10} unit={'BRiX'}/>}
                        {stax > 0 && <ReviewBrixItem fee={fee.stax} amount={stax} price={price * 100} unit={'Stax'}/>}
                      </Grid>
                      <Divider/>
                    </div>
                  );
                })}
              </Grid>

              <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'flex-start', mb: 2}}>
                  <Typography variant="body1" sx={{opacity: 0.7, marginLeft: '-16px'}}>
                    Total (USD)
                  </Typography>
                  <Box sx={{flexGrow: 1}}/>
                  <Typography variant="body2">
                    {formatWithDigits(grantTotal)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>Payment Method</Typography>
            <Grid container spacing={2} sx={{p: 2}}>
              <Grid key={paymentMethod?.id} item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
                  <Card
                    sx={{
                      px: 3,
                      py: 2,
                      boxShadow: 'none',
                      width: '100%',
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4} md={2}>
                        <Image
                          src={
                            paymentMethod?.type === 'wallet'
                              ? '../assets/images/wallet.png'
                              : paymentMethod?.brand === 'visa'
                                ? '../assets/images/visa.png'
                                : '../assets/images/mastercard.png'
                          }
                          alt={'BRiX'}
                          duration={50}
                          easing="ease-in-out"
                          width={'100%'}
                          height={'100%'}
                          fit="contain"
                        />
                      </Grid>
                      {paymentMethod?.type === 'wallet' && <Grid sx={{mt: 1}} item xs={8} md={10}>
                        <Typography variant="subtitle1" sx={{fontWeight: 400}}>
                          {'Current Financial Balance'}
                          <span style={{fontWeight: 600, display: 'block', color: '#CC4645', marginTop: '5px'}}>
                            {formatWithDigits(walletBalance?.total ?? 0)}
                          </span>
                        </Typography>
                      </Grid>}

                      {paymentMethod?.type !== 'wallet' && <Grid item xs={8} md={10}>
                        <Typography variant="subtitle1" sx={{fontWeight: 400}}>
                          **** **** **** <span style={{fontWeight: 600}}>{paymentMethod?.lastFourDigits}</span>
                        </Typography>
                      </Grid>}
                    </Grid>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{opacity: 0.6}}>
              <SvgIcon sx={{fontSize: '12px', opacity: 0.6}}>
                <MonetizationOnIconOutlined/>
              </SvgIcon>{' '}
              {`30 days hold until ` + moment(new Date()).add(1, 'M').format('MMMM Do YYYY, h:mm').toString()}
            </Typography>
          </Grid>
        </Grid>
        <StepperButtons prevStep={prevStep} nextStep={submitPayment}
                        disabled={paymentMethod?.type === 'wallet' && walletBalance?.available === 0}
                        loading={loading}/>
      </Card>
    </Container>
  );
};

export default Review;
