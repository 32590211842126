import { Typography } from '@mui/material';

export const PrivacyPolicy = () => {
    return (

        <Typography color="black" variant="subtitle2" sx={{ mb: 2 }}>
            Last updated 2022.07.07<br />

            Your privacy is important to us. This Privacy Policy (this “Policy”) applies to services provided by BRiX
            Holdings, Inc. (“we,” “us,” “our”, “BRiX”), as well as our affiliates and subsidiaries, and to our website
            (the “Site”), mobile app and/or other platform(s) (collectively, the “Services”) and explains what
            information we collect from users or visitors of our Services (each, a “user,” “you” or “your”), including
            information that may be used to personally identify you (“Personal Information”), and how we use it.
            We encourage you to read the details below. This Policy applies to any user of our Services. We will be
            the controller of your personal data provided to, collected by or for or processed in connection with our
            Services.<br />

            We reserve the right to change this Policy at any time. We will notify you of material changes to this
            Policy by sending notice to the primary email address specified in your account or by a prominent notice
            on our website. Changes and clarifications will take effect immediately. You should periodically check
            our website, including the page on which this Policy is located, for updates. You acknowledge that your
            continued use of our Services after we publish or send a notice about our changes to this Policy means
            that the collection, use and/or sharing of your Personal Information is subject to the updated Policy.<br />

            PLEASE BE AWARE THAT THE SITE IS CURRENTLY IN A TESTING PHASE AND NO SERVICES ARE BEING
            PROVIDED. THE SOLE FUNCTIONALITY OF THE SITE AT PRESENT IS TO COLLECT USER PROVIDED EMAIL
            CONTACT DETAILS IN ORDER TO CONTACT PERSONS WHO MAY HAVE INTEREST IN THE SERVICES IN
            THE FUTURE. THIS PRIVACY POLICY APPLIES TO THE COLLECTION OF SUCH PERSONAL DATA DURING
            THE TESTING PHASE.<br />

            What Information Do We Collect?<br />

            Information Provided by You<br />

            We collect information that you knowingly give us when you create an account, contact us or otherwise
            access or use our Services. This may include:<br />

            ● Your full name, email address, residential address, citizenship, telephone number, social security
            number, date of birth, employment information (including status and employer information, if
            applicable), information regarding investor “accreditation” and suitability, responses to surveys
            that we may ask you to complete for research purposes or to help direct BRiX activities and
            social media account information;<br />

            ● The contact information of your representative, if any; and/or<br />

            ● Details of any financial transactions you participate in on the Services, including the amount,
            currency and method of payment.<br />

            Information Collected Automatically<br />

            We automatically or passively collect information when you access or use our Services. This may include:<br />

            ● Device type used to access our website (i.e., computer or mobile device), unique number
            assigned to identify such device, your device&#39;s operating system, your device&#39;s application
            version and your device&#39;s internet protocol (IP) address;<br />

            ● Whenever you visit or interact with our website, information about how our website is accessed
            and used, including, but not limited to, click path taken through our website, your use of
            features or applications on our website and/or other publicly available information; this data is
            generally non-identifying, but, if we associate it with you as an identifiable person, we will treat
            it as Personal Information; and/or<br />

            ● If you access our Services from a mobile device, your device&#39;s unique mobile platform
            advertising identifier, such as Apple&#39;s “Identifier for Advertising ID” or Google&#39;s “Android
            Advertising ID” (“ mobile advertising identifiers”), and the ways you use the Services.

            How Do We Use the Information We Collect?<br />

            We use the information we collect to:<br />

            ● Deliver and improve the Services and your overall user experience;<br />

            ● Protect against, investigate and deter fraudulent, unauthorized and/or illegal activity;<br />

            ● Link or combine user information with other Personal Information;<br />

            ● Compare and verify information for accuracy and update our records;<br />

            ● Validate information for identity verification, including verification for anti-money laundering
            and “Know Your Customer” protocols;<br />

            ● Email, text message, message or otherwise contact you with information and updates about us
            and the Services, and respond to your comments and questions if you&#39;ve provided us with the
            means to do so;<br />

            ● On a de-identified, aggregated basis, analyze how you use the Services with tools like Google
            Analytics to help us understand traffic patterns and know if there are problems with the
            Services; and/or<br />

            ● Create targeted advertising to promote the Services and engage our users.<br />

            Do We Share Your Personal Information?<br />

            We do not rent, sell or share your Personal Information with third parties except with your consent or as
            necessary to complete any transaction or provide any service you have requested or authorized in this
            Policy.<br />

            In connection with the Services, we may provide your Personal Information to the following third
            parties:<br />

            ● Partners: Sometimes we collaborate with others, including our broker-dealer and escrow
            partners, to deliver the Services, and may provide such Personal Information as required by our
            partners who do not have an independent use of the information we disclose to them.<br />

            ● Affiliates: We may share your information with our affiliates and/or subsidiaries.<br />

            ● Service providers: We work with a wide range of third-party service providers, notably our
            database administrators, cloud computing services, advertising services, data analysts,
            application service providers, identity verification services and other non-governmental
            organizations, and may provide such information as required by any such service provider in
            connection with the performance of the applicable service.<br />

            ● Payment providers: We work with third-party payment providers to facilitate the payment
            process in the Services. These providers will store certain information about you. By using the
            Service, you grant BRiX and Plaid the right, power, and authority to act on your behalf to access
            and transmit your personal and financial information from your relevant financial institution.<br />

            We may also transfer your Personal Information to a third party as a result of a merger, acquisition,
            reorganization or similar transaction; when required by law or to respond to legal process; to protect
            our customers; to protect lives; to maintain the security of the Services; and/or to protect our rights or
            property. In any such event, and to the extent legally permitted, we will notify you and, if there are
            material changes in relation to the processing of your Personal Information, give you an opportunity to
            consent to such changes. Any third party with whom we share your data with will be required to provide
            the same or equal protection of such data as stated in this Policy.<br />

            The manner in which partners, service providers and payment processors use, store and/or disclose your
            information is governed by the policies of such parties, and we shall have no liability or responsibility for
            the privacy practices or other actions of any such parties. Generally, you can easily recognize when one
            of our partners is associated with a service. However, certain parties may provide services anonymously,
            and, in this case, we will only share information that is directly related to the applicable service(s).<br />

            We will also share Personal Information with third-party companies, organizations or individuals if we
            have a good faith belief that access, use, preservation or disclosure of your Personal Information is
            reasonably necessary to detect or protect against fraud or security issues, enforce our terms of use,
            meet any enforceable government request, defend against legal claims or protect against harm our legal
            rights or safety. This may include the incorporation of your Personal Information into databases
            maintained to validate information for identity verification, including verification for anti-money
            laundering and “Know Your Customer” protocols.<br />

            How Do We Use Tracking Technologies?<br />

            A “cookie” is a small file placed on your hard drive by a web page. We, or third parties we do business
            with, may use cookies to help us analyze our web page flow; customize our services, content and
            advertising; measure promotional effectiveness; and promote trust and safety. Cookies and pixel tags
            are commonly used at most major transactional websites in much the same way we use them in our
            Services.<br />
            Even if you are only browsing the Services, certain information (including computer and connection
            information, browser type and version, operating system and platform details, and the time of accessing
            the Services) is automatically collected. This information will be collected every time you access the
            Services and will be used for the purposes outlined in this Policy.<br />

            You can prevent or limit the usage of cookies collected from your device. An easy way of doing this is to
            change the settings in your browser. If you do so: (a) your use of the Services may be adversely affected
            (and possibly entirely prevented); (b) your experience of our website and other websites that use
            cookies to enhance or personalize your experience may be adversely affected; and (c) you may not be
            presented with advertising that reflects the way that you use our website and other websites. You find
            out how to make these changes to your browser at this site: https://www.allaboutcookies.org/manage-
            cookies/. Unless you have adjusted your browser settings to refuse cookies, our system will send cookies
            as soon as you access our Services. By using the Services, you consent to this use.<br />

            Web beacons, also known as pixel tags, may be used to track the traffic patterns of users from one page
            to another in order to maximize web traffic flow. Our third-party advertising service providers may also
            use web beacons to recognize you when you visit the Site or access the Services and to help determine
            how you found the Services. If you would like more information about this and to know your choices
            about not having this information used by these companies, please visit: the Digital Advertising
            Alliance&#39;s website, https://www.aboutads.info/, or the Network Advertising Initiative&#39;s website,
            https://networkadvertising.org/consumer/opt_out.asp.<br />

            How Do We Secure Your Personal Information?<br />

            Your account is protected by a password for your privacy and security. You must prevent unauthorized
            access to your account and Personal Information by selecting and protecting your password and/or
            other sign-on mechanism appropriately and limiting access to your computer or device and browser,
            including by signing off after you have finished accessing your account.<br />

            We are committed to safeguarding the confidentiality of your information but cannot guarantee the
            security of your information. We provide physical, electronic and procedural safeguards to protect
            information we process and maintain. For example, we (a) encrypt any information we store, (b)
            transmit information via https and (c) limit access to this information to (i) authorized employees and
            contractors who need to know that information in order to operate, develop and/or improve the
            Services and (ii) third parties that must receive that information in order to facilitate provision of the
            Services. Please be aware that, although we endeavor to provide reasonable security for information we
            process and maintain, no security system can prevent all potential security breaches. Unauthorized
            entry or use, hardware or software failure and/or other factors may compromise the security of user
            information at any time. If you believe your information may not be secure for any reason, please
            contact us immediately at info@realtybrix.com .<br />

            Your Choices Regarding Information<br />

            You have several choices regarding the use of Personal Information on the Services.<br />

            Changing or Deleting Your Personal Data<br />

            All users may review, update, correct or delete the Personal Information furnished by a user by
            contacting us at info@realtybrix.com or, in certain cases, by accessing your user account. For your
            protection, we may only share and update the Personal Information associated with the specific email
            address that you use to send us your request, and we may need to verify your identity before doing so.
            We will try to comply with such requests in a reasonably timely manner. If you completely and
            permanently delete all of your Personal Information, then your user account will be deactivated. If you
            wish to cancel your account, you may do so by emailing info@realtybrix.com, and any Personal
            Information associated with your account will be deleted as soon as is reasonably practical or as
            required by applicable law, subject to our data retention policy.<br />

            Data Retention<br />

            We retain information we receive for as long as you use the Services or as necessary to fulfill the
            purposes for which it was collected, provide our Services, resolve disputes, establish legal defenses,
            enforce our agreements and comply with applicable laws. We may retain information that is otherwise
            deleted in anonymized and aggregated form, in archived or backup copies as required pursuant to
            records retention obligations or otherwise as required by law. We may retain an archived copy of your
            records as required by law or for legitimate business purposes.<br />

            Download or Access Personal Information<br />

            You can ask us for a copy of your Personal Information, including a copy in machine readable form, by
            emailing us at info@realtybrix.com.<br />

            Opting Out of Email Communications<br />

            We may use some of the information we collect for marketing purposes, including to send you
            promotional communications about new features, products, events or other opportunities. If you wish
            to stop receiving these communications or to opt out of use of your information for these purposes,
            please follow the opt-out instructions, such as clicking “Unsubscribe” (or similar opt-out language), in
            those communications. You can also contact us at info@realtybrix.com to opt out. Despite your
            indicated email preferences, we may send you service-related communications, including notices of any
            updates to our terms of service or this Policy.<br />

            Links to Third-Party Websites<br />

            We may provide links to third-party websites. We have no control over these websites, and they are
            subject to their own terms of use and privacy policies. As such, we do not endorse and are not
            responsible for the availability of, or for any content, advertising, products or other materials on or
            available from, these third-party websites. By using the Services, you agree that we will not be liable for
            any damage or loss caused by your use of or reliance on any content, advertising, products or other
            materials on or available from these third-party websites.

            How We Respond to Do Not Track Signals<br />

            Your browser settings may allow you to automatically transmit a “Do Not Track” signal to websites and
            other online services you visit. We do not alter our practices when we receive a Do Not Track signal from
            your browser because we do not track our visitors to provide targeted advertising. To find out more
            about Do Not Track, please visit http://www.allaboutdnt.com.<br />

            If you access our Services from a mobile device, we may collect mobile advertising identifiers to provide
            certain Services. Users may be able to opt out of interest-based advertisements through the most recent
            applicable settings features of their mobile devices. These opportunities are subject to the offerings
            made available by the third-party operating system providers and subject to their privacy policies, and
            not any offerings by or the privacy policy of the Company. For convenience, the following are opt-out
            instructions that may apply to your mobile device, though please note that the setting features may vary
            from device to device:<br />

            ● For iOS devices with iOS 7 or higher: open Settings &gt; select Privacy &gt; select Advertising &gt; enable
            Limit Ad Tracking; and<br />

            ● For Android devices with OS 2.2 or higher and Google Play Services version 4.0 or higher: open
            the Google Settings app &gt; select Ads &gt; enable Opt out of interest-based advertising.<br />


            Please note, however, that opting out of mobile advertising identifier collection may prevent certain
            Services from functioning properly.<br />

            Third-Party Direct Marketing<br />

            We do not share personal data with third parties for their direct marketing purposes without your
            consent.<br />

            A Note to Users Outside the United States<br />

            BRiX is based in the United States. The Services are controlled and operated by us from the United
            States and are not intended to subject us to the laws or jurisdiction of any state, country or territory
            other than that of the United States. Your Personal Information may be stored and processed in any
            country where we have facilities or in which we engage service providers, and by using the Services, you
            consent to the transfer of information to countries outside of your country of residence, including the
            United States, which may have data protection rules that are different from those of your country. In
            certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in
            those other countries may be entitled to access your Personal Information.<br />

            Children<br />

            We do not use the Services to knowingly solicit data from, or market to, children under the age of 18. If
            a parent or guardian becomes aware that his or her child has provided us with information without their
            consent, he or she should contact us at info@realtybrix.com. We will delete such information from our
            files within a reasonable time.<br />

            California Privacy Rights<br />

            Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to request
            from a business with which the California resident has an established business relationship what types
            of personal information, if any, the business shares with third parties for direct marketing purposes by
            such third parties and the identities of the third parties with whom the business has shared such
            information in the immediately preceding calendar year. To access this information, please contact us by
            email at info@realtybrix.com.<br />

            Contact Us<br />

            If you have any questions about this Policy, your Personal Information, or the Services, you can contact
            info@realtybrix.com.<br />
        </Typography>
    );
};
