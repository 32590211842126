import {Box, Button, Card, Typography,} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {IPaymentMethod} from '@brix/shared-frontend';
import useAuth from '@panel/hooks/useAuth';
import {api} from '@panel/utils';
import {useQuery} from 'react-query';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useStripeBankAccount from '@panel/hooks/stripe/useStripeBankAccount';


const loadBankAccount = async (userId: string | undefined) => {
  const res = await api['payment-method'].get(`/user/${userId}/ach`);
  return res.data;
};

const BankAccountCard = () => {
  const {bankAccounts, isLoading} = useStripeBankAccount();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const {user} = useAuth();

  const {data: bankAccount} = useQuery(['payment-method', user?.id], () => loadBankAccount(user?.id));

  useEffect(() => {
    setPaymentMethods(bankAccount);
  }, [bankAccount]);

  const handleClickLink = () => {
    localStorage.setItem('tab', '3')
  }

  return (
    <Card
      sx={{
        background:
          'linear-gradient(135deg,rgba(51,14,14,1) 0%,rgba(20,25,37,1) 25%,rgba(20,25,37,1) 75%,rgba(34,25,3,1) 100%)',
        mb: 2,
        px: {xs: 1, sm: 2},
        pb: 2,
        pt: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          alignSelf: 'flex-start',
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
        }}
      >
        <Typography variant="h6" sx={{color: 'white'}}>
          Payout Bank Account
        </Typography>
        <Typography variant="body1" sx={{color: 'white'}}>
          Link your bank account to send your money faster.{' '}
        </Typography>
      </Box>

      {isLoading ? <CircularProgress color='primary'/>
        :
        <Box
          sx={{
            display: 'flex',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
            width: '90%',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '84px',
                height: '51px',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #5C6375',
                borderRadius: '2px',
              }}
            ><CheckCircleIcon
              sx={{
                borderRadius: '80%',
                border: 'hidden',
                backgroundColor: 'white',
                marginLeft: '-8px',
                marginTop: '-50px',
              }}
              fontSize="small"
              color="primary"
            />
              <AccountBalanceOutlinedIcon
                fontSize="large"
                sx={{
                  marginLeft: '15px',
                  color: 'white',
                }}
              />
            </Box>
            <Box
              sx={{
                color: 'white',
                marginLeft: '0.5rem',
                marginRight: '0.5rem',
              }}
            >
              {bankAccounts?.length === 0 ?
                <RouterLink style={{textDecoration: 'none', color: 'white'}} onClick={handleClickLink} to="/profile">
                  <Typography variant="h6">Please Add Bank Account</Typography>
                </RouterLink>
                :
                <>
                  <Typography variant="h6">{bankAccounts?.[0].bank_name}</Typography>
                  <Typography variant="body1">Checking ********** {bankAccounts?.[0].last4}</Typography>
                </>
              }
            </Box>
          </Box>
        </Box>
      }

      <Box
        sx={{
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <RouterLink to="/profile" style={{textDecoration: 'none'}}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: ' 8px 32px',
              gap: ' 8px',
              borderRadius: '5px',
              width: '100%',
            }}
            onClick={handleClickLink}
          >
            {paymentMethods?.length === 0 ? 'Add ACH Account' : 'Manage my bank accounts'}
          </Button>
        </RouterLink>
      </Box>
    </Card>
  );
};

export default BankAccountCard;
