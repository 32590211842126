import {errorHandler} from '@brix/shared-frontend';
import {IQuestion, RegisterWizardProps} from '@panel/interfaces';
import {api} from '@panel/utils';
import {toast} from 'react-toastify';
import axiosInstance from '@panel/utils/axios';

/**
 * Load questions from server
 * @returns {Promise<void>}
 */
export const loadQuestions = async () => {
  try {
    const questionsQuery = await api['security-question'].get();
    const questions: IQuestion[] = await questionsQuery.data.data.map((x: Record<string, unknown>) => {
      return {
        id: x['id'],
        question: x['question'],
      };
    });
    return questions;
  } catch (e: any) {
    toast.error(errorHandler(e?.data));
    return [];
  }
};

/**
 * Creates a new user
 * @param param0
 * @returns
 */
export const onCreateUser = async ({email, phone, password, firstName, lastName, userName}: RegisterWizardProps) => {
  return await api['auth'].post('/register', {
    email,
    phone,
    password,
    firstName,
    lastName,
    userName,
  });
};

/**
 * Create user profile
 * @param userId string
 * @param phone string
 */
export const onCreateProfile = async (userId: string, phone: string | undefined, userToken: string | undefined) => {
  try {
    await axiosInstance.post(
      `/users/${userId}/profile`,
      {phone, citizenship: '', timezone: ''},
      {headers: {Authorization: `Bearer ${userToken}`}}
    );
  } catch (e) {
    console.log(e);
  }
};

/**
 * Create question for user
 * @param userId string
 * @param securityQuestion1 string
 * @param securityAnswer1 string
 */
export const onCreateAnswer = async (
  userId: string,
  securityQuestion1: string | undefined,
  securityAnswer1: string | undefined,
  userToken: string | undefined
) => {
  try {
    await axiosInstance.post(
      '/security-answer',
      {
        answer: securityAnswer1,
        questionId: securityQuestion1,
        userId: userId,
      },
      {
        headers: {Authorization: `Bearer ${userToken}`},
      }
    );
  } catch (e) {
    console.log(e);
  }
};

/**
 * Send verification code to user's phone
 * @param userId string
 * @returns boolean
 */
export const sendPhoneVerification = async (
  userId: string | undefined,
  userToken: string | undefined
): Promise<boolean> => {
  return await axiosInstance
    .post(`/auth/sms/send-verification-code`, {id: userId}, {headers: {Authorization: `Bearer ${userToken}`}})
    .then(() => true)
    .catch((e) => {
      toast.error(errorHandler(e?.data));
      return false;
    });
};

/**
 * Send verification code to user's email
 * @param email string
 * @returns boolean
 */
export const sendEmailVerification = async (
  email: string | undefined,
  userToken: string | undefined
): Promise<boolean> => {
  return await axiosInstance
    .post(`/auth/send-email-verification`, {email}, {headers: {Authorization: `Bearer ${userToken}`}})
    .then(() => {
      return true;
    })
    .catch((e: any) => {
      toast.error(errorHandler(e?.data));
      return false;
    });
};

/**
 * Check if phone verification code is valid
 * @param code string
 * @param userId string
 * @returns boolean
 */
export const checkPhoneVerification = async (
  code: string,
  userId: string | undefined,
  userToken: string | undefined
): Promise<boolean> => {
  return await axiosInstance
    .post(`/auth/sms/check-verification-code`, {code, id: userId}, {headers: {Authorization: `Bearer ${userToken}`}})
    .then((response) => {
      if (response.status !== 201) {
        toast.error('Phone verification code is invalid');
      }
      return true;
    })
    .catch((e) => {
      toast.error(e?.data.message);
      return false;
    });
};

/**
 * Check if email verification code is correct
 * @param code string
 * @param email string
 * @returns boolean
 */
export const checkEmailVerification = async (
  code: string,
  email: string | undefined,
  userToken: string | undefined
): Promise<boolean> => {
  return await axiosInstance
    .post('/auth/check-email-verification-code', {code, email}, {headers: {Authorization: `Bearer ${userToken}`}})
    .then(() => true)
    .catch((e) => {
      toast.error(errorHandler(e?.data));
      return false;
    });
};
