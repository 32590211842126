import {createContext, useContext} from 'react';

export type SelectContextOrder = {
  orderSelected: string;
  setOrderSelected: (Filter: string) => void;
};

export const OrderContext = createContext<SelectContextOrder>({
  orderSelected: 'popular',
  setOrderSelected: (SelectContextOrder) => {},
});
export const useFilter = () => useContext(OrderContext);
