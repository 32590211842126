import {useHistory} from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Alert,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import useCart from '@panel/hooks/cart/useCart';
import {makeStyles} from '@mui/styles';
import {PopoverMenuProps} from '@panel/interfaces/popover-menu';
import {getInitials} from '@panel/utils/get-initials';
import {formatBrixies, formatPrice} from '@brix/shared-frontend';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    pointerEvents: 'auto',
    padding: theme.spacing(1),
  },
}));

export const CartPopover = ({anchorEl, onClose, open, ...other}: PopoverMenuProps) => {
  const classes = useStyles();
  const {products} = useCart();
  const history = useHistory();

  return (
    <Popover
      id="mouse-over-popover"
      classes={{
        paper: classes.paper,
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      disableScrollLock
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{sx: {width: {
        xs: 300,
        sm: 400,
      }, ml: 6}}}
      transitionDuration={100}
      sx={{
        pointerEvents: 'none',
      }}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        {products.length > 0 ? (
          <Table>
            <TableBody>
              {products.map((product) => {
                return (
                  <TableRow key={product.id} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Avatar
                          src={product.propertyImage}
                          variant="square"
                          alt={product.propertyName}
                          sx={{
                            height: 60,
                            width: 60,
                            borderRadius: '5px',
                            bgcolor: 'rgba(0,0,0,0.1)',
                          }}
                        >
                          {getInitials(product.propertyName)}
                        </Avatar>
                        <Box sx={{ml: 2}}>
                          <Typography variant="subtitle2" sx={{fontSize: '13px'}}>
                            {product.propertyName}
                          </Typography>
                          <Typography sx={{fontSize: '11px', mt: 1}} color="textSecondary" variant="body1">
                            {product.propertyAddress}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={{
                      display: 'flex',
                    }}>
                      <Typography variant="subtitle2" sx={{fontSize: '12px', mb: '4px'}}>
                        {formatBrixies(product.quantity).stax > 0
                          ? formatBrixies(product.quantity).stax +
                            ' Stax' +
                            (formatBrixies(product.quantity).brix > 0 ? ',' : '')
                          : ''}{' '}
                        {formatBrixies(product.quantity).brix > 0
                          ? formatBrixies(product.quantity).brix +
                            ' BRiX' +
                            (formatBrixies(product.quantity).brixie > 0 ? ',' : '')
                          : ''}{' '}
                        {formatBrixies(product.quantity).brixie > 0
                          ? formatBrixies(product.quantity).brixie + ' BRiXies'
                          : ''}{' '}
                      </Typography>
                      <Typography color="gray" variant="body2">
                        = {formatPrice(product.currentValue * product.quantity)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Alert severity="info">
            <Typography variant="body2" sx={{fontWeight: 600}}>
              Your cart is empty.
            </Typography>
          </Alert>
        )}
      </Box>
      <Divider />
      <Box sx={{my: 1}}>
        <MenuItem onClick={() => history.push(`/my-cart`)}>
          <ListItemIcon>
            <ShoppingCartCheckoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" sx={{fontWeight: 600}}>
                Go to My Cart
              </Typography>
            }
          />
        </MenuItem>
      </Box>
    </Popover>
  );
};
